import { config } from "../config";
import { User } from "./user.service";
import { postData } from "./utils";

export namespace AuthenticationService {

    export function login(email: string, password: string) : Promise<User> {
        return postData(config.API_BASE_URL + '/user/login', { email, password });
    }

    export function logout() : Promise<{ success: boolean }> {
        return postData(config.API_BASE_URL + '/user/logout', {});
    }
}
