import { config } from "../config";
import { getData } from "./utils";

export interface Department {
    id: number;
    name: string;
}

export namespace DepartmentService {

    export function getAll() : Promise<Department[]> {
        return getData(config.API_BASE_URL + '/department');
    }

}
