import React from 'react';
import { Hook, useForceValidateContext, useHook, useReadOnlyContext } from './Utils';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface InputProps extends BaseTextFieldProps {
    $value?: Hook<any>;
    readOnly?: boolean;
    InputProps?: any;
    SelectProps?: any;
    onChange?: (
      event: React.ChangeEvent<{ name?: string; value: any }>,
      child: React.ReactNode
    ) => void;
}

const useStyles = makeStyles((theme) => ({
    input: {
        '& .MuiInputLabel-asterisk': {
            color: 'red'
        }
    }
}));

export const Input = ({ $value, readOnly, ...props } : InputProps) => {
    const $firstFocus = useHook(true);
    const readOnlyContext = useReadOnlyContext();
    const forceValidateContext = useForceValidateContext();
    const classes = useStyles();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ($value) {
            $value.set(event.target.value);
        }
    };

    let errors: any = undefined;
    if (!$firstFocus.value || forceValidateContext) {
        errors = $value?.validator ? $value.validator($value.value) : undefined;
    }

    return <TextField
            variant="outlined"
            error={Boolean(errors && errors.length)}
            helperText={errors?.join('\n')}
            onBlur={() => $firstFocus.set(false)}
            classes={{ root: classes.input }}
            value={$value?.value == null ? props.value : $value?.value}
            onChange={handleChange}
            disabled={readOnly != null ? readOnly : readOnlyContext}
            {...(props as any)}
        />
}

export const PasswordField = ({ $value, readOnly, ...props } : InputProps) => {
    const $firstFocus = useHook(true);
    const readOnlyContext = useReadOnlyContext();
    const forceValidateContext = useForceValidateContext();
    const classes = useStyles();
    const $showPassword = useHook(false);
    const handleClickShowPassword = () => {
        $showPassword.set(!$showPassword?.value);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ($value) {
            $value.set(event.target.value);
        }
    };

    let errors: any = undefined;
    if (!$firstFocus.value || forceValidateContext) {
        errors = $value?.validator ? $value.validator($value.value) : undefined;
    }

    return <TextField
        variant="outlined"
        error={Boolean(errors && errors.length)}
        helperText={errors?.join('\n')}
        onBlur={() => $firstFocus.set(false)}
        classes={{ root: classes.input }}
        type={$showPassword.value ? 'text' : 'password'}
        value={$value?.value == null ? props.value : $value?.value}
        onChange={handleChange}
        disabled={readOnly != null ? readOnly : readOnlyContext}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {$showPassword.value ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            ),
            labelWidth: 70
        }}
        {...(props as any)}
    />
}
