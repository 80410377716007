import { ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import './App.css';
import { AdminDashboard } from './Components/AdminDashboard';
import { LoginComponent } from './Components/Login';
import { PageLayout } from './Components/PageLayout';
import { FilterState, StoreContext, useHook } from './CoreComponents/Utils';
import { theme } from './theme';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { AddUser, EditUser } from './Components/User';
import { randomInt } from './Services/utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { User, UserType } from './Services/user.service';
import { ApproverLeaveWall } from './Components/ApproverLeaveWall';
import { UserLeaveWall, UserLeaveWallAsApprover } from './Components/UserLeaveWall';
// import { Alert } from './CoreComponents/Alert';
import { Notifications } from './Components/Notifications';
import { useLastReadNotificationDateHook } from './Services/notification.service';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { TeamWall } from './Components/TeamWall';
import { Reports } from './Components/Reports';
import {Schedule} from './Components/Schedule';

const BackgroundElements = [...Array(7)].map((x, i) => `element_${i + 1}.png`);

const useStyles = makeStyles((theme) => ({
  imageElement: {
    position: 'fixed',
  },
  imageContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -1,
    overflow: 'hidden',
    width: '98vw',
    height: '98vh',
  }
}));

const Background = () => {
  const classes = useStyles();
  //const $backgroundElements = useHook<any>([]);
  const [backgroundElements, setBackgroundElements] = React.useState<any>([])

  useEffect(() => {

    function generateElements() {
      const elements: any = [];
      const maxElements = 40;
      for (let i = 0; i < maxElements; ++i) {
        const elIndex = randomInt(BackgroundElements.length);
        // const maxLeft = 35;
        // const maxRight = 60;
        // const isRight = randomInt(0, 2);
        // const x = isRight ? randomInt(maxRight, 101)
        //   : randomInt(maxLeft);
        const x = randomInt(0, 101);
        elements.push({
          src: BackgroundElements[elIndex],
          x: x,
          rotation: randomInt(-180, 180),
          scale: randomInt(7, 8),
          floatSpeed: randomInt(9, 12),
          spinSpeed: randomInt(5, 10),
          floatDelay: randomInt(0, 15)
        })
      }
      setBackgroundElements(elements);
    }

    generateElements();

    window.addEventListener('resize', generateElements);
    return () => {
      window.removeEventListener('resize', generateElements);
    }
  }, []);

  return <div className={classes.imageContainer}>
    {backgroundElements.map((x, i) =>
      <img
        alt=""
        src={x.src || '/element_1.png'}
        key={x.src + i}
        className={classes.imageElement}
        style={{
          left: `${x.x}%`,
          top: '-15%',
          maxWidth: `${x.scale}%`,
          height: 'auto',
          WebkitAnimation: `floatBubble ${x.floatSpeed}s infinite ${x.floatDelay}s alternate
        , spin ${x.spinSpeed}s linear infinite`,
          animation: `floatBubble ${x.floatSpeed}s infinite ${x.floatDelay}s alternate
        , spin ${x.spinSpeed}s linear infinite`,
        }}
      />
    )}

  </div>
}

function App() {
  const $userData = useHook<User | null>(null);
  const $selectedUserData = useHook<User | null>(null);
  const $navigationComponent = useHook<any>(undefined);
  const $missedNotificationsCount = useHook<number>(0);
  const $blockNotificationCounterUpdate = useHook(false);
  const $filters = useHook<FilterState>({ pageId: '', data: {} });
  const $lastReadNotificationDate = useLastReadNotificationDateHook(0);
  const $snackbarMessage = useHook('');
  const $isFetchAttached = useHook(false);

  useEffect(() => {
    const originalFetch = window.fetch;
    // @ts-ignore
    window.fetch = function (input: RequestInfo, init?: RequestInit) {
      // console.log('fetch', input);
      return originalFetch(input, init)
        .then(async resp => {
          if (!resp.ok) {
            const text = await resp.text();

            let parsedError = '';
            try {
              parsedError = JSON.parse(text).msg;
            } catch {
              parsedError = text;
            }

            switch (resp.status) {
              case 401:
                $userData.set(null);
                $snackbarMessage.set(parsedError);
                return;
              case 404:
              case 500:
                $snackbarMessage.set(parsedError);
                return;
            }

            throw new Error(parsedError);
          }

          // parses JSON response into native JavaScript objects
          return resp.json()
            .catch(err => console.error('response parse error', input, 'error', err));
        },
        err => {
          // if fetch throws an error
          $snackbarMessage.set(err.message);
          console.error('fetch error', err);
        })
    }

    $isFetchAttached.set(true);
    return () => {
      window.fetch = originalFetch;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ($isFetchAttached.value ?
    <div className="App">
      <ThemeProvider theme={theme}>
        <StoreContext.Provider value={{
          $currentUser: $userData,
          $selectedUser: $selectedUserData,
          $missedNotificationsCount,
          $blockNotificationCounterUpdate,
          $lastReadNotificationDate,
          $filters
        } as any}>
          <Snackbar
            open={Boolean($snackbarMessage.value)}
            autoHideDuration={10000}
            onClose={() => $snackbarMessage.set('')}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          >
            <Alert severity="error">
              {$snackbarMessage.value}
            </Alert>
          </Snackbar>
          {$userData.value ?
            <Router>
              <PageLayout $userData={$userData} $navigationComponent={$navigationComponent}>
                <Switch>
                  {$userData.value.userType === UserType.Admin ?
                    <>
                      <Route path="/add-user">
                        <AddUser />
                      </Route>
                      <Route exact path="/">
                        <AdminDashboard $navigationComponent={$navigationComponent} />
                      </Route>
                      <Route exact path="/profile/:userId">
                        <EditUser />
                      </Route>
                    </>
                  :$userData.value.userType === UserType.Approver ?
                    <>
                      <Route exact path="/">
                        <ApproverLeaveWall $userData={$userData as any} $navigationComponent={$navigationComponent} />
                      </Route>
                      <Route exact path="/team-wall">
                        <TeamWall $userData={$userData as any} $navigationComponent={$navigationComponent} />
                      </Route>
                      <Route exact path="/my-wall">
                        <UserLeaveWall $userData={$userData} $navigationComponent={$navigationComponent} />
                      </Route>
                      <Route exact path="/wall/:userId">
                        <UserLeaveWallAsApprover $navigationComponent={$navigationComponent} />
                      </Route>
                      <Route exact path="/reports">
                        <Reports $userData={$userData as any} $navigationComponent={$navigationComponent} />
                      </Route>
                      <Route exact path="/Schedule">
                        <Schedule $userData={$userData as any} $navigationComponent={$navigationComponent} />
                      </Route>
                      <Route exact path="/notifications">
                        <Notifications $navigationComponent={$navigationComponent} />
                      </Route>
                    </>
                  :$userData.value.userType === UserType.User ?
                    <>
                      <Route exact path="/">
                        <UserLeaveWall $userData={$userData} $navigationComponent={$navigationComponent} />
                      </Route>
                      <Route exact path="/team-wall">
                        <TeamWall $userData={$userData as any} $navigationComponent={$navigationComponent} />
                      </Route>
                      <Route exact path="/notifications">
                        <Notifications $navigationComponent={$navigationComponent} />
                      </Route>
                      <Route exact path="/Schedule">
                        <Schedule $userData={$userData as any} $navigationComponent={$navigationComponent} />
                      </Route>
                    </>
                  :
                    <Alert severity="error">INVALID USER TYPE</Alert>
                  }
                </Switch>
              </PageLayout>
            </Router>
            :
            <div className="loginContainer">
              <Background />
              <LoginComponent $userData={$userData} />
            </div>
          }
        </StoreContext.Provider>
      </ThemeProvider>
    </div>
  : null);
}

export default App;
