import React, { useEffect } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styles from './Login.module.css';
import { Alert } from '../CoreComponents/Alert';
import { Input, PasswordField } from '../CoreComponents/Input';
import { useHook, useStoreContext } from '../CoreComponents/Utils';
import { AuthenticationService } from '../Services/authentication.service';
import { BrandButton } from '../CoreComponents/Button';
import { UserService } from '../Services/user.service';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    loginInput: {
        '& .MuiFormControl-root': {
            marginTop: theme.spacing(3),
            // width: '25ch',
        },
    },

    footer: {
        marginTop: theme.spacing(),
        padding: theme.spacing(2),
        borderRadius: theme.spacing(0, 0, 2.5, 2.5),
        background: '#f4f8fb',
        color: '#745fa4',
    },

    panel: {
        display: 'flex',
        flexDirection: 'column',

        background: '#ffffff',
        boxShadow: theme.spacing(0, 0.5, 0.5) + ' rgba(0, 0, 0, 0.25)',
        borderRadius: theme.spacing(2.5),
    },

    // loginTitle: {
    //     fontWeight: 'bold',
    //     color: theme.palette.primary.main,
    //     paddingBottom: 0,
    //     marginBottom: 0,
    // }
}));

const Panel = ({ header, main, footer, ...props }: any) => {
    const classes = useStyles();
    return <div className={clsx(classes.panel, props.className)}>
        {header ?
            <header>{header}</header>
            : null}
        {main ?
            <main>{main}</main>
            : null}
        {footer ?
            <footer className={classes.footer}>{footer}</footer>
            : null}
    </div>
}

export const LoginComponent = ({ $userData }) => {
    const classes = useStyles();
    const { $lastReadNotificationDate } = useStoreContext();
    const $email = useHook('');
    const $password = useHook('');
    const $error = useHook('');

    useEffect(() => {
        UserService.getMe()
            .then(data => {
                $userData.set(data);
                $lastReadNotificationDate.update(data.id);
            })
            .catch(err => { });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Panel
        className={styles.loginPosition}
        header={<>
            <h1 className={styles.title}>Login</h1>
            <span className={styles.clarification}>Enter your details below to continue</span>
        </>}
        main={
            <form className={clsx(styles.login, classes.loginInput)}>
                {$error.value ?
                    <Alert severity="error">{$error.value}</Alert>
                    : null}
                <Input label="Email address" $value={$email} />
                <PasswordField
                    id="password"
                    // classes={{ root: clsx(classes.margin, classes.textField) }}
                    label="Password"
                    $value={$password}
                />
                <BrandButton
                    color="primary"
                    type="submit"
                    classes={{ root: styles.loginButton }}
                    onClick={e => {
                        e.preventDefault();
                        if (!$email.value || !$password.value) {
                            $error.set('Please enter username and password');
                            return;
                        }

                        AuthenticationService.login($email.value, $password.value)
                            .then(data => {
                                $userData.set(data);
                                $lastReadNotificationDate.update(data.id);
                            })
                            .catch(err => $error.set('Wrong credentials contact your team lead, head of department or HR department!'));
                    }}
                >
                    Login
                </BrandButton>
            </form>
        }
        footer={
            <span>Advanced HR System - One Step Ahead</span>
        }
    />
};
