import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx';
import { BrandVacationsCalendar } from '../CoreComponents/Calendar';
import { Hook, useHook, useQuery } from "../CoreComponents/Utils";
import { AdminService, NationalHoliday } from '../Services/admin.service';
import { Department, DepartmentService } from '../Services/department.service';
import { GenderType, StatusType, User, UserService } from "../Services/user.service";
import { LeaveWallVacations, LeaveRequest } from '../Services/vacation.service';
import { BrandButton } from '../CoreComponents/Button';
import { addDays, addMonths, endOfMonth, format, startOfMonth, subDays, subMonths } from 'date-fns';
import { SelectedLeaveRequest } from './UserLeaveWall';
import { useHistory } from 'react-router-dom';
import { Input } from '../CoreComponents/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { BrandModal, WarningConfirmationDialog } from '../CoreComponents/Modal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/Select';
import { BrandTagIcon } from '../CoreComponents/CustomIcons';
import Chip from '@material-ui/core/Chip';
import { TeamEntry, TeamService } from '../Services/team.service';
import { PageDataLoading } from '../CoreComponents/PageDataLoading';

export interface TeamWallProps {
    $userData: Hook<User>;
    $navigationComponent: Hook<any>;
}

const useStyles = makeStyles((theme) => ({
    root: {
        // margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },


    inputField: {
        minWidth: theme.spacing(35),
        marginRight: theme.spacing(1),
    },
    addForm: {
        display: 'flex',
        flexDirection: 'row',
        minWidth: theme.spacing(30),
        margin: 'auto',
    },

    userRow: {
        display: 'inline-flex',
        marginBottom: theme.spacing(5),
        '&>*': {
            margin: 'auto 0',
        },
    },
    daysUser: {
        width: theme.spacing(8),
        minWidth: theme.spacing(8),
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    daysRemaining: {
        width: theme.spacing(10),
        minWidth: theme.spacing(10),
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: '#ff6073',
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'row',
        width: theme.spacing(30),
        minWidth: theme.spacing(30),
        padding: theme.spacing(0, 0, 1, 0),
        borderRadius: theme.shape.borderRadius,
    },

    userCardInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',
    },
    cardTitle: {
        fontWeight: 'bold',
        color: '#727272',
        textDecoration: 'unset',
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        margin: theme.spacing(1, 3, 'auto', 1),
        fontWeight: 'bold',
    },
    avatarFemale: {
        backgroundColor: '#FFB2BB',
        color: '#FF6073',
    },
    avatarMale: {
        backgroundColor: '#E6F5FF',
        color: '#18A0FB',
    },
    avatarInactive: {
        backgroundColor: '#EFEFEF',
        color: '#727272',
    },

    controlsBar: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(0, 0, 4, 0),
    },
    calendarControls: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: 'auto',
        // marginLeft: 'auto',
        '& > *': {
            margin: theme.spacing('auto', 1.2),
        },
    },
    calendarBarDate: {
        fontWeight: 'bold',
        fontSize: '1.5em',
    },
    subordinatesList: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
    },

    selectedTeam: {
        margin: 'auto 0 auto auto',
    },
    teamsEditButtonIcon: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: '#ffa3ae',
    },
    removeUserIcon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: '#dedede',
        '&:hover': {
            color: '#acacac',
        },
    },
    requestLeaveButtonIcon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: '#18a0fb',
    },
}));

const TeamMemberEntry = (
    { user, onDelete, departments, nationalHolidays, $date, requestedLeaves, isOwnerOfTeam }
        : { user: User, onDelete: () => void, departments: Department[], nationalHolidays: NationalHoliday[], $date: Hook<Date>, requestedLeaves?: LeaveRequest[], isOwnerOfTeam: boolean }
) => {
    const classes = useStyles();
    const $department = useHook('');
    const $selectedRequestedLeave = useHook<LeaveRequest>({} as any);
    const $isSelectedRequestedLeave = useHook<boolean>(false);

    useEffect(() => {
        $department.set(
            departments.find(x => x.id === user.departmentId)?.name
            || 'unable to resolve department'
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments]);

    const onClickedCalendar = (date, requestedLeave, holiday) => {
        if (!requestedLeave) {
            return;
        }

        $selectedRequestedLeave.set(requestedLeave);
        $isSelectedRequestedLeave.set(true);
    }

    return <div className={classes.userRow}>
        {isOwnerOfTeam ?
            <IconButton disabled>
                <div className={classes.removeUserIcon}></div>
            </IconButton>
            :
            <IconButton onClick={onDelete}>
                <CancelIcon className={classes.removeUserIcon} />
            </IconButton>
        }
        <div className={classes.userInfo}>
            <Avatar
                className={clsx(
                    classes.avatar,
                    user.status === StatusType.Inactive ? classes.avatarInactive
                        : user.gender === GenderType.Female ? classes.avatarFemale
                            : classes.avatarMale
                )}
            >
                {user.firstName[0] + user.lastName[0]}
            </Avatar>
            <div className={classes.userCardInfo}>
                <span className={classes.cardTitle}>
                    {user.firstName} {user.lastName}
                </span>
                <span>{user.jobPosition}</span>
                <span>ID-{user.id}</span>
            </div>
        </div>
        <BrandVacationsCalendar
            isRow={true}
            // onlyCurrentMonth
            requestedLeaves={requestedLeaves}
            nationalHolidays={nationalHolidays}
            date={$date.value}
            onChange={onClickedCalendar}
        // classes={{ root: classes.calendar }}
        />
        {$isSelectedRequestedLeave.value ?
            <SelectedLeaveRequest
                user={user}
                $isOpen={$isSelectedRequestedLeave}
                selectedLeave={$selectedRequestedLeave.value}
                hideReason={true}
            />
            : null}
    </div>
}

const useEditTeamsStyles = makeStyles((theme) => ({
    paper: {
        // width: '90vw',
        // maxWidth: theme.spacing(100),
        maxWidth: 'unset',
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    column: {
        margin: theme.spacing(1),
        minWidth: theme.spacing(45),
        '&:first-child:not(:last-child)': {
            borderRight: '0.2px solid rgba(113, 113, 113, 0.49)',
            paddingRight: theme.spacing(1),
        },
    },
    inputField: {
        minWidth: theme.spacing(35),
        marginRight: theme.spacing(1),
    },
    addForm: {
        display: 'flex',
        flexDirection: 'row',
        minWidth: theme.spacing(30),
        marginBottom: theme.spacing(1),
    },
    doneButtonContainer: {
        display: 'flex',
    },
    doneButton: {
        margin: 'auto 0 auto auto',
    },

    teamsList: {
        overflowY: 'auto',
        maxHeight: '40vh',
        display: 'flex',
        flexDirection: 'column',
    },
    chipItem: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1, 0),
        '& > .MuiChip-label': {
            marginRight: 'auto',
        },
    },
}));
const EditTeams = ({ $isOpen, $teams }: { $isOpen: Hook<boolean>, $teams: Hook<TeamEntry[]> }) => {
    const classes = useEditTeamsStyles();
    const $newTeamName = useHook('');
    const $selectedTeamToDelete = useHook<TeamEntry | null>(null);
    const $waitingToDelete = useHook(false);

    return <BrandModal
        title={`Team Settings`}
        open={$isOpen.value}
        onClose={() => $isOpen.set(false)}
        onCloseButton={() => $isOpen.set(false)}
        classes={{ paper: classes.paper }}
    >
        <div className={classes.column}>
            <div className={classes.root}>
                <div className={classes.column}>
                    <span>Create Team</span>
                    <div className={classes.addForm}>
                        <Input classes={{ root: classes.inputField }} size="small" $value={$newTeamName} />
                        <BrandButton
                            color="primary"
                            onClick={() => {
                                if (!$newTeamName.value) {
                                    return;
                                }

                                TeamService.addTeam({ name: $newTeamName.value, users: [] })
                                    .then(data => {
                                        $newTeamName.set('');
                                        $teams.set([
                                            ...$teams.value,
                                            data
                                        ]);
                                    });
                            }}
                        >
                            Add
                        </BrandButton>
                    </div>
                    <span>My Teams</span>
                    <div className={classes.teamsList}>
                        {$teams.value.map(team => team.isDefault ? null :
                            <Chip
                                key={team.id}
                                onDelete={() => $selectedTeamToDelete.set(team)}
                                classes={{ root: classes.chipItem }}
                                // color={$selectedTeam.value === team ? "primary" : undefined}
                                label={team.name}
                                avatar={<Avatar>{team.users.length}</Avatar>}
                            // onClick={() => $selectedTeam.set(team)}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={classes.doneButtonContainer}>
                <BrandButton
                    className={classes.doneButton}
                    color="primary"
                    onClick={() => $isOpen.set(false)}
                >
                    Done
                </BrandButton>
            </div>
            {$selectedTeamToDelete.value ?
                <WarningConfirmationDialog
                    isLoading={$waitingToDelete.value}
                    isOpen={$selectedTeamToDelete.value}
                    message={`Are you sure you want to remove team '${$selectedTeamToDelete.value.name}'?`}
                    onCancel={() => $selectedTeamToDelete.set(null)}
                    onOk={() => {
                        $waitingToDelete.set(true);
                        TeamService.deleteTeam($selectedTeamToDelete.value!.id as number)
                            .then(() => {
                                $selectedTeamToDelete.set(null);
                                $waitingToDelete.set(false);
                                $teams.set($teams.value.filter(x => x.id !== $selectedTeamToDelete.value!.id));
                            },
                                err => $waitingToDelete.set(false));
                    }}
                />
                : null}
        </div>
    </BrandModal>
}

const useTeamSearchStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    backButton: {
        margin: theme.spacing('auto', 2, 'auto', 2),
        padding: theme.spacing(1),
    },
    searchButton: {
        margin: theme.spacing('auto', 2, 'auto', 0),
        padding: theme.spacing(1),
    },
    searchInput: {
        width: theme.spacing(45),
        margin: theme.spacing('auto', 1),
        '& .MuiOutlinedInput-input': {
            padding: theme.spacing(1.4, 2),
        },
    },
}));
const TeamSearch = (
    { searchValueChange, ...props }
        : { searchValueChange?: (string) => void, showSearchButton?: boolean, showBackButton?: boolean }
) => {
    const classes = useTeamSearchStyles();
    const history = useHistory();
    const { q } = useQuery();
    const $search = useHook<string>(q || '');

    useEffect(() => {
        const value = q || '';
        $search.set(value);
        if (searchValueChange) {
            searchValueChange(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [q]);

    return <form
        className={classes.root}
        onSubmit={e => {
            e.preventDefault();
            history.push(`/?q=${$search.value}`);
        }}
    >
        {props.showBackButton ?
            <IconButton
                onClick={() => history.goBack()}
                className={classes.backButton}
            >
                <ArrowBackIcon />
            </IconButton>
            : null}
        <Input
            placeholder="Search"
            value={$search.value}
            onChange={e => {
                const v = e.target.value.toLowerCase();
                $search.set(v);
                if (searchValueChange) {
                    searchValueChange(v);
                    history.replace({ search: `q=${v}` });
                }
            }}
            classes={{ root: classes.searchInput }}
        />
        {props.showSearchButton ?
            <BrandButton
                color="primary"
                type="submit"
                className={classes.searchButton}
            >
                <FontAwesomeIcon icon={faSearch} />
            </BrandButton>
            : null}
    </form>
}

export const TeamWall = ({ $userData, $navigationComponent }: TeamWallProps) => {
    const classes = useStyles();
    const { q } = useQuery();
    const $selectedUser = useHook<User | null>(null);
    const $selectedTeamUsers = useHook<User[]>([]);
    const $filteredDepartmentUsers = useHook<User[]>([]);
    const $departmentUsers = useHook<User[]>([]);
    const $date = useHook(new Date());
    const $departments = useHook<Department[]>([]);
    const $nationalHolidays = useHook<NationalHoliday[]>([]);
    const $showEditTeamsModal = useHook(false);
    const $teamLeaves = useHook<LeaveWallVacations>([]);
    const $filter = useHook(q || '');
    const $teams = useHook<TeamEntry[]>([]);
    const $selectedTeamId = useHook<number>(0);
    const $selectedUserToDelete = useHook<User | null>(null);
    const $waitingToDelete = useHook(false);
    const $loading = useHook<boolean>(false);

    useEffect(() => {
        $loading.set(true);
        Promise.all([
            UserService.getAllFromDepartment(),
            DepartmentService.getAll(),
            AdminService.nationalHolidays(),
            TeamService.getMy(),
        ]).then(([departmentUsers, departments, nationalHolidays, myTeams]) => {
            $departmentUsers.set(departmentUsers);
            $departments.set(departments);
            $nationalHolidays.set(nationalHolidays);
            if (myTeams.length > 0) {
                myTeams.sort((a, b) => a.isDefault ? -1 : b.isDefault ? 1 : 0);
                $teams.set(myTeams.map(x => {
                    x.users = x.users.filter(u => departmentUsers.some(du => du.id === u.userId));
                    return x;
                }));
            } else {
                TeamService.addTeam({ name: 'Default', isDefault: true, users: [] })
                    .then(data => $teams.set([data]));
            }
            $loading.set(false);
        });

        $navigationComponent.set(
            <TeamSearch searchValueChange={$filter.set} />
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ($teams.value.some(x => x.id === $selectedTeamId.value)) {
            return;
        }

        const defaultTeam = $teams.value.find(x => x.isDefault);
        if (defaultTeam) {
            $selectedTeamId.set(defaultTeam.id as number);
        } else {
            $selectedTeamId.set(0);
        }

        $filter.set('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$teams.value]);

    useEffect(() => {
        // console.log('useEffect')
        const selectedTeamUsers = $teams.value.find(x => x.id === $selectedTeamId.value)?.users;
        if (!selectedTeamUsers) {
            $selectedTeamUsers.set([]);
            return;
        }

        $filteredDepartmentUsers.set(
            $departmentUsers.value.filter(x => !selectedTeamUsers.some(tu => tu.userId === x.id))
        );

        const departmentUsers = $departmentUsers.value
            .filter(x =>
                selectedTeamUsers.some(tu => tu.userId === x.id)
                && (x.firstName + ' ' + x.lastName + ' ID-' + x.id)
                    .toLowerCase()
                    .includes($filter.value)
            );
        departmentUsers.sort((a, b) =>
            a.id === $userData.value.id ? -1
                : b.id === $userData.value.id ? 1
                    : 0);
        $selectedTeamUsers.set(departmentUsers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$departmentUsers.value, $filter.value, $selectedTeamId.value, $teams.value]);

    useEffect(() => {
        if (!$selectedTeamId.value) {
            return;
        }

        const startDate = subDays(startOfMonth($date.value), 1);
        const endDate = addDays(endOfMonth($date.value), 1);
        // TODO: refactor
        $loading.set(true);
        TeamService.teamVacations($selectedTeamId.value, startDate, endDate)
            .then(data => {
                $teamLeaves.set(data)
                $loading.set(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$date.value, $selectedTeamId.value]);

    const updateSelectedTeam = () => {
        const startDate = subDays(startOfMonth($date.value), 1);
        const endDate = addDays(endOfMonth($date.value), 1);

        TeamService.getTeam($selectedTeamId.value, startDate, endDate)
            .then(data => {
                $teams.set([...$teams.value.map(x => x.id === data.id ? data : x)]);

                // TODO: refactor this
                const result = {};
                for (let v of data.users) {
                    result[v.userId] = v.vacations;
                }
                $teamLeaves.set(result);
            });
    };

    return <div className={classes.root}>
        <div className={classes.controlsBar}>
            <div className={classes.calendarControls}>
                <IconButton onClick={() => $date.set(subMonths($date.value, 1))}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </IconButton>
                <IconButton onClick={() => $date.set(addMonths($date.value, 1))}>
                    <FontAwesomeIcon icon={faArrowRight} />
                </IconButton>
                <span className={classes.calendarBarDate}>
                    {format($date.value, 'MMMM y')}
                </span>
                <BrandButton
                    onClick={() => $date.set(new Date())}
                    variant="outlined"
                    color="primary"
                >
                    Today - {format(new Date(), 'd MMMM')}
                </BrandButton>
            </div>

            <div className={classes.addForm}>
                <Autocomplete
                    classes={{ root: classes.inputField }}
                    size="small"
                    options={$filteredDepartmentUsers.value}
                    getOptionLabel={(option) => `${option.firstName} ${option.middleName} ${option.lastName} ID-${option.id}`}
                    value={$selectedUser.value}
                    // disabled={$isReadOnly.value}
                    onChange={(_, user) => $selectedUser.set(user)}
                    renderInput={(params) => <Input {...params} required label="Who for" variant="outlined" />}
                />
                <BrandButton
                    color="primary"
                    onClick={() => {
                        if (!$selectedUser.value) {
                            return;
                        }

                        // const safeTeam = ($selectedTeam.value as TeamEntry);
                        TeamService.addUser($selectedTeamId.value, { userId: $selectedUser.value.id })
                            .then(() => {
                                updateSelectedTeam();
                                $selectedUser.set(null);
                            });
                    }}
                >
                    Add
                </BrandButton>
            </div>

            <BrandSelect
                $value={$selectedTeamId}
                className={classes.selectedTeam}
                size="small"
            >
                {$teams.value.map(team =>
                    <BrandMenuItem value={team.id} key={team.id}>{team.name}</BrandMenuItem>
                )}
            </BrandSelect>
            <IconButton onClick={() => $showEditTeamsModal.set(true)}>
                <BrandTagIcon className={classes.teamsEditButtonIcon} />
            </IconButton>
            {$showEditTeamsModal.value ?
                <EditTeams
                    $isOpen={$showEditTeamsModal}
                    $teams={$teams}
                />
                : null}
        </div>

        {$loading.value ?
            <PageDataLoading />
            :
            <div className={classes.subordinatesList}>
                {$selectedTeamUsers.value.map(x =>
                    <TeamMemberEntry
                        key={x.id}
                        user={x}
                        onDelete={() => $selectedUserToDelete.set(x)}
                        departments={$departments.value}
                        nationalHolidays={$nationalHolidays.value}
                        requestedLeaves={$teamLeaves.value ? $teamLeaves.value[x.id] : undefined}
                        $date={$date}
                        isOwnerOfTeam={x.id === $userData.value.id}
                    />
                )}
            </div>
        }
        {$selectedUserToDelete.value ?
            <WarningConfirmationDialog
                isLoading={$waitingToDelete.value}
                isOpen={$selectedUserToDelete.value}
                message={`Are you sure you want to remove ${$selectedUserToDelete.value.firstName} ${$selectedUserToDelete.value.lastName} from the team?`}
                onCancel={() => $selectedUserToDelete.set(null)}
                onOk={() => {
                    $waitingToDelete.set(true);
                    TeamService.removeUser($selectedTeamId.value, { userId: $selectedUserToDelete.value!.id })
                        .then(() => {
                            $selectedUserToDelete.set(null);
                            $waitingToDelete.set(false);
                            updateSelectedTeam();
                        },
                            err => $waitingToDelete.set(false));
                }}
            />
            : null}
    </div>
}
