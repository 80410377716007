import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './Alert.module.css';

interface AlertProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    classes?: any;
    Component?: any;
    severity: 'error' | 'success' | 'warning';
}

const themeColorMap = {
    error: styles.alertError,
    success: styles.alertSuccess,
    warning: styles.alertWarning,
}

export const Alert = ({ severity, classes, Component, ...props } : AlertProps) =>
    Component ?
        <Component
            className={ clsx(
                styles.alert,
                classes?.root,
                themeColorMap[severity]
            ) }
            {...props}
        />
    :
        <div
            className={ clsx(
                styles.alert,
                classes?.root,
                themeColorMap[severity]
            ) }
            {...props}
        >
            {props.children}
        </div>

export const SlimAlert = ({ severity, classes, Component, ...props } : AlertProps) =>
    Component ?
        <Component
            className={ clsx(
                styles.slimAlert,
                classes?.root,
                themeColorMap[severity]
            ) }
            {...props}
        />
    :
        <div
            className={ clsx(
                styles.slimAlert,
                classes?.root,
                themeColorMap[severity]
            ) }
            {...props}
        >
            {props.children}
        </div>
