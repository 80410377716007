
export interface Category {
    id: number;
    name: string;
}

export enum LeaveCategory {
    PaidLeave = 1,
    UnpaidLeave,
    NationalHoliday,
    SickLeave,
    AddedDays,
    OtherNonDeductible,
    HalfDay,
}

export namespace CategoryService {

    export async function getAll(): Promise<Category[]> {
        const categories = [
            { id: LeaveCategory.PaidLeave, name: 'Paid Leave' },
            { id: LeaveCategory.UnpaidLeave, name: 'Unpaid Leave' },
            { id: LeaveCategory.NationalHoliday, name: 'National Holiday' },
            { id: LeaveCategory.SickLeave, name: 'Sick Leave' },
            { id: LeaveCategory.AddedDays, name: 'Add Days' },
            { id: LeaveCategory.OtherNonDeductible, name: 'Other Non-Deductible' },
            { id: LeaveCategory.HalfDay, name: 'Half Day' },
        ];
        return categories;
    }
    export async function getGrouped(): Promise<{ [id: number]: string }> {
        return getAll().then(data => data.reduce((acc, x) => ({ ...acc, [x.id]: x.name }), {}));
    }

}
