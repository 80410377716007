import { useState } from "react";
import { differenceInCalendarDays } from "date-fns";
import { config } from "../config";
import { Hook } from "../CoreComponents/Utils";
import { GenderType, StatusType } from "./user.service";
import { getData } from "./utils";
import { LeaveStatus } from "./vacation.service";
import { LeaveCategory } from "./category.service";

export enum VacationAction {
    Approve = 1,
    Reject,
    Delete
}

export interface NotificationEntry {
    vacationId: number;
    userId: number;
    userJobPosition: string;
    userFirstName: string;
    userLastName: string;
    userStatus: StatusType;
    userGender: GenderType;
    userDepartmentId: number;

    category: number;
    endDate: Date;
    startDate: Date;
    vacationReason: string;
    vacationStatus: LeaveStatus;
    vacationCreatedDate: Date;

    actionId?: number;
    actionReason: string;
    vacationAction?: VacationAction;
    actingUserId?: number;
    actingUserFirstName?: string;
    actingUserLastName?: string;
    actingUserJobPosition?: string;
    actionDate?: Date;
    includeWeekends?: boolean;
    daysUsed: number;
}

export type NotificationFilterValues = 'my' | 'requests' | 'history';

const lastReadNotificationDateStoreKey = 'last-notification-'
function getLastReadNotificationDate(userId: number): Date {
    const dateString = localStorage.getItem(lastReadNotificationDateStoreKey + userId);
    if (dateString) {
        return new Date(dateString);
    }

    return new Date(1);
}
export interface LastReadNotificationHook extends Hook<Date> {
    update: (newUserId: number) => void;
}
export const useLastReadNotificationDateHook = (userId: number): LastReadNotificationHook => {
    const [date, setDate] = useState<Date>(getLastReadNotificationDate(userId));
    const [id, setId] = useState(userId);

    function set(newDate: Date) {
        localStorage.setItem(lastReadNotificationDateStoreKey + id, newDate.toISOString());
        setDate(newDate);
    }

    function update(newUserId: number) {
        setId(newUserId);
        const newDate = getLastReadNotificationDate(newUserId);
        localStorage.setItem(lastReadNotificationDateStoreKey + newUserId, newDate.toISOString());
        setDate(newDate);
    }

    return {
        value: date,
        update: update,
        set: set
    }
}

export namespace NotificationService {

    export function getAll(takeCount: number, skipCount: number, q: string, filter?: NotificationFilterValues): Promise<NotificationEntry[]> {
        return getData(`${config.API_BASE_URL}/notification?take=${takeCount}&skip=${skipCount}&q=${q}` + (filter ? `&filter=${filter}` : ''))
            .then(data => data.map(x => {
                const startDate = new Date(x.startDate);
                const endDate = new Date(x.endDate);
                return {
                    // eslint-disable-next-line eqeqeq
                    daysUsed: x.category == LeaveCategory.HalfDay ?
                        0.5
                        : 1 + Math.abs(differenceInCalendarDays(endDate, startDate)),
                    ...x,
                    startDate: startDate,
                    endDate: endDate,
                    actionDate: x.actionDate && new Date(x.actionDate),
                    vacationCreatedDate: new Date(x.vacationCreatedDate),
                };
            }));
    }

    export function notificationSync(lastDate: Date): Promise<{ count: number }> {
        return getData(`${config.API_BASE_URL}/notification/notification-sync?lastDate=${lastDate.toISOString()}`);
    }

}
