import { useEffect } from 'react';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHook, useQuery, useStoreContext } from '../CoreComponents/Utils';
import { Department, DepartmentService } from '../Services/department.service';
import { User, UserService } from '../Services/user.service';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/Select';
import { IconButton } from '@material-ui/core';
import { BrandTagIcon, ExportIcon } from '../CoreComponents/CustomIcons';
import { VacationService, VacationStatusType } from '../Services/vacation.service';
import { BrandButton } from '../CoreComponents/Button';
import { BrandModal } from '../CoreComponents/Modal';
import * as Papa from 'papaparse';
import { LoadingIndicator } from '../CoreComponents/LoadingIndicator';
import { TagEntry, TagService } from '../Services/tag.service';
import { ApproverSearch, EditTags } from './ApproverLeaveWall';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Alert } from "../CoreComponents/Alert";
import { PageDataLoading } from '../CoreComponents/PageDataLoading';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    toolbarSection: {
        display: 'flex',
        alignItems: 'center',
    },
    leftSide: {
        gap: theme.spacing(3),
    },
    selectedTeam: {
        margin: 'auto 0 auto auto',
    },
    teamsEditButtonIcon: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: '#ffa3ae',
    },
    yearButton: {
        color: theme.palette.grey[700],
        border: `1px solid ${theme.palette.grey[500]}`,
        '&:hover': {
            border: `1px solid ${theme.palette.grey[500]}`,
            backgroundColor: 'transparent'
        },
    },
    title: {
        fontWeight: 600
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    centerButtons: {
        justifyContent: 'center',
    },
    positionEndButtons: {
        justifyContent: 'end'
    },
    cancelButton: {
        color: 'white',
        background: '#969696'
    },
    period: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    loadingIndicator: {
        color: 'white',
        width: '30px !important',
        height: '30px !important',
    },
    selectRoot: {
        width: theme.spacing(25),
    },
    selectInput: {
        padding: theme.spacing(1.4, 2),
    },
    calendars: {
        display: 'flex',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    reportDatesSubtitle: {
        fontSize: theme.spacing(2),
        color: '#464646',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1)
    },
    reportDatesTitle: {
        marginBottom: theme.spacing(4),
        fontSize: theme.spacing(2.75),
    },
    reportDatesContainer: {
        padding: theme.spacing(2)
    }
}));

const getDate = (date) => {
    return format(date, "do 'of' MMMM yyyy");
}

export function parseDate(date, delimiter = '/') {
    return format(date, `yyyy${delimiter}MM${delimiter}dd`);
}

export const DateRangePicker = ({ $showDateRangePicker, $selectedFromDate, $selectedToDate }) => {
    const classes = useStyles();
    const $fromDate = useHook(new Date());
    const $toDate = useHook(new Date());

    const handleFromDateChange = (pickedDate) => {
        $fromDate.set(pickedDate);
        $toDate.set(pickedDate);
    };

    const handleToDateChange = (pickedDate) => {
        $toDate.set(pickedDate);
    };

    useEffect(() => {
        if ($selectedFromDate.value) {
            $fromDate.set($selectedFromDate.value);
        }

        if ($selectedToDate.value) {
            $toDate.set($selectedToDate.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.reportDatesContainer}>
            <div className={clsx(classes.reportDatesSubtitle, classes.reportDatesTitle)}>Report Dates</div>
            <div className={classes.calendars}>
                <div>
                    <div className={classes.reportDatesSubtitle}>Start Date</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            variant="static"
                            openTo="date"
                            value={$fromDate.value}
                            onChange={handleFromDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div>
                    <div className={classes.reportDatesSubtitle}>End Date</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            variant="static"
                            openTo="date"
                            value={$toDate.value}
                            onChange={handleToDateChange}
                            minDate={parseDate($fromDate.value)}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <div className={clsx(classes.buttons, classes.positionEndButtons)}>
                <BrandButton className={classes.cancelButton} onClick={() => $showDateRangePicker.set(false)}>Cancel</BrandButton>
                <BrandButton color="primary" onClick={() => {
                    $selectedFromDate.set($fromDate.value);
                    $selectedToDate.set($toDate.value);
                    $showDateRangePicker.set(false);
                }}>
                    Done
                </BrandButton>
            </div>
        </div >
    );
}

export const Reports = ({ $userData, $navigationComponent }) => {
    const classes = useStyles();
    const { q } = useQuery();
    const { $filters } = useStoreContext();
    const $subordinateUsers = useHook<User[]>([]);
    const $showEditTagsModal = useHook(false);
    const $filter = useHook($filters.value.pageId === 'Reports' ? $filters.value.data.filter : (q || ''));
    const $tags = useHook<TagEntry[]>([]);
    const $selectedTagId = useHook<number>($filters.value.pageId === 'Reports' ? $filters.value.data.selectedTagId : 0);
    const $export = useHook<boolean>(false);
    const $waitingToExport = useHook<boolean>(false);
    const $departments = useHook<Department[]>([]);
    const $selectedDepartmentId = useHook($filters.value.pageId === 'Reports' ? $filters.value.data.selectedDepartmentId : 0);
    const $defaultFromDate = useHook(new Date(new Date().setDate(new Date().getDate() - 3)));
    const $defaultToDate = useHook(new Date(new Date().setDate(new Date().getDate() + 3)));
    const $fromDate = useHook(new Date(new Date().setDate(new Date().getDate() - 3)));
    const $toDate = useHook(new Date(new Date().setDate(new Date().getDate() + 3)));
    const $showDateRangePicker = useHook(false);
    const $csvReportData = useHook<any[]>([]);
    const $reports = useHook<any[]>([]);
    const $loading = useHook<boolean>(false);
    const $error = useHook('');

    const fetchMembersForReports = () => {
        $loading.set(true);
        VacationService.getReportsRequest(parseDate($fromDate.value, '-'), parseDate($toDate.value, '-'), $selectedDepartmentId.value, $selectedTagId.value, $filter.value)
            // remove comments (//) when fetch is fixed, because fetch currently returns a promise sometimes, instead of an array
            ///*
            .then(async resData => {
                if (resData.ok) {
                    resData = await resData.json();
                }
                //*/.then(resData => {

                const csvReportData: any[] = [];
                const reports = {};

                for (const member of resData) {
                    member.eopRemainingDays += Number(member.addedDays);
                    member.daysUsed += Number(member.addedDays);

                    csvReportData.push({
                        "Team": member.department,
                        "First Name": member.firstName,
                        "Last Name": member.lastName,
                        "Id": member.borId,
                        "Paid Leaves": member.paidLeaves,
                        "Half Day": member.halfDay,
                        "Other Non-Deductable": member.otherNonDeductable,
                        "Added Days": member.addedDays,
                        "Sick Leave": member.sickLeave,
                        "Unpaid Leave": member.unpaidLeave,
                        "National days used in Period": member.nationalHolidays,
                        "End of Period National Holidays": member.eopNationalHolidays,
                        "End of period Contractual Allowance": member.eopContractualAllowance,
                        "End of period Days from Last Year": member.eopDaysFromLastYear,
                        "End of period Remaining Days": member.eopRemainingDays,
                        "Days Used In This Period": member.daysUsedIP,
                        "Pending Paid Leave": member.pendingPaidLeave,
                        "Pending National Holidays": member.pendingNationalHolidays,
                        "Pending Half Day": member.pendingHalfDay,
                        "Pending Other Non-Deductable": member.pendingOtherNonDeductable,
                        "Pending Added Days": member.pendingAddedDays,
                        "Pending Sick Leave": member.pendingSickLeave,
                        "Pending Unpaid Leave": member.pendingUnpaidLeave,
                    });

                    member.pendingLeaves = member.pendingLeaveCount;

                    if (member.eopContractualAllowance == null) {
                        member.eopContractualAllowance = 'Missing Data'
                    }
                    if (member.eopDaysFromLastYear == null) {
                        member.eopDaysFromLastYear = 'Missing Data'
                    }
                    if (member.daysUsed == null) {
                        member.daysUsed = 'Missing Data'
                    }

                    if (reports[member.department]) {
                        reports[member.department].members.push(member);
                    } else {
                        reports[member.department] = {
                            departmentName: member.department,
                            members: [member]
                        }
                    }
                }

                $reports.set(Object.values(reports));
                $csvReportData.set(csvReportData);
                $loading.set(false);
            }).catch(err => {
                $error.set(err.message);
                setTimeout(() => {
                    $error.set('');
                }, 5000)
            });
    }

    useEffect(() => {
        fetchMembersForReports();
        $filters.set({
            pageId: 'Reports',
            data: {
                filter: $filter.value,
                selectedTagId: $selectedTagId.value,
                selectedDepartmentId: $selectedDepartmentId.value,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$fromDate.value, $toDate.value, $selectedDepartmentId.value, $selectedTagId.value, $filter.value])

    useEffect(() => {
        Promise.all([
            UserService.getAllSubordinates($userData.value.id),
            DepartmentService.getAll(),
            TagService.getAll()
        ]).then(([subordinateUsers, departments, tags]) => {
            $subordinateUsers.set(subordinateUsers);
            $departments.set(departments);
            $tags.set(tags.map(t => {
                t.users = t.users.filter(userId => subordinateUsers.some(su => su.id === userId))
                return t;
            }));
        });

        setTimeout(() => {
            $navigationComponent.set(
                <ApproverSearch initialSearchValue={$filter.value} searchValueChange={$filter.set} />
            )
        }, 50);

        return () => {
            $navigationComponent.set(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!$tags.value.length) {
            return;
        }

        const selectedTagUsers = $tags.value.find(x => x.id === $selectedTagId.value)?.users;
        if (!selectedTagUsers) {
            $selectedTagId.set(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$subordinateUsers.value, $filter.value, $selectedTagId.value, $tags.value]);

    const onExport = async () => {
        $waitingToExport.set(true);

        const asCsvData = Papa.unparse($csvReportData.value);
        const utf8Flags = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const csvFile = new File([utf8Flags, asCsvData], `report'.csv`, {
            type: "text/plain",
        });
        const url = window.URL.createObjectURL(csvFile);
        const a = document.createElement('a');
        a.href = url;
        a.download = `report-${parseDate($fromDate.value)}-${parseDate($toDate.value)}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        $waitingToExport.set(false);
        $export.set(false);
    }

    return (
        <div>
            {$error.value ?
                <Alert severity="error">{$error.value}</Alert>
                :
                null
            }
            <div className={classes.toolbar}>
                <div className={clsx(classes.toolbarSection, classes.leftSide)}>
                    <BrandSelect
                        className={classes.selectRoot}
                        selectProps={{
                            classes: { select: classes.selectInput }
                        }}
                        $value={$selectedDepartmentId}
                        onChange={e => $selectedDepartmentId.set(+e.target.value)}
                    >
                        <BrandMenuItem value={0}>All departments</BrandMenuItem>
                        {($departments && $departments.value && $departments.value.length ? $departments.value : []).map(x =>
                            <BrandMenuItem value={x.id} key={x.id}>{x.name}</BrandMenuItem>
                        )}
                    </BrandSelect>
                    <BrandButton
                        onClick={() => $showDateRangePicker.set(true)}
                        variant="outlined"
                        color="primary"
                        className={classes.yearButton}
                    >
                        Date
                    </BrandButton>
                </div>
                <BrandButton
                    onClick={() => {
                        $fromDate.set($defaultFromDate.value);
                        $toDate.set($defaultToDate.value);
                    }}
                    variant="outlined"
                    color="primary"
                >
                    {parseDate($fromDate.value)} - {parseDate($toDate.value)}
                </BrandButton>

                <div className={classes.toolbarSection}>
                    <BrandSelect
                        $value={$selectedTagId}
                        className={classes.selectedTeam}
                        size="small"
                    >
                        <BrandMenuItem value={0}>All users</BrandMenuItem>
                        {($tags && $tags.value && $tags.value.length ? $tags.value : []).map(tag =>
                            <BrandMenuItem value={tag.id} key={tag.id}>{tag.name}</BrandMenuItem>
                        )}
                    </BrandSelect>
                    <IconButton onClick={() => $showEditTagsModal.set(true)}>
                        <BrandTagIcon className={classes.teamsEditButtonIcon} />
                    </IconButton>
                    {$showEditTagsModal.value ?
                        <EditTags
                            $isOpen={$showEditTagsModal}
                            $tags={$tags}
                            subordinates={$subordinateUsers.value}
                        />
                        : null}
                    <IconButton onClick={() => $export.set(true)}>
                        <ExportIcon className={classes.teamsEditButtonIcon} />
                    </IconButton>
                </div>
            </div>
            <BrandModal open={$export.value}>
                <div className={classes.root}>
                    <div className={classes.title}>Are you sure you want to export</div>
                    <div className={classes.title}>this report for the period:</div>
                    <div className={classes.period}>
                        {getDate($fromDate.value)} to {getDate($toDate.value)}
                    </div>
                    <div className={clsx(classes.buttons, classes.centerButtons)}>
                        <BrandButton className={classes.cancelButton} onClick={() => $export.set(false)}>No</BrandButton>
                        <BrandButton color="primary" onClick={!$waitingToExport.value ? onExport : null}>
                            {$waitingToExport.value ?
                                <LoadingIndicator className={classes.loadingIndicator} />
                                :
                                'Yes'
                            }
                        </BrandButton>
                    </div>
                </div>
            </BrandModal>
            <BrandModal open={$showDateRangePicker.value} maxWidth='lg'>
                <DateRangePicker
                    $showDateRangePicker={$showDateRangePicker}
                    $selectedFromDate={$fromDate}
                    $selectedToDate={$toDate}
                />
            </BrandModal>
            {$loading.value ?
                <PageDataLoading />
                :
                <ReportsTable
                    fromDate={$fromDate}
                    toDate={$toDate}
                    departments={$reports.value}
                />
            }
        </div>
    );
}

const useTableStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        borderCollapse: 'collapse',
        textAlign: 'center',
        width: '100%',
        '& th': {
            fontWeight: 'bold',
            color: '#727272',
            background: 'white',
            margin: 0
        },
        '& td': {
            margin: 0
        }
    },
    stickyThcTh: {
        '& th': {
            position: 'sticky',
            top: '0'
        },
    },
    tableContainer: {
        overflow: 'auto',
        height: 600,
        msOverflowStyle: 'none', /* Internet Explorer 10+ */
        scrollbarWidth: 'none',  /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none' /* Safari and Chrome */
        }
    },
    firstColumn: {
        minWidth: '200px !important',
        height: '80px'
    },
    column: {
        height: '80px',
        width: '150px',
        overflowWrap: 'break-word'
    },
    tableForTableHeader: {
        marginBottom: '-80px',
        position: 'relative',
        zIndex: 1,
    },
    departmentName: {
        zIndex: 2,
        position: 'relative',
    },
    avatarCell: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center'
    },
    maleAvatar: {
        color: theme.palette.primary.main,
        background: '#E6F5FF',
        fontWeight: 'bold',
        padding: 12
    },
    femaleAvatar: {
        color: theme.palette.secondary.main,
        background: '#FFB2BB',
        fontWeight: 'bold',
        padding: 12
    },
    employeeIdLabel: {
        color: theme.palette.grey[500],
        fontSize: theme.spacing(2),
    },
    fullName: {
        fontWeight: 'bold',
        color: '#727272'
    },
    fullNameInPopup: {
        fontWeight: 'bold',
        color: '#464646'
    },
    tableData: {
        borderBottom: '1px solid #E1E1E1',
        borderTop: '1px solid #E1E1E1',
        cursor: 'pointer'
    },
    departmentNameRow: {
        borderBottom: '2px solid #E1E1E1 !important',
    },
    tableBody: {
        '& tr:nth-child(odd)': {
            '& td:nth-child(n+2)': {
                background: '#E6F5FF'
            }
        }
    },
    departmentOfMember: {
        color: '#464646',
        fontWeight: 'bold',
    },
    departmentOfMemberContainer: {
        display: 'flex',
        gap: theme.spacing(1.5),
        marginTop: '10px'
    },
    toolbar: {
        display: 'flex',
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: theme.spacing(4),
    },
    additionalRowData: {
        display: 'flex',
        gap: theme.spacing(3),
        width: '100%',
    },
    additionalRowDataContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        maxHeight: theme.spacing(18),
        overflow: 'auto',
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    category: {
        width: theme.spacing(24),
    },
    date: {
        width: theme.spacing(26),
    },
    id: {
        width: theme.spacing(8),
    },
    listTitle: {
        fontSize: theme.spacing(2),
        color: '#464646',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1)
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
    },
    positionEndButtons: {
        justifyContent: 'end'
    },
    additionalRowDataModal: {
        padding: theme.spacing(2),
        width: theme.spacing(120)
    },
    fullNameAndIdContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    },
    fullNameContainer: {
        display: 'flex',
        alignItems: 'start',
        flexWrap: 'wrap'
    },
    dayOffInfo: {
        display: 'flex',
        width: '90%',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(10)
    },
    dayOffInfoCol: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1)
    },
    dayOffInfoRow: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center'
    },
    departmentOfMemberFirstColumn: {
        width: theme.spacing(18),
    },
    departmentOfMemberSecondColumn: {
        width: theme.spacing(18),
    },
    departmentOfMemberThirdColumn: {
        width: theme.spacing(21),
    },
    leaves: {
        paddingLeft: theme.spacing(10),
        marginTop: theme.spacing(5),
    },
    pendingLeaves: {
        paddingLeft: theme.spacing(10),
        marginTop: theme.spacing(5),
    },
    cancelButton: {
        color: '#FFFFFF',
        background: '#C4C4C4'
    },
}));

const AdditionalRowData = ({ fromDate, toDate, member, onClose }) => {
    const classes = useTableStyles();
    const $leaves = useHook<any[]>([]);
    const $pendingLeaves = useHook<any[]>([]);

    const fetchAdditionalRowData = async () => {
        let leaves: any = [];
        let pendingLeaves: any = [];
        member.vacations.forEach(v => {
            if (v.id) {
                debugger;
                if (v.status === VacationStatusType.Approved) {
                    leaves.push({
                        category: v.vacationType,
                        id: v.id,
                        fromDate: v.start,
                        toDate: v.end,
                        daysUsed: v.calculatedDays
                    });
                } else if (v.status === VacationStatusType.Pending) {
                    pendingLeaves.push({
                        category: v.vacationType,
                        id: v.id,
                        fromDate: v.start,
                        toDate: v.end,
                        daysOccupied: v.calculatedDays
                    });
                }
            }
        });
        $leaves.set(leaves);
        $pendingLeaves.set(pendingLeaves);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetchAdditionalRowData() }, [])

    return (
        <div className={classes.additionalRowDataModal}>
            <div className={classes.toolbar}>
                <div className={classes.avatarCell}>
                    <Avatar className={member.gender === 1 ? classes.maleAvatar : classes.femaleAvatar}>
                        {member.firstName.charAt(0) + member.lastName.charAt(0)}
                    </Avatar>
                    <div className={classes.fullNameAndIdContainer}>
                        <div className={clsx(classes.fullNameInPopup, classes.fullNameContainer)}>
                            <div>{member.firstName}</div>&nbsp;<div>{member.lastName}</div>
                        </div>
                        <div>{member.jobPosition}</div>
                    </div>
                </div>
                <div className={classes.departmentOfMemberContainer}>
                    <div>ID</div>
                    <div className={classes.departmentOfMember}>{member.borId}</div>
                </div>
                <div className={classes.departmentOfMemberContainer}>
                    <div>Department</div>
                    <div className={classes.departmentOfMember}>{member.department}</div>
                </div>
            </div>
            <div className={classes.dayOffInfo}>
                <div className={classes.dayOffInfoCol}>
                    <div className={classes.dayOffInfoRow}>
                        <div className={classes.departmentOfMemberFirstColumn}>End of period Remaining Days</div>
                        <div className={classes.departmentOfMember}>{member?.eopRemainingDays || 0}</div>
                    </div>
                    <div className={classes.dayOffInfoRow}>
                        <div className={classes.departmentOfMemberFirstColumn}>Days used in period</div>
                        <div className={classes.departmentOfMember}>{member?.daysUsedIP || 0}</div>
                    </div>
                </div>
                <div className={classes.dayOffInfoCol}>
                    <div className={classes.dayOffInfoRow}>
                        <div className={classes.departmentOfMemberSecondColumn}>End of Period National Holidays </div>
                        <div className={classes.departmentOfMember}>{member?.eopNationalHolidays || 0}</div>
                    </div>
                    <div className={classes.dayOffInfoRow}>
                        <div className={classes.departmentOfMemberSecondColumn}>National days used in Period  </div>
                        <div className={classes.departmentOfMember}>{member?.nationalHolidays || 0}</div>
                    </div>
                </div>
                <div className={classes.dayOffInfoCol}>
                    <div className={classes.dayOffInfoRow}>
                        <div className={classes.departmentOfMemberThirdColumn}>End of period Days from Last Year</div>
                        <div className={classes.departmentOfMember}>{member?.eopDaysFromLastYear || 0}</div>
                    </div>
                    <div className={classes.dayOffInfoRow}>
                        <div className={classes.departmentOfMemberThirdColumn}>End of period Contractual Allowance</div>
                        <div className={classes.departmentOfMember}>{member?.eopContractualAllowance || 0}</div>
                    </div>
                </div>
            </div>

            <div className={classes.leaves}>
                <div className={classes.additionalRowData}>
                    <div className={clsx(classes.category, classes.listTitle)}>Approved Leaves</div>
                    <div className={clsx(classes.id, classes.listTitle)}>ID</div>
                    <div className={clsx(classes.date, classes.listTitle)}>From</div>
                    <div className={clsx(classes.date, classes.listTitle)}>To</div>
                    <div className={clsx(classes.category, classes.listTitle)}>Total Days Used<br />in Filter</div>
                </div>
                <div className={classes.additionalRowDataContainer}>
                    {$leaves.value.map((vacation, i) => {
                        return (
                            <div key={i} className={classes.additionalRowData}>
                                <div className={classes.category}>{vacation.category}</div>
                                <div className={classes.id}>{vacation.id}</div>
                                <div className={classes.date}>{format(new Date(vacation.fromDate), 'EEE, d MMMM yyyy')}</div>
                                <div className={classes.date}>{format(new Date(vacation.toDate), 'EEE, d MMMM yyyy')}</div>
                                <div className={classes.category}>{vacation.daysUsed}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={classes.pendingLeaves}>
                <div className={classes.additionalRowData}>
                    <div className={clsx(classes.category, classes.listTitle)}>Pending Leaves</div>
                    <div className={clsx(classes.id, classes.listTitle)}>ID</div>
                    <div className={clsx(classes.date, classes.listTitle)}>From</div>
                    <div className={clsx(classes.date, classes.listTitle)}>To</div>
                    <div className={clsx(classes.category, classes.listTitle)}>Days Occupied<br />in Filter</div>
                </div>
                <div className={classes.additionalRowDataContainer}>
                    {$pendingLeaves.value.map((vacation, i) => {
                        return (
                            <div key={i} className={classes.additionalRowData}>
                                <div className={classes.category}>{vacation.category}</div>
                                <div className={classes.id}>{vacation.id}</div>
                                <div className={classes.date}>{format(new Date(vacation.fromDate), 'EEE, d MMMM yyyy')}</div>
                                <div className={classes.date}>{format(new Date(vacation.toDate), 'EEE, d MMMM yyyy')}</div>
                                <div className={classes.category}>{vacation.daysOccupied}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={clsx(classes.buttons, classes.positionEndButtons)}>
                <BrandButton className={classes.cancelButton} onClick={onClose}>
                    Close
                </BrandButton>
            </div>
        </div>
    );
}


const ReportsTable = ({ fromDate, toDate, departments }) => {
    const classes = useTableStyles();
    const $showSelectedMemberPopup = useHook(false);
    const $selectedMember = useHook(0);

    return (
        <div>
            <BrandModal open={$showSelectedMemberPopup.value} maxWidth='xl'>
                <AdditionalRowData
                    fromDate={fromDate}
                    toDate={toDate}
                    member={$selectedMember.value}
                    onClose={() => $showSelectedMemberPopup.set(false)}
                />
            </BrandModal>
            <div className={classes.container}>
                <table className={clsx(classes.table, classes.stickyThcTh, classes.tableForTableHeader)}>
                    <thead>
                        <tr>
                            {/* 1 */}<th className={classes.firstColumn} />
                            {/* 2 */}<th className={classes.column}><span>Paid<br />Leaves</span></th>
                            {/* 3 */}<th className={classes.column}>Half<br />Day</th>
                            {/* 4 */}<th className={classes.column}>Other<br />Non-Deductable</th>
                            {/* 5 */}<th className={classes.column}>Added<br />Days</th>
                            {/* 6 */}<th className={classes.column}>Sick<br />Leave</th>
                            {/* 7 */}<th className={classes.column}>Unpaid<br />Leave</th>
                            {/* 8 */}<th className={classes.column}>National days used <br />in Period</th>
                            {/* 9 */}<th className={classes.column}>Number of<br />pending Leaves</th>
                            {/* 10 */}<th className={classes.column}>End of Period National Holidays</th>
                            {/* 11 */}<th className={classes.column}>End of period Contractual Allowance</th>
                            {/* 12 */}<th className={classes.column}>End of period Days from Last Year</th>
                            {/* 13 */}<th className={classes.column}>End of period Remaining Days</th>
                            {/* 14 */}<th className={classes.column}>Days Used In<br />This Period</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className={classes.tableContainer}>
                <div className={classes.container}>
                    {departments.map((department, i) => {
                        return (
                            <table key={department.departmentName + i} className={clsx(classes.table, classes.stickyThcTh)}>
                                <thead>
                                    <tr key={i} className={classes.departmentNameRow}>
                                        {/* 1 */}<th className={clsx(classes.firstColumn, classes.departmentName)}>{department.departmentName}</th>
                                        {/* 2 */}<th className={classes.column} />
                                        {/* 3 */}<th className={classes.column} />
                                        {/* 4 */}<th className={classes.column} />
                                        {/* 5 */}<th className={classes.column} />
                                        {/* 6 */}<th className={classes.column} />
                                        {/* 7 */}<th className={classes.column} />
                                        {/* 8 */}<th className={classes.column} />
                                        {/* 9 */}<th className={classes.column} />
                                        {/* 10 */}<th className={classes.column} />
                                        {/* 11 */}<th className={classes.column} />
                                        {/* 12 */}<th className={classes.column} />
                                        {/* 13 */}<th className={classes.column} />
                                        {/* 14 */}<th className={classes.column} />
                                    </tr>
                                </thead>
                                <tbody className={classes.tableBody}>
                                    {department.members.map((member, j) => {

                                        return (
                                            <tr
                                                key={member.borId + j + i}
                                                className={classes.tableData}
                                                onClick={() => {
                                                    $showSelectedMemberPopup.set(true);
                                                    $selectedMember.set(member);
                                                }}
                                            >
                                                {/* 1 */}<td className={clsx(classes.firstColumn, classes.avatarCell)}>
                                                    <Avatar className={member.gender === 1 ? classes.maleAvatar : classes.femaleAvatar}>
                                                        {member.firstName.charAt(0) + member.lastName.charAt(0)}
                                                    </Avatar>
                                                    <div className={classes.fullNameAndIdContainer}>
                                                        <div className={clsx(classes.fullName, classes.fullNameContainer)}>
                                                            <div>{member.firstName}</div>&nbsp;<div>{member.lastName}</div>
                                                        </div>
                                                        <div className={classes.employeeIdLabel}>{member.borId}</div>
                                                    </div>
                                                </td>
                                                {/* 2 */}<td className={classes.column}>{member.paidLeaves}</td>
                                                {/* 3 */}<td className={classes.column}>{member.halfDay}</td>
                                                {/* 4 */}<td className={classes.column}>{member.otherNonDeductable}</td>
                                                {/* 5 */}<td className={classes.column}>{member.addedDays}</td>
                                                {/* 6 */}<td className={classes.column}>{member.sickLeave}</td>
                                                {/* 7 */}<td className={classes.column}>{member.unpaidLeave}</td>
                                                {/* 8 */}<td className={classes.column}>{member.nationalHolidays}</td>
                                                {/* 9 */}<td className={classes.column}>{member.pendingLeaves}</td>
                                                {/* 10 */}<td className={classes.column}>{member.eopNationalHolidays}</td>
                                                {/* 11 */}<td className={classes.column}>{member.eopContractualAllowance}</td>
                                                {/* 12 */}<td className={classes.column}>{member.eopDaysFromLastYear}</td>
                                                {/* 13 */}<td className={classes.column}>{member.eopRemainingDays}</td>
                                                {/* 14 */}<td className={classes.column}>{member.daysUsedIP}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        );
                    })}
                </div>
            </div>
        </div >
    );
};