import React, { useEffect } from "react"
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Avatar from "@material-ui/core/Avatar";
import { BrandButton } from "../CoreComponents/Button";
import { BrandCard } from "../CoreComponents/Card";
import { Input } from "../CoreComponents/Input";
import { BoldText } from "../CoreComponents/Typography";
import { getDatesInRange, useHook, useStoreContext } from "../CoreComponents/Utils";
import { CategoryService, LeaveCategory } from "../Services/category.service";
import { Department, DepartmentService } from "../Services/department.service";
import { NotificationEntry, NotificationFilterValues, NotificationService, VacationAction } from "../Services/notification.service";
import { GenderType, StatusType, UserService, UserType } from "../Services/user.service";
import { BrandModal } from "../CoreComponents/Modal";
import { LeaveStatus, VacationService } from "../Services/vacation.service";
import { LoadingIndicator } from "../CoreComponents/LoadingIndicator";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpIcon from '@material-ui/icons/Help';
import { BrandDeleteIcon } from "../CoreComponents/CustomIcons";
// import debounce from "@material-ui/core/utils/debounce";
import { debounce } from "../Services/utils";
import { enUS } from 'date-fns/esm/locale';
import { formatRelative } from "date-fns";
import { Alert, SlimAlert } from "../CoreComponents/Alert";

const formatRelativeLocale = {
    lastWeek: "'Last' eeee 'at' H:mm",
    yesterday: "'Yesterday at' H:mm",
    today: "'Today at' H:mm",
    tomorrow: "'Tomorrow at'",
    nextWeek: "eeee 'at' H:mm",
    other: 'd MMMM'
};

const locale = {
    ...enUS,
    weekStartsOn: 1,
    formatRelative: token => formatRelativeLocale[token],
};

const useNotificationStyles = makeStyles((theme) => ({
    dialogTitle: {
        fontWeight: 'bold',
        margin: theme.spacing(2, 'auto', 0, 2),
        textAlign: 'left',
    },
    creationDate: {
        margin: theme.spacing(2, 2, 0, 'auto'),
        fontWeight: 'bold',
        color: '#18A0FB',
    },
    dialogContent: {
        display: 'flex',
    },
    action: {
        marginLeft: theme.spacing(8),
        // marginBottom: theme.spacing(1),
        display: 'flex',
        '&>*': {
            margin: theme.spacing('auto', 1),
        },
    },

    actionIcon: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    approved: {
        color: '#00b828',
    },
    declined: {
        color: '#ff6073',
    },

    heading: {
        marginBottom: `${theme.spacing(2.5)}px !important`,
    },

    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        margin: theme.spacing('auto', 3, 'auto', 1),
        fontWeight: 'bold',
    },
    avatarFemale: {
        backgroundColor: '#FFB2BB',
        color: '#FF6073',
    },
    avatarMale: {
        backgroundColor: '#E6F5FF',
        color: '#18A0FB',
    },
    avatarInactive: {
        backgroundColor: '#EFEFEF',
        color: '#727272',
    },

    root: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    content: {
        display: 'grid',
        gridTemplateColumns: `${theme.spacing(12)}px repeat(4, 1fr)`,
        gridTemplateRows: '1fr 1fr auto',
        gridColumnGap: theme.spacing(1.25),
        gridRowGap: theme.spacing(1),
        margin: theme.spacing(4, 3, 5, 7),
    },
    firstColumn: {
        gridColumn: '2 / -4',
    },
    secondColumn: {
        gridColumn: '2 / -3',
    },

    notificationInput: {
        marginTop: theme.spacing(2.5),
    },
    inputField: {
        width: theme.spacing(35),
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        // flexWrap: 'wrap',
        // margin: theme.spacing(0, 0, 1, 0),
        width: '100%',
        justifyContent: 'space-around',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        margin: 'auto 0',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: theme.spacing(0, 2.5, 2.5, 0),
        width: '100%',
        '& > *': {
            marginTop: 'auto',
            marginRight: theme.spacing(2),
        },
    },

    approverOffset: {
        margin: 'auto 0 0 0',
    },
}));
export const Notification = (
    { departments, notification, onApprove, onDecline, onDelete }
        : { departments: Department[], notification: NotificationEntry, onApprove: () => void, onDecline: () => void, onDelete: () => void }
) => {
    const classes = useNotificationStyles();
    const { $currentUser } = useStoreContext();
    const $category = useHook<string>('');
    const $department = useHook<string>('');
    const currentUserId = $currentUser.value.id;
    const isActionFromCurrentUser = currentUserId === notification.actingUserId;
    const isRequestFromCurrentUser = currentUserId === notification.userId;
    const isRequestingUserSameAsActing = notification.actingUserId === notification.userId;
    const $showWeekendsIncluded = useHook<boolean>(false);

    useEffect(() => {
        if (notification && notification.startDate && notification.endDate) {
            let daysBetweenDates = getDatesInRange(notification.startDate, notification.endDate);
            // eslint-disable-next-line eqeqeq
            let showWeekendStatus = daysBetweenDates.filter(x => x.getDay() === 6 || x.getDay() === 0).length > 0 && notification.includeWeekends == true
            // console.log(notification.vacationId, notification.includeWeekends, notification.startDate, notification.endDate, showWeekendStatus);
            // console.log(notification.vacationId, notification.includeWeekends == true);
            $showWeekendsIncluded.set(showWeekendStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        CategoryService.getAll()
            .then(data => {
                const category = data.find(x => x.id === notification.category);
                $category.set(category ? category.name : 'MISSING CATEGORY');
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    useEffect(() => {
        const department = departments.find(x => x.id === notification.userDepartmentId);
        $department.set(department ? department.name : 'UNKNOWN DEPARTMENT');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification, departments]);

    // eslint-disable-next-line eqeqeq
    const isHalfDay = notification.category == LeaveCategory.HalfDay;

    return <BrandCard className={classes.root}>
        <div className={clsx(classes.row, classes.heading)}>
            <h2 className={classes.dialogTitle}>
                ID {notification.vacationId}
            </h2>
            <span className={classes.creationDate}>
                {formatRelative(
                    notification.actionDate ? notification.actionDate : notification.vacationCreatedDate,
                    new Date(),
                    { locale }
                )}
            </span>
        </div>
        {notification.actionId ?
            <div className={classes.action}>
                {notification.vacationAction === VacationAction.Approve ?
                    <>
                        <CheckCircleIcon className={clsx(classes.actionIcon, classes.approved)} />
                        <BoldText>
                            {isActionFromCurrentUser ?
                                'You have approved a leave request for: '
                                : isRequestFromCurrentUser ?
                                    'Your leave request has been approved: '
                                    :
                                    'A leave request has been approved by another approver: '
                            }
                        </BoldText>
                    </>
                    : notification.vacationAction === VacationAction.Reject ?
                        <>
                            <CancelIcon className={clsx(classes.actionIcon, classes.declined)} />
                            <BoldText>
                                {isActionFromCurrentUser ?
                                    'You have declined a leave request for: '
                                    : isRequestFromCurrentUser ?
                                        'Your leave request has been declined: '
                                        :
                                        'A leave request has been declined by another approver for: '
                                }
                            </BoldText>
                        </>
                        :
                        <>
                            <BrandDeleteIcon className={classes.actionIcon} />
                            <BoldText>
                                {isRequestFromCurrentUser ?
                                    isActionFromCurrentUser ?
                                        'You have deleted your leave request: ' // requested by you, deleted by you
                                        :
                                        'Your leave request has been deleted: ' // requested by you, deleted by sb
                                    : isRequestingUserSameAsActing ?
                                        'A request has been deleted by: ' // requested by user, deleted by user
                                        : isActionFromCurrentUser ?
                                            'You have deleted a leave request for: ' // requested by user, deleted by you
                                            :
                                            'A leave request has been deleted by another approver: ' // requested by user, deleted by sb
                                }
                            </BoldText>
                        </>
                }
                {notification.vacationAction !== VacationAction.Approve ?
                    <span>{notification.vacationReason}</span>
                    : null}
            </div>
            :
            <div className={classes.action}>
                <SlimAlert severity="warning" classes={{ root: classes.actionIcon }} Component={HelpIcon} />
                <BoldText>Pending:</BoldText>
            </div>
        }
        <div className={classes.content}>
            {notification.userFirstName && notification.userLastName ?
                <Avatar
                    className={clsx(
                        classes.avatar,
                        notification.userStatus === StatusType.Inactive ? classes.avatarInactive
                            : notification.userGender === GenderType.Female ? classes.avatarFemale
                                : classes.avatarMale
                    )}
                >
                    {notification.userFirstName[0] + notification.userLastName[0]}
                </Avatar>
                : null}
            <div className={classes.column}>
                <BoldText>{notification.userFirstName} {notification.userLastName}</BoldText>
                <span>{notification.userJobPosition}</span>
            </div>
            <div className={classes.column}>
                <BoldText>ID</BoldText>
                <span>ID-{notification.userId}</span>
            </div>
            <div className={classes.column}>
                <BoldText>Leave type</BoldText>
                <span>{$category.value}</span>
            </div>
            <div className={classes.column}>
                <BoldText>Department</BoldText>
                <span>{$department.value}</span>
            </div>
            <div className={clsx(classes.column, isHalfDay ? classes.secondColumn : classes.firstColumn)}>
                <BoldText>From</BoldText>
                <span>{notification.startDate.toDateString()}</span>
            </div>
            {isHalfDay ? null :
                <div className={classes.column}>
                    <BoldText>To</BoldText>
                    <span>{notification.endDate.toDateString()}</span>
                </div>
            }
            <div className={classes.column}>
                <BoldText>Days used</BoldText>
                <span>{notification.daysUsed}</span>
            </div>
            {$showWeekendsIncluded.value ?
                <div className={classes.column}>
                    <BoldText>Weekends Included</BoldText>
                    <span>Yes</span>
                </div>
                :
                null
            }


            {!notification.vacationAction || notification.vacationAction === VacationAction.Approve ?
                <Input
                    label="Reason"
                    disabled
                    className={clsx(classes.inputField, classes.secondColumn, classes.notificationInput)}
                    multiline
                    rows={4}
                    // rowsMax={4}
                    value={notification.vacationReason}
                />
                :
                <Input
                    label="Reason"
                    disabled
                    className={clsx(classes.inputField, classes.secondColumn, classes.notificationInput)}
                    multiline
                    rows={4}
                    // rowsMax={4}
                    value={notification.actionReason}
                />
            }
            {notification.actingUserId && !isActionFromCurrentUser ?
                <>
                    <div className={clsx(classes.column, classes.approverOffset)}>
                        <BoldText>By</BoldText>
                        <span>{notification.actingUserFirstName} {notification.actingUserLastName}</span>
                    </div>
                    <div className={clsx(classes.column, classes.approverOffset)}>
                        <BoldText>Position</BoldText>
                        <span>{notification.actingUserJobPosition}</span>
                    </div>
                </>
                : null}
        </div>
        {notification.actionId && notification.vacationStatus === LeaveStatus.Approved ?
            <div className={classes.buttons}>
                <BrandButton color="secondary" onClick={onDelete}>Delete</BrandButton>
            </div>
            : null}
        {!notification.actionId && (
            (!isRequestFromCurrentUser && $currentUser.value.userType === UserType.Approver)
            || (isRequestFromCurrentUser && $currentUser.value.canSelfApprove)
        ) ?
            <div className={classes.buttons}>
                <BrandButton color="secondary" onClick={onDecline}>Decline</BrandButton>
                <BrandButton color="primary" onClick={onApprove}>Approve</BrandButton>
            </div>
            : null}
    </BrandCard>
}

const useDialogStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    title: {
        textAlign: 'center',
    },
    inputField: {
        width: '100%',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: theme.spacing(2, 0, 2, 0),
        width: '100%',
        '& > *': {
            marginTop: 'auto',
            marginRight: theme.spacing(2),
        },
    },
    centerButtons: {
        justifyContent: 'center',
    },
}));

const ApproveDialog = ({ $isOpen, notification, onApprove }) => {
    const classes = useDialogStyles();
    const $error = useHook('');
    const {
        $currentUser
    } = useStoreContext();

    const handleApprove = () => {
        VacationService.approveVacationRequest(notification.vacationId)
            .then(() => {
                $isOpen.set(false);
                onApprove();
                if (notification.userId === $currentUser.value.id) {
                    UserService.getById(notification.userId)
                        .then(data => $currentUser.set(data));
                }
            })
            .catch(err => $error.set(err.message));
    }

    return <BrandModal
        open={$isOpen.value}
        title="Are you sure you want to approve this leave request?"
        classes={{ root: classes.title }}
    >
        {$error.value ?
            <Alert severity="error">{$error.value}</Alert>
            : null}
        <div className={classes.root}>
            <div className={clsx(classes.buttons, classes.centerButtons)}>
                <BrandButton onClick={() => $isOpen.set(false)}>Cancel</BrandButton>
                <BrandButton color="primary" onClick={handleApprove}>Approve</BrandButton>
            </div>
        </div>
    </BrandModal>
}

const DeclineDialog = ({ $isOpen, notification, onDecline }) => {
    const classes = useDialogStyles();
    const $declineReason = useHook('');
    const $error = useHook('');
    const {
        $currentUser
    } = useStoreContext();

    const handleDecline = () => {
        VacationService.rejectVacationRequest(notification.vacationId, $declineReason.value)
            .then(() => {
                $isOpen.set(false);
                onDecline();
                if (notification.userId === $currentUser.value.id) {
                    UserService.getById(notification.userId)
                        .then(data => $currentUser.set(data));
                }
            });
    };

    return <BrandModal
        open={$isOpen.value}
        title="Are you sure you want to decline this leave request?"
        classes={{ root: classes.title }}
    >
        <div className={classes.root}>
            <Input
                label="Reason for decline (Optional)"
                className={classes.inputField}
                multiline
                rows={4}
                // rowsMax={4}
                $value={$declineReason}
            />
            {$error.value ?
                <Alert severity="error">{$error.value}</Alert>
                : null}
            <div className={classes.buttons}>
                <BrandButton onClick={() => $isOpen.set(false)}>Cancel</BrandButton>
                <BrandButton
                    color="secondary"
                    onClick={handleDecline}
                >
                    Decline
                </BrandButton>
            </div>
        </div>
    </BrandModal>
}

const DeleteDialog = ({ $isOpen, notification, onDelete }) => {
    const classes = useDialogStyles();
    const $deleteReason = useHook('');
    const $error = useHook('');
    const {
        $currentUser
    } = useStoreContext();

    const deleteHandler = () => {
        VacationService.deleteVacationRequest(notification.vacationId, $deleteReason.value)
            .then(() => {
                $isOpen.set(false);
                onDelete();
                if (notification.userId === $currentUser.value.id) {
                    UserService.getById(notification.userId)
                        .then(data => $currentUser.set(data));
                }
            }).catch(err => $error.set(err.message));
    };

    return <BrandModal
        open={$isOpen.value}
        title="Are you sure you want to delete this leave request?"
        classes={{ root: classes.title }}
    >
        <div className={classes.root}>
            <Input
                label="Reason for delete (Optional)"
                className={classes.inputField}
                multiline
                rows={4}
                // rowsMax={4}
                $value={$deleteReason}
            />
            {$error.value ?
                <Alert severity="error">{$error.value}</Alert>
                : null}
            <div className={classes.buttons}>
                <BrandButton onClick={() => $isOpen.set(false)}>Cancel</BrandButton>
                <BrandButton
                    color="secondary"
                    onClick={deleteHandler}
                >
                    Delete
                </BrandButton>
            </div>
        </div>
    </BrandModal>
}

const useNotificationSearchStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    backButton: {
        margin: theme.spacing('auto', 2, 'auto', 2),
        padding: theme.spacing(1),
    },
    searchButton: {
        margin: theme.spacing('auto', 2, 'auto', 0),
        padding: theme.spacing(1),
    },
    searchInput: {
        width: theme.spacing(45),
        margin: theme.spacing('auto', 1),
        '& .MuiOutlinedInput-input': {
            padding: theme.spacing(1.4, 2),
        },
    },
}));
export const NotificationSearch = (
    { searchValueChange, ...props }
        : { searchValueChange?: (string) => void, showSearchButton?: boolean }
) => {
    const classes = useNotificationSearchStyles();
    const $search = useHook<string>('');

    return <form
        className={classes.root}
        onSubmit={e => {
            e.preventDefault();
        }}
    >
        <Input
            placeholder="Search"
            value={$search.value}
            onChange={e => {
                const v = e.target.value.toLowerCase();
                $search.set(v);
                if (searchValueChange) {
                    searchValueChange(v);
                }
            }}
            classes={{ root: classes.searchInput }}
        />
        {/* {props.showSearchButton ?
            <BrandButton
                color="primary"
                type="submit"
                className={classes.searchButton}
            >
                <FontAwesomeIcon icon={faSearch} />
            </BrandButton>
        : null} */}
    </form>
}

const useNotificationsStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
    },
    filters: {
        width: theme.spacing(30),
        maxWidth: theme.spacing(30),
        minWidth: theme.spacing(30),
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        borderRight: '0.2px solid rgba(113, 113, 113, 0.49)',
        position: 'fixed',
    },
    filterButton: {
        backgroundColor: 'inherit',
        border: 'unset',
        boxShadow: 'unset',
    },
    notifications: {
        padding: theme.spacing(2),
        width: '100%',
        // filter width + 2 * filter padding + border
        marginLeft: theme.spacing(30) + 2 * theme.spacing(2) + 2,
        display: 'flex',
        flexDirection: 'column',
    },
}));

export const Notifications = ({ $navigationComponent, ...props }) => {
    const takeCount = 5;
    const {
        $currentUser,
        $missedNotificationsCount,
        $blockNotificationCounterUpdate,
        $lastReadNotificationDate
    } = useStoreContext();
    const classes = useNotificationsStyles();
    const $notifications = useHook<NotificationEntry[]>([]);
    const $departments = useHook<Department[]>([]);
    const $selectedFilter = useHook<NotificationFilterValues | undefined>(undefined);
    const $didReachBottom = useHook(false);
    const $loadMoreNotifications = useHook(false);
    const $isLoading = useHook(false);
    const $isDeclineDialogOpen = useHook(false);
    const $isApproveDialogOpen = useHook(false);
    const $isDeleteDialogOpen = useHook(false);
    const $currentNotification = useHook<NotificationEntry | undefined>(undefined);
    const $q = useHook('');
    const $isDebounced = useHook(false);
    const $lastRead = useHook($lastReadNotificationDate.value);

    function updateLastRead(data) {
        if (data.length) {
            const lastDate = data.reduce((accumulator, currentValue) => {
                const date = currentValue.actionDate || currentValue.vacationCreatedDate;
                // console.log('date', date, 'accumulator', accumulator);
                return accumulator < date ? date : accumulator;
            }, $lastReadNotificationDate.value);
            // console.log('lastDate', lastDate);

            if ($lastReadNotificationDate.value < lastDate) {
                $blockNotificationCounterUpdate.set(true);
                $missedNotificationsCount.set(0)
            } else {
                $blockNotificationCounterUpdate.set(false);
            }

            $lastReadNotificationDate.set(lastDate);
        }
    }

    const refreshNotifications = () => {
        if ($isLoading.value && !$isDebounced.value) {
            return;
        }

        // console.log('refreshNotifications');
        $isLoading.set(true);
        NotificationService.getAll(Math.max(takeCount, $notifications.value.length), 0, $q.value, $selectedFilter.value)
            .then(data => {
                updateLastRead(data);
                $notifications.set(data);
                $isLoading.set(false);
                $isDebounced.set(false);
            }, error => console.error(error.message.msg));
    };

    const loadNotifications = () => {
        // console.log('loadNotifications');
        if ($didReachBottom.value || $isLoading.value) {
            // console.log('skip', $didReachBottom.value, $isLoading.value);
            return;
        }

        $isLoading.set(true);
        NotificationService.getAll(takeCount, $notifications.value.length, $q.value, $selectedFilter.value)
            .then(data => {
                updateLastRead(data);
                if (data.length > 0) {
                    $notifications.set([...$notifications.value, ...data]);
                }

                $didReachBottom.set(data.length < takeCount);
                $isLoading.set(false);
                $loadMoreNotifications.set(false);
            }, error => console.error(error.message.msg));
    };

    function handleScroll(event) {
        const { scrollTop, offsetHeight } = document.documentElement;
        const innerHeight = window.innerHeight;
        if (innerHeight + scrollTop > offsetHeight * 0.8) {
            // console.log(scrollTop, innerHeight, offsetHeight);
            $loadMoreNotifications.set(true);
        }
    }

    useEffect(() => {
        DepartmentService.getAll()
            .then(data => $departments.set(data));
        // loadNotifications();

        $navigationComponent.set(
            <NotificationSearch searchValueChange={debounce(
                v => {
                    // console.log('input', v);
                    $notifications.set([]);
                    $isLoading.set(true);
                }, v => {
                    // console.log('debounced', v);
                    $isDebounced.set(true);
                    $q.set(v);
                }, 500)}
            />
        );

        window.addEventListener('scroll', handleScroll);
        $blockNotificationCounterUpdate.set(true);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            $blockNotificationCounterUpdate.set(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ($isLoading.value && !$isDebounced.value) {
            return;
        }

        $isLoading.set(true);
        NotificationService.getAll(takeCount, 0, $q.value, $selectedFilter.value)
            .then(data => {
                updateLastRead(data);
                document.documentElement.scrollTop = 0;

                $notifications.set(data);
                $isLoading.set(false);
                $isDebounced.set(false);
                const didReachBottom = data.length < takeCount;
                // console.log('didReachBottom', didReachBottom);
                $didReachBottom.set(didReachBottom);
                $loadMoreNotifications.set(didReachBottom);
            }, error => console.error(error.message.msg));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$selectedFilter.value, $q.value]);

    useEffect(() => {
        if (!$loadMoreNotifications.value) {
            return;
        }

        loadNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$loadMoreNotifications.value]);

    useEffect(() => {
        if (!$missedNotificationsCount.value) {
            return;
        }

        refreshNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$missedNotificationsCount.value]);

    return <div className={classes.root}>
        <div className={classes.filters}>
            <BrandButton
                color={!$selectedFilter.value ? "primary" : undefined}
                className={!$selectedFilter.value ? undefined : classes.filterButton}
                onClick={() => $selectedFilter.set(undefined)}
            >
                All notifications
            </BrandButton>
            {$currentUser.value.userType === UserType.Approver ?
                <>
                    <BrandButton
                        color={$selectedFilter.value === 'my' ? "primary" : undefined}
                        className={$selectedFilter.value === 'my' ? undefined : classes.filterButton}
                        onClick={() => $selectedFilter.set('my')}
                    >
                        My notifications
                    </BrandButton>
                    <BrandButton
                        color={$selectedFilter.value === 'requests' ? "primary" : undefined}
                        className={$selectedFilter.value === 'requests' ? undefined : classes.filterButton}
                        onClick={() => $selectedFilter.set('requests')}
                    >
                        Requests
                    </BrandButton>
                    <BrandButton
                        color={$selectedFilter.value === 'history' ? "primary" : undefined}
                        className={$selectedFilter.value === 'history' ? undefined : classes.filterButton}
                        onClick={() => $selectedFilter.set('history')}
                    >
                        History
                    </BrandButton>
                </>
                : null}
        </div>
        <div className={classes.notifications}>
            {$notifications.value.length < 1 && !$isLoading.value ?
                'No notifications'
                : null}
            {$isApproveDialogOpen.value ?
                <ApproveDialog
                    $isOpen={$isApproveDialogOpen}
                    notification={$currentNotification.value}
                    onApprove={refreshNotifications}
                />
                : null}
            {$isDeclineDialogOpen.value ?
                <DeclineDialog
                    $isOpen={$isDeclineDialogOpen}
                    notification={$currentNotification.value}
                    onDecline={refreshNotifications}
                />
                : null}
            {$isDeleteDialogOpen.value ?
                <DeleteDialog
                    $isOpen={$isDeleteDialogOpen}
                    notification={$currentNotification.value}
                    onDelete={refreshNotifications}
                />
                : null}
            {$notifications.value.map((x, i) =>
                // <div key={`${x.vacationId}_${(x.vacationAction as any)}`}>
                //     ID {x.vacationId}
                //     <pre>{JSON.stringify(x, null, 2)}</pre>
                <NewLabel
                    key={`${i}_${x.vacationId}_${(x.vacationAction as number)}`}
                    isVisible={$lastRead.value < (x.actionDate || x.vacationCreatedDate)}
                >
                    <Notification
                        departments={$departments.value}
                        notification={x}
                        onApprove={() => {
                            $currentNotification.set(x);
                            $isApproveDialogOpen.set(true);
                        }}
                        onDecline={() => {
                            $currentNotification.set(x);
                            $isDeclineDialogOpen.set(true);
                        }}
                        onDelete={() => {
                            $currentNotification.set(x);
                            $isDeleteDialogOpen.set(true);
                        }}
                    />
                </NewLabel>
                // </div>
            )}
            {$isLoading.value ?
                <LoadingIndicator />
                : null}
        </div>
    </div>
}

const useNewLabelStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        position: 'relative',
        flexShrink: 0,
        verticalAlign: 'middle',
        width: '100%',
        maxWidth: theme.spacing(130),
    },
    topRightAnchor: {
        top: 0,
        right: 0,
        transform: 'scale(1) translate(50%, -50%)',
        transformOrigin: '100% 0%',
    },
    label: {
        color: '#fafafa',
        backgroundColor: '#ffb800',
        width: theme.spacing(5),
        height: theme.spacing(5),
        // margin: theme.spacing('auto', 1),
        fontWeight: 'bold',
        display: 'flex',
        overflow: 'hidden',
        position: 'absolute',
        // fontSize: '1.25rem',
        alignItems: 'center',
        flexShrink: 0,
        lineHeight: '1',
        borderRadius: '50%',
        justifyContent: 'center',
    },
}));

const NewLabel = ({ isVisible, ...props }) => {
    const classes = useNewLabelStyles();
    return <div className={classes.root}>
        {props.children}
        {isVisible ?
            <span className={clsx(classes.topRightAnchor, classes.label)}>New</span>
            : null}
    </div>
}
