import React, { useEffect } from 'react';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';
import { Section, SectionContainer } from '../CoreComponents/Section';
import { useHook, useStoreContext } from '../CoreComponents/Utils';
import { Department, DepartmentService } from '../Services/department.service';
import { GenderType, StatusType, User, UserService } from '../Services/user.service';
import { Input } from '../CoreComponents/Input';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
    userCard: {
        margin: theme.spacing(2),
        width: theme.spacing(30),
        height: theme.spacing(15),
        display: 'flex',
        flexDirection: 'row',
        borderRadius: theme.spacing(1.8),
        boxShadow: `${theme.spacing(0, 1, 1)} rgba(0, 0, 0, 0.25)`
    },
    userCardInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',
    },
    cardTitle: {
        fontWeight: 'bold',
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        margin: theme.spacing(1, 3, 'auto', 1),
        fontWeight: 'bold',
    },
    avatarFemale: {
        backgroundColor: '#FFB2BB',
        color: '#FF6073',
    },
    avatarMale: {
        backgroundColor: '#E6F5FF',
        color: '#18A0FB',
    },
    avatarInactive: {
        backgroundColor: '#EFEFEF',
        color: '#727272',
    },
    sectionRoot: {
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: 0,
        padding: 0,
        paddingBottom: theme.spacing(5),
        borderBottom: '0.2px solid rgba(113, 113, 113, 0.49)'
    },

    searchButton: {
        margin: theme.spacing('auto', 2, 'auto', 0),
    },
    searchInput: {
        width: theme.spacing(45),
        margin: theme.spacing('auto', 1),
        '& .MuiOutlinedInput-input': {
            padding: theme.spacing(1.4, 2),
        },
    },
    selectRoot: {
        margin: theme.spacing('auto', 1, 'auto', 'auto'),
        width: theme.spacing(25),
    },
    selectInput: {
        padding: theme.spacing(1.4, 2),
    },
}));

const UserCard = ({ user }: { user: User }) => {
    const classes = useStyles();
    return <Card className={classes.userCard}>
        <Avatar
            className={clsx(
                classes.avatar,
                user.status === StatusType.Inactive ? classes.avatarInactive
                    : user.gender === GenderType.Female ? classes.avatarFemale
                        : classes.avatarMale
            )}
        >
            {user.firstName[0] + user.lastName[0]}
        </Avatar>
        <div className={classes.userCardInfo}>
            <span className={classes.cardTitle}>
                {user.firstName} {user.lastName}
            </span>
            <Link to={`/profile/${user.id}`}>View profile</Link>
            <span>ID-{user.id}</span>
        </div>
    </Card>
}

const AdminSearchComponent = ({ initialSearchValue, $departments, $selectedDepartment, searchValueChange, $showOlyInactive }) => {
    const classes = useStyles();
    const $search = useHook(initialSearchValue);

    return <>
        <form
            onSubmit={e => {
                e.preventDefault();
                // history.push(`/?q=${$search.value}`);
            }}
        >
            <Input
                placeholder="Search"
                value={$search.value}
                onChange={e => {
                    const v = e.target.value;
                    $search.set(v);
                    if (searchValueChange) {
                        searchValueChange(v);
                        // history.replace({ search: `q=${v}` });
                    }
                }}
                classes={{ root: classes.searchInput }}
            />
        </form>
        <BrandSelect
            className={classes.selectRoot}
            selectProps={{
                classes: { select: classes.selectInput }
            }}
            $value={$selectedDepartment}
            onChange={e => $selectedDepartment.set(+e.target.value)}
        >
            <BrandMenuItem value={0}>All departments</BrandMenuItem>
            {$departments.value.map(x =>
                <BrandMenuItem value={x.id} key={x.id}>{x.name}</BrandMenuItem>
            )}
        </BrandSelect>
        <FormControlLabel
            control={
                <Switch
                    checked={$showOlyInactive.value}
                    onChange={e => $showOlyInactive.set(e.target.checked)}
                    color="primary"
                />
            }
            label="Show only inactive"
        />
    </>
}

export const AdminDashboard = ({ $navigationComponent }) => {
    const classes = useStyles();
    const { $filters } = useStoreContext();
    const $groupedUsers = useHook<{ [k: number]: User[] }>([]);
    const $users = useHook<User[]>([]);
    const $departments = useHook<Department[]>([]);
    const $selectedDepartment = useHook($filters.value.data.selectedDepartment || 0);
    const $userSelector = useHook($filters.value.data.userSelector || '');
    const $showOlyInactive = useHook($filters.value.data.showOlyInactive || false);

    const filterUsers = () => {
        const userSelector = $userSelector.value.toLowerCase();
        const groupedUsers = $users.value
            .filter(x =>
                (!$selectedDepartment.value || x.departmentId === $selectedDepartment.value)
                && (!$showOlyInactive.value || x.status === StatusType.Inactive)
                && `${x.firstName} ${x.lastName} ID-${x.id}`
                    .toLowerCase()
                    .includes(userSelector)
            )
            .reduce((groups, item) => {
                const group = (groups[item.departmentId] || []);
                group.push(item);
                groups[item.departmentId] = group;
                return groups;
            }, {});
        $groupedUsers.set(groupedUsers);
    }

    useEffect(() => {
        UserService.getAll()
            .then(data => $users.set(data));
        DepartmentService.getAll()
            .then(data => $departments.set(data));
        return () => {
            $navigationComponent.set(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        filterUsers();
        $filters.set({
            pageId: 'AdminDashboard',
            data: {
                userSelector: $userSelector.value,
                selectedDepartment: $selectedDepartment.value,
                showOlyInactive: $showOlyInactive.value,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$users.value, $userSelector.value, $selectedDepartment.value, $showOlyInactive.value]);
    useEffect(() => {
        $navigationComponent.set(
            <AdminSearchComponent
                initialSearchValue={$userSelector.value}
                $departments={$departments}
                $selectedDepartment={$selectedDepartment}
                searchValueChange={$userSelector.set}
                $showOlyInactive={$showOlyInactive}
            />
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$departments.value, $selectedDepartment.value, $showOlyInactive.value]);

    return <SectionContainer className={classes.sectionRoot}>
        {Object.keys($groupedUsers.value).map(departmentId =>
            <Section
                key={departmentId}
                sectionName={$departments.value.find(d => d.id === +departmentId)?.name}
                container={({ className, ...props }) => <div className={clsx(classes.section, className)} {...props} />}
            >
                {$groupedUsers.value[departmentId].map(x =>
                    <UserCard key={x.id} user={x} />
                )}
                <br></br>
            </Section>
        )}
    </SectionContainer >
}
