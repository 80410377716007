import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    roContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },
    roLabel: {
    },
    roValue: {
        fontWeight: 'bold'
    }
}));

export const ReadOnlyField = ({ $value, ...props } : { $value?: any, value?:any, label?: any }) => {
    const classes = useStyles();
    return <div className={classes.roContainer} {...props}>
        {props.label ?
            <span className={classes.roLabel}>{props.label}</span>
        : null}
        <span className={classes.roValue}>{$value ? $value.value : props.value}</span>
    </div>
}
