import { createTheme } from '@material-ui/core/styles'

// https://material-ui.com/customization/color/
export const theme = createTheme({
    palette: {
        primary: {
            dark: '#1769aa',
            main: '#2196f3',
            light: '#4dabf5',
            contrastText: '#ffffff'
        },
        secondary: {
            dark: '#aa2e25',
            main: '#f44336',
            light: '#f6685e',
            contrastText: '#ffffff'
        },
    },
});
