import { config } from "../config";
import { emailValidator, passwordValidator, requiredValidator } from "../CoreComponents/Utils";
import { deleteData, getData, patchData, postData, putData } from "./utils";

export enum UserType {
    Admin = 1,
    Approver = 2,
    User = 3,
}

export enum membershipType {
    Member = 1,
    Admin = 2,
    ViewOnlyAdmin = 3
}

export enum GenderType {
    Male = 1,
    Female = 2,
}

export enum StatusType {
    Active = 1,
    Inactive = 2,
}

export interface User {
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
    departmentId: number;
    email: string;
    password: string;
    repeatPassword: string;
    telephoneNumber: string;
    jobPosition: string;
    homeOffice: string;
    status: StatusType;
    gender: GenderType;
    userType: UserType;
    canSelfApprove: boolean;
    approvers: number[];

    daysFromContract?: number;
    yearlyAllowance: number;
    daysUsed: number;
    daysRemaining: number;
    totalDays: number;
    nationalHolidays: number;
    daysFromLastYear: number;
    department?: string;
}

export const UserDataValidators = {
    firstName: [requiredValidator],
    middleName: [requiredValidator],
    lastName: [requiredValidator],
    departmentId: [requiredValidator],
    email: [requiredValidator, emailValidator],
    password: [passwordValidator],
    repeatPassword: [passwordValidator],
    status: [requiredValidator],
    gender: [requiredValidator],
    userType: [requiredValidator],
    yearlyAllowance: [requiredValidator],
    nationalHolidays: [requiredValidator],
    daysFromLastYear: [requiredValidator],
};

export namespace UserService {

    export function getMe(): Promise<User> {
        return getData(config.API_BASE_URL + '/user/me');
    }

    export function getAll(): Promise<User[]> {
        return getData(config.API_BASE_URL + '/user');
    }

    export function getById(id: number): Promise<User> {
        return getData(`${config.API_BASE_URL}/user/${id}`);
    }

    export function addUser(user: User): Promise<any> {
        return postData(config.API_BASE_URL + '/user', user);
    }

    export function editUser(user: User): Promise<any> {
        return putData(`${config.API_BASE_URL}/user/${user.id}`, user);
    }

    export function deleteUser(id: number): Promise<any> {
        return deleteData(`${config.API_BASE_URL}/user/${id}`);
    }

    export function getAllSubordinates(id: number): Promise<User[]> {
        return getData(`${config.API_BASE_URL}/user/${id}/subordinates`);
    }

    export function getAllFromDepartment(): Promise<User[]> {
        return getData(`${config.API_BASE_URL}/user/myDepartment`);
    }

    export function changePassword(id, password): Promise<any> {
        return patchData(`${config.API_BASE_URL}/user/${id}/password`, { password })
    }

}
