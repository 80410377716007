import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

export const BoldText = React.memo(
    withStyles((theme) => ({
        root: {
            fontWeight: 'bold',
            // color: '#000000',
        },
    }))(({ classes, ...props } : any) => <span className={classes.root} {...props} />)
);
