import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as UserIcon } from '../icons/user.svg';
import { ReactComponent as DeleteIcon } from '../icons/delete.svg';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export const BrandUserIcon = props =>
    <SvgIcon component={UserIcon} viewBox="0 0 512 512" {...props} />

export const BrandDeleteIcon = props =>
    <SvgIcon component={DeleteIcon} viewBox="0 0 512 512" {...props} />

const useImageIconStyles = makeStyles((theme) => ({
    root: {
        width: '2em',
        height: '2em',
        display: 'inline-block',
        flexShrink: 0,
        objectFit: 'scale-down',
    },
    resetDaysIcon: {
        width: '1.3em',
        height: '1.3em',
        display: 'inline-block',
        flexShrink: 0,
        objectFit: 'scale-down',
    },
    scheduleSmallIcon: {
        width: '1em',
        height: '1em',
        display: 'inline-block',
        flexShrink: 0,
        objectFit: 'scale-down',
    },
    logoutIcon: {
        width: '2.3em',
        height: '2.3em',
        '&:hover': {
            cursor: 'pointer',
            filter: 'brightness(0) saturate(100%) invert(19%) sepia(100%) saturate(1882%) hue-rotate(349deg) brightness(93%) contrast(84%)'
        },
    },
    navigationIcon: {
        '&:hover': {
            cursor: 'pointer',
            filter: 'brightness(0) saturate(100%) invert(58%) sepia(62%) saturate(5801%) hue-rotate(185deg) brightness(109%) contrast(97%)'
        },
    },
    active: {
        filter: 'brightness(0) saturate(100%) invert(58%) sepia(62%) saturate(5801%) hue-rotate(185deg) brightness(109%) contrast(97%)'
    }
}));

export const BrandBellIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/bell.png"
        className={classes.root}
        {...props}
    />
}

export const DocumentsIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/documents.png"
        className={classes.scheduleSmallIcon}
        {...props}
    />
}

export const CopyMemberScheduleIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/copy.png"
        className={classes.scheduleSmallIcon}
        {...props}
    />
}

export const EditScheduleIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/editSchedule.png"
        className={classes.scheduleSmallIcon}
        {...props}
    />
}

export const CreateScheduleIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/createSchedule.png"
        className={classes.resetDaysIcon}
        {...props}
    />
}

export const NavigationIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src={`./icons/${props.iconname}.png`}
        className={clsx(classes.navigationIcon, classes.root, props.active ? classes.active : null)}
        {...props}
    />
}

export const LogoutIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/logout.png"
        className={clsx(classes.logoutIcon, classes.root)}
        {...props}
    />
}

export const BrandTagIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/tag.png"
        className={classes.root}
        {...props}
    />
}

export const LeaveWallIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/leave_wall.png"
        className={classes.root}
        {...props}
    />
}

export const TeamWallIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/team_wall.png"
        className={classes.root}
        {...props}
    />
}

export const MyWallIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/my_wall.png"
        className={classes.root}
        {...props}
    />
}

export const ResetDaysIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/reset_days.png"
        className={classes.resetDaysIcon}
        {...props}
    />
}

export const ReportsIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/reports.png"
        className={classes.root}
        {...props}
    />
}

export const ExportIcon = props => {
    const classes = useImageIconStyles();
    return <img
        alt=""
        src="./icons/export.png"
        className={classes.root}
        {...props}
    />
}