import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { ForceValidateContext, getDatesInRange, Hook, useHook, useObjectHook, useStoreContext } from '../CoreComponents/Utils';
import { DepartmentService } from '../Services/department.service';
import { GenderType, StatusType, User, UserService, UserType } from '../Services/user.service';
import { Input } from '../CoreComponents/Input';
import { BrandButton } from '../CoreComponents/Button';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/Select';
import { BrandCard } from '../CoreComponents/Card';
import { withStyles } from '@material-ui/core';
import { Category, CategoryService, LeaveCategory } from '../Services/category.service';
import { HistoryEntry, LeaveRequest, LeaveRequestValidators, SummaryEntry, VacationService, LeaveStatus } from '../Services/vacation.service';
import { LoadingIndicator } from '../CoreComponents/LoadingIndicator';
import { Alert, SlimAlert } from '../CoreComponents/Alert';
import HelpIcon from '@material-ui/icons/Help';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import { BrandModal } from '../CoreComponents/Modal';
import { BrandVacationsCalendar, CalendarInput } from '../CoreComponents/Calendar';
import Checkbox from '@material-ui/core/Checkbox';
import {
    addDays,
    addMonths,
    differenceInDays,
    endOfMonth,
    endOfWeek,
    format,
    isBefore,
    isSameDay,
    isWithinInterval,
    startOfDay,
    startOfMonth,
    startOfToday,
    startOfWeek,
    subDays,
    subMonths
} from 'date-fns';
import { AdminService, NationalHoliday } from '../Services/admin.service';
import { BoldText } from '../CoreComponents/Typography';
import Avatar from '@material-ui/core/Avatar';
import { useParams } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ApproverSearch } from './ApproverLeaveWall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { PageDataLoading } from '../CoreComponents/PageDataLoading';

const useUserSideInfoStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(0.5, 3),
        width: theme.spacing(45),
        top: theme.spacing(14),
    },
    title: {
        color: '#2196f3',
        fontWeight: 'bold',
    },
    spaceBetweenRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    historyOfLeaves: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        minHeight: theme.spacing(15),
        maxHeight: theme.spacing(45),
        paddingRight: theme.spacing(1),
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left'
    },
    row: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    daysUsedLabel: {
        color: '#18a0fb',
        fontWeight: 'bold',
    },
    daysRemainingLabel: {
        color: '#ff6073',
        fontWeight: 'bold',
    },
    nationalHolidaysLabel: {
        color: '#00b828',
        fontWeight: 'bold',
    },
    pendingEntryHeader: {
        display: 'flex',
    },
}));

const Line = withStyles(theme => ({
    root: {
        borderBottom: '0.5px solid rgba(113, 113, 113, 0.49)',
        margin: theme.spacing(1, 0),
    },
}))(({ classes }: any) => <div className={classes.root} />);
const LineSpacing = withStyles(theme => ({
    root: {
        height: theme.spacing(2),
    },
}))(({ classes }: any) => <div className={classes.root} />);

const UserSideInfo = (
    { $userData, $history }
        : { $userData: Hook<User>, $history: Hook<HistoryEntry | undefined> }
) => {
    const classes = useUserSideInfoStyles();
    const [year] = useState(new Date().getFullYear());
    const $department = useHook('');
    const $categories = useHook<{ [key: number]: string }>({});
    const $summary = useHook<SummaryEntry[]>([]);
    const $pagination = useHook({ take: 5, skip: 0 });

    useEffect(() => {
        DepartmentService.getAll()
            .then(data => {
                const departmentName = data
                    .find(x => x.id === $userData.value.departmentId)
                    ?.name;
                $department.set(departmentName || '');
            });
        CategoryService.getGrouped()
            .then(data => $categories.set(data));
        VacationService.getSummary($userData.value.id, year)
            .then(data => $summary.set(data.filter(x => x.days > 0)));
        VacationService.getHistory($userData.value.id, $pagination.value.take, $pagination.value.skip)
            .then(data => $history.set(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        VacationService.getSummary($userData.value.id, year)
            .then(data => $summary.set(data.filter(x => x.days > 0)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$userData.value]);

    return <BrandCard className={classes.root}>
        <h1 className={classes.title}>Days for {year}</h1>
        <div className={classes.spaceBetweenRow}>
            <BoldText>{$userData.value.firstName} {$userData.value.lastName} ID-{$userData.value.id}</BoldText>
            <span>{$department.value}</span>
        </div>
        <LineSpacing />
        <div className={classes.spaceBetweenRow}>
            <Typography variant="h5" component="span">Total days:</Typography>
            <Typography variant="h5" component="span">{$userData.value.totalDays}</Typography>
        </div>
        <LineSpacing />
        <div className={classes.spaceBetweenRow}>
            <span>Yearly/Contractual allowance</span>
            <span>{$userData.value.yearlyAllowance}</span>
        </div>
        <div className={classes.spaceBetweenRow}>
            <span>Days from last year</span>
            <span>{$userData.value.daysFromLastYear}</span>
        </div>
        <Line />
        {$summary.value.map(x =>
            <div key={x.category} className={classes.spaceBetweenRow}>
                <span>{$categories.value[x.category] || 'UNKNOWN CATEGORY'}</span>
                <span>{x.days}</span>
            </div>
        )}
        <LineSpacing />
        <div className={classes.row}>
            <div className={classes.column}>
                <BoldText className={classes.daysUsedLabel}>Days Used</BoldText>
                <BoldText>{$userData.value.daysUsed}</BoldText>
            </div>
            <div className={classes.column}>
                <BoldText className={classes.daysRemainingLabel}>Days Remaining</BoldText>
                <BoldText>{$userData.value.daysRemaining}</BoldText>
            </div>
            <div className={classes.column}>
                <BoldText className={classes.nationalHolidaysLabel}>National Holidays</BoldText>
                <BoldText>{$userData.value.nationalHolidays}</BoldText>
            </div>
        </div>
        <LineSpacing />
        <div className={classes.spaceBetweenRow}>
            <Typography variant="h5" component="span">History of leaves</Typography>
            {/* <Typography variant="h5" component="span">{27}</Typography> */}
        </div>
        <Line />
        <div className={classes.historyOfLeaves}>
            {$history.value ?
                <>
                    {$history.value.pending.map(x =>
                        <div key={x.id}>
                            <div className={classes.spaceBetweenRow}>
                                <div className={classes.pendingEntryHeader}>
                                    <SlimAlert severity="warning" Component={HelpIcon} />
                                    <BoldText>Request for:</BoldText>
                                </div>
                                <span>{$categories.value[x.category]}</span>
                            </div>
                            <div className={classes.spaceBetweenRow}>
                                <BoldText>From</BoldText>
                                <span>{x.startDate.toDateString()}</span>
                            </div>
                            <div className={classes.spaceBetweenRow}>
                                <BoldText>To</BoldText>
                                <span>{x.endDate.toDateString()}</span>
                            </div>
                            <LineSpacing />
                            <div className={classes.spaceBetweenRow}>
                                <BoldText>ID {x.id}</BoldText>
                                <SlimAlert severity="warning" Component="span">Waiting approval</SlimAlert>
                            </div>
                            <Line />
                        </div>
                    )}
                    {$history.value.approved.map(x =>
                        <div key={x.id}>
                            <div className={classes.spaceBetweenRow}>
                                <BoldText>Request for:</BoldText>
                                <span>{$categories.value[x.category]}</span>
                            </div>
                            <div className={classes.spaceBetweenRow}>
                                <BoldText>From</BoldText>
                                <span>{x.startDate.toDateString()}</span>
                            </div>
                            <div className={classes.spaceBetweenRow}>
                                <BoldText>To</BoldText>
                                <span>{x.endDate.toDateString()}</span>
                            </div>
                            <LineSpacing />
                            <div className={classes.row}>
                                <div className={classes.column}>
                                    <BoldText>Approved By</BoldText>
                                    <span>{x.firstName} {x.lastName}</span>
                                </div>
                                <div className={classes.column}>
                                    <BoldText>Job position</BoldText>
                                    <span>{x.jobPosition}</span>
                                </div>
                                <div className={classes.column}>
                                    <BoldText>ID {x.id}</BoldText>
                                </div>
                            </div>
                            <Line />
                        </div>
                    )}
                </>
                : <LoadingIndicator />}
        </div>
        <div className={classes.spaceBetweenRow}></div>
    </BrandCard>
}

const useVacationRequestStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: theme.spacing(2),
    },
    paper: {
        width: '90vw',
        maxWidth: theme.spacing(80),

        '& .MuiDialogContent-root': {
            flexDirection: 'column',
        },
    },
    inputField: {
        width: theme.spacing(35),
        marginBottom: theme.spacing(2),
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: theme.spacing(0, 0, 2, 0),
        width: '100%',
        justifyContent: 'space-around',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        '& > *': {
            marginTop: 'auto',
        },
    },
    includeWeekends: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
    },
    checkbox: {
        '& .MuiSvgIcon-root': {
            width: '30px',
            height: '30px'
        }
    },
    endButtons: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        margin: theme.spacing(5, 0, 2, 0)
    },
    card: {
        color: '#727272',
        fontSize: theme.spacing(2),
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        width: '50%',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    deck: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.spacing(2)
    },
    centerButtons: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: theme.spacing(2, 0, 2, 0),
        '& .MuiButton-root': {
            width: '40%'
        }
    }
}));
export const VacationRequest = ({ $isOpen, $userData, forUserId, nationalHolidays, onAddedRequests, ...props }) => {
    const classes = useVacationRequestStyles();
    const { $currentUser } = useStoreContext();
    const $categories = useHook<Category[]>([]);
    const $isForceValidating = useHook(false);
    const $isLoading = useHook(false);
    const $selectedUser = useHook<User | null>(null);
    const $minDate = useHook<Date>($currentUser.value.userType === UserType.Approver ? subDays(startOfToday(), 30) : startOfToday());
    const $startDateRequestedLeaves = useHook<LeaveRequest[]>([]);
    const $endDateRequestedLeaves = useHook<LeaveRequest[]>([]);
    const $error = useHook('');
    const $responseError = useHook('');
    const $dateRangeIncludesNationalHoliday = useHook(true);
    const $displayNationalHolidayForm = useHook(false);

    useEffect(() => {
        CategoryService.getAll()
            .then(data => $categories.set(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const $vacationRequest = useObjectHook<LeaveRequest>({
        bookedByUserId: $userData.value.id,
        userId: $selectedUser.value ? $selectedUser.value.id : forUserId,
        category: '' as any,
        startDate: new Date(),
        endDate: new Date(),
        reason: '',
        includeWeekends: false,
        checkForNationalHoliday: true
    },
        LeaveRequestValidators,
        (val) => {
            const result = {} as any;
            let overlapsNationalHoliday = false;
            let requestRange = 0;
            if (val.startDate === val.endDate) {
                overlapsNationalHoliday = nationalHolidays.some(x => isSameDay(x.calendarDate, val.startDate));
                requestRange = 1;
            } else {
                const range = {
                    start: val.startDate < val.endDate ? val.startDate : val.endDate,
                    end: val.startDate < val.endDate ? val.endDate : val.startDate,
                };
                overlapsNationalHoliday = nationalHolidays.some(x => isWithinInterval(x.calendarDate, range));
                requestRange = differenceInDays(range.end, range.start);
            }

            switch (val.category) {
                case LeaveCategory.PaidLeave:
                    if ($selectedUser.value && $selectedUser.value.daysRemaining < requestRange) {
                        const err = 'Not enough days!';
                        result.startDate = result.endDate = [err];
                        $error.set(err);
                    }
                    break;
                case LeaveCategory.NationalHoliday:
                    if ($selectedUser.value && $selectedUser.value.nationalHolidays < requestRange) {
                        const err = 'Not enough days!';
                        result.startDate = result.endDate = [err];
                        $error.set(err);
                    }
                    break;
                case LeaveCategory.HalfDay:
                    if (overlapsNationalHoliday) {
                        const err = 'Cannot take half day on holiday!';
                        result.startDate = result.endDate = [err];
                        $error.set(err);
                    } else if ($selectedUser.value && $selectedUser.value.daysRemaining < requestRange) {
                        const err = 'Not enough days!';
                        result.startDate = result.endDate = [err];
                        $error.set(err);
                    }
                    break;
                case LeaveCategory.AddedDays:
                    if (overlapsNationalHoliday) {
                        const err = 'Cannot add day(s) on holiday!';
                        result.startDate = result.endDate = [err];
                        $error.set(err);
                    }
                    break;
                case LeaveCategory.OtherNonDeductible:
                    if (overlapsNationalHoliday) {
                        const err = 'Cannot add non-deductible day(s) on holiday!';
                        result.startDate = result.endDate = [err];
                        $error.set(err);
                    }
                    break;
            }

            if (!Object.keys(result).length) {
                $error.set('');
                return undefined;
            }

            $isForceValidating.set(true);
            return result;
        });

    const syncRequestLeaves = (uId, $handler, date) => {
        VacationService.getRequestedLeaves(
            uId,
            subDays(startOfWeek(startOfMonth(date), { weekStartsOn: 1 }), 1),
            addDays(endOfWeek(endOfMonth(date), { weekStartsOn: 1 }), 1)
        )
            .then(data => $handler.set(data));
    }

    useEffect(() => {
        const uId = $selectedUser.value ? $selectedUser.value.id : forUserId;
        $vacationRequest.set({
            ...$vacationRequest.value,
            bookedByUserId: $userData.value.id,
            userId: uId,
        });

        if (uId) {
            syncRequestLeaves(uId, $startDateRequestedLeaves, $vacationRequest.value.startDate);
            syncRequestLeaves(uId, $endDateRequestedLeaves, $vacationRequest.value.endDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$userData.value, forUserId, $selectedUser.value]);

    // eslint-disable-next-line eqeqeq
    const isHalfDay = $vacationRequest.value.category == LeaveCategory.HalfDay;

    useEffect(() => {
        if (isHalfDay || isBefore($vacationRequest.value.endDate, $vacationRequest.value.startDate)) {
            $vacationRequest.getPropHook('endDate').set($vacationRequest.value.startDate);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$vacationRequest.value.startDate, isHalfDay]);

    const onSend = () => {
        $isForceValidating.set(true);
        const errors = $vacationRequest.validator($vacationRequest.value);
        if (errors) {
            // WARN: this is a lazy fix
            delete errors.endDate;
            $error.set(Object.values(errors).join(' '));
            return;
        }

        $isLoading.set(true);
        VacationService.addVacationRequest($vacationRequest.value)
            .then(data => {
                $isLoading.set(false);
                $isOpen.set(false);
                onAddedRequests(
                    data.map(x => ({
                        ...$vacationRequest.value,
                        ...x,
                        startDate: startOfDay(new Date(x.startDate)),
                        endDate: startOfDay(new Date(x.endDate)),
                    })),
                );
            }, error => {
                $responseError.set(error.message);
                $isLoading.set(false);
            });
    }

    useEffect(() => {
        const startDate = $vacationRequest.value.startDate.getTime();
        const endDate = $vacationRequest.value.endDate.getTime();

        for (const nationalHoliday of nationalHolidays) {
            const nationalHolidaysDate = nationalHoliday.calendarDate.getTime();
            if (nationalHolidaysDate >= startDate && nationalHolidaysDate <= endDate && nationalHoliday.active === 1) {
                $dateRangeIncludesNationalHoliday.set(true);
                return;
            }
        }
        $dateRangeIncludesNationalHoliday.set(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$vacationRequest.value.startDate, $vacationRequest.value.endDate])

    return <BrandModal
        title="Booking time off"
        open={$isOpen.value}
        onClose={() => $isOpen.set(false)}
        onCloseButton={() => $isOpen.set(false)}
        classes={{ paper: classes.paper }}
    >
        {$displayNationalHolidayForm.value ?
            <div className={classes.root}>
                <div className={classes.deck}>
                    <div className={classes.card}>
                        <div>No, use both national holiday/s for the national holiday and paid leave for the other day/s</div>
                        <div className={classes.centerButtons}>
                            <BrandButton color="secondary"
                                onClick={() => {
                                    $displayNationalHolidayForm.set(false);
                                    const vacationRequest = $vacationRequest.value;
                                    vacationRequest.checkForNationalHoliday = true;
                                    $vacationRequest.set(vacationRequest);
                                    onSend();
                                }}
                            >
                                No
                            </BrandButton>
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div>Yes use a paid leave on the national holidays</div>
                        <div className={classes.centerButtons}>
                            <BrandButton
                                color="primary"
                                onClick={() => {
                                    $displayNationalHolidayForm.set(false);
                                    const vacationRequest = $vacationRequest.value;
                                    vacationRequest.checkForNationalHoliday = false;
                                    $vacationRequest.set(vacationRequest);
                                    onSend();
                                }}
                            >
                                Yes
                            </BrandButton>
                        </div>
                    </div>
                </div>
                <div className={classes.endButtons}>
                    <BrandButton onClick={() => $isOpen.set(false)}>Cancel</BrandButton>
                </div>
            </div>
            :
            <>
                {
                    $error.value || $responseError.value ?
                        <Alert severity="error">{$error.value} {$responseError.value}</Alert>
                        : null
                }
                {$isLoading.value ?
                    <LoadingIndicator />
                    :
                    <ForceValidateContext.Provider value={$isForceValidating.value}>
                        <div className={classes.root}>
                            <div className={classes.row}>
                                {$userData.value.userType === UserType.Approver && props.subordinates ?
                                    <Autocomplete
                                        classes={{ root: classes.inputField }}
                                        options={props.subordinates}
                                        getOptionLabel={(option) => `${option.firstName} ${option.middleName} ${option.lastName} ID-${option.id}`}
                                        value={$selectedUser.value}
                                        // disabled={$isReadOnly.value}
                                        onChange={(_, user) => $selectedUser.set(user)}
                                        renderInput={(params) => <Input {...params} required label="Who for" variant="outlined" />}
                                    />
                                    :
                                    <div className={clsx(classes.column, classes.inputField)}>
                                        <BoldText>Who for</BoldText>
                                        <span>{$userData.value.firstName} {$userData.value.lastName} ID-{$userData.value.id}</span>
                                    </div>
                                }
                                <BrandSelect
                                    required
                                    label="Category"
                                    className={classes.inputField}
                                    $value={$vacationRequest.getPropHook('category')}
                                >
                                    {$categories.value.map(x =>
                                        <BrandMenuItem value={x.id} key={x.id}>{x.name}</BrandMenuItem>
                                    )}
                                </BrandSelect>
                            </div>
                            <div className={classes.row}>
                                <CalendarInput
                                    label="Starting"
                                    required
                                    className={classes.inputField}
                                    nationalHolidays={nationalHolidays}
                                    requestedLeaves={$startDateRequestedLeaves.value}
                                    minDate={$minDate.value}
                                    $value={$vacationRequest.getPropHook('startDate')}
                                    onClose={() => {
                                        const uId = $selectedUser.value ? $selectedUser.value.id : forUserId;
                                        if (uId) {
                                            syncRequestLeaves(uId, $startDateRequestedLeaves, $vacationRequest.value.startDate);
                                        }
                                    }}
                                    onMonthChange={m => {
                                        const uId = $selectedUser.value ? $selectedUser.value.id : forUserId;
                                        if (uId) {
                                            syncRequestLeaves(uId, $startDateRequestedLeaves, m);
                                        }
                                    }}
                                />
                                {isHalfDay ?
                                    <div className={classes.inputField} />
                                    :
                                    <CalendarInput
                                        label="Ending"
                                        required
                                        className={classes.inputField}
                                        nationalHolidays={nationalHolidays}
                                        requestedLeaves={$endDateRequestedLeaves.value}
                                        minDate={$vacationRequest.getPropHook('startDate').value}
                                        $value={$vacationRequest.getPropHook('endDate')}
                                        onClose={() => {
                                            const uId = $selectedUser.value ? $selectedUser.value.id : forUserId;
                                            if (uId) {
                                                syncRequestLeaves(uId, $endDateRequestedLeaves, $vacationRequest.value.endDate);
                                            }
                                        }}
                                        onMonthChange={m => {
                                            const uId = $selectedUser.value ? $selectedUser.value.id : forUserId;
                                            if (uId) {
                                                syncRequestLeaves(uId, $endDateRequestedLeaves, m);
                                            }
                                        }}
                                    />
                                }
                            </div>
                            <div className={clsx(classes.row, classes.includeWeekends)}>
                                <div>Do you want to include the weekend in your leave request?</div>
                                <Checkbox
                                    className={classes.checkbox}
                                    color="primary"
                                    checked={$vacationRequest.value.includeWeekends}
                                    onChange={e => {
                                        const tempVacationRequest = $vacationRequest.value;
                                        tempVacationRequest.includeWeekends = e.target.checked;
                                        $vacationRequest.set({ ...tempVacationRequest })
                                    }}
                                />
                            </div>
                            <div className={classes.row}>
                                <Input
                                    label="Reason (optional)"
                                    className={classes.inputField}
                                    multiline
                                    rows={4}
                                    // rowsMax={4}
                                    placeholder="Reason for time off"
                                    $value={$vacationRequest.getPropHook('reason')}
                                />
                                <div className={clsx(classes.buttons, classes.inputField)}>
                                    <BrandButton onClick={() => $isOpen.set(false)}>Cancel</BrandButton>
                                    <BrandButton
                                        color="primary"
                                        onClick={() => {
                                            if ($dateRangeIncludesNationalHoliday.value && $vacationRequest.value.category === 1) {
                                                $displayNationalHolidayForm.set(true);
                                            } else {
                                                onSend();
                                            }
                                        }}
                                    >
                                        Send
                                    </BrandButton>
                                </div>
                            </div>
                        </div>
                    </ForceValidateContext.Provider>
                }
            </>
        }
    </BrandModal>
}

const useDeleteDialogStyles = makeStyles(theme => ({
    root: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    title: {
        textAlign: 'center',
    },
    inputField: {
        width: '100%',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: theme.spacing(2, 0, 2, 0),
        width: '100%',
        '& > *': {
            marginTop: 'auto',
            marginRight: theme.spacing(2),
        },
    },
    centerButtons: {
        justifyContent: 'center',
    },
}));

const DeleteDialog = ({ $isOpen, selectedLeave, onDelete }) => {
    const classes = useDeleteDialogStyles();
    const $declineReason = useHook('');
    const $error = useHook('');

    return <BrandModal
        open={$isOpen.value}
        title="Are you sure you want to delete this leave request?"
        classes={{ root: classes.title }}
    >
        <div className={classes.root}>
            <Input
                label="Reason for deletion (Optional)"
                className={classes.inputField}
                multiline
                rows={4}
                // rowsMax={4}
                $value={$declineReason}
            />
            {$error.value ?
                <Alert severity="error">{$error.value}</Alert>
                : null}
            <div className={classes.buttons}>
                <BrandButton onClick={() => $isOpen.set(false)}>Cancel</BrandButton>
                <BrandButton
                    color="secondary"
                    onClick={() => {
                        VacationService.deleteVacationRequest(selectedLeave.id as number, $declineReason.value)
                            .then(() => onDelete())
                            .catch(err => $error.set(err.message));
                    }}
                >
                    Delete
                </BrandButton>
            </div>
        </div>
    </BrandModal>
}

const useSelectedLeaveRequestStyles = makeStyles(theme => ({
    content: {
        display: 'grid',
        gridTemplateColumns: `${theme.spacing(10)}px repeat(4, 1fr)`,
        gridTemplateRows: '1fr 1fr auto',
        gridColumnGap: theme.spacing(1.25),
        gridRowGap: theme.spacing(5),
        margin: theme.spacing(1, 3, 3, 3),
    },
    firstColumn: {
        gridColumn: '2 / -4',
    },
    secondColumn: {
        gridColumn: '2 / -3',
    },

    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        fontWeight: 'bold',
    },
    avatarFemale: {
        backgroundColor: '#FFB2BB',
        color: '#FF6073',
    },
    avatarMale: {
        backgroundColor: '#E6F5FF',
        color: '#18A0FB',
    },
    avatarInactive: {
        backgroundColor: '#EFEFEF',
        color: '#727272',
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    paper: {
        width: '90vw',
        maxWidth: theme.spacing(130),
    },
    inputField: {
        width: theme.spacing(35),
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        margin: 'auto 0',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: theme.spacing(0, 0, 2, 0),
        width: '100%',
        '& > *': {
            marginTop: 'auto',
            marginRight: theme.spacing(2),
        },
    },

    approverOffset: {
        margin: 'auto 0 0 0',
    },
}));
export const SelectedLeaveRequest = (
    { user, $isOpen, selectedLeave, onDeletedRequest, hideReason }
        : { user: User, $isOpen: Hook<boolean>, selectedLeave: LeaveRequest, onDeletedRequest?: (request: LeaveRequest) => void, hideReason?: boolean }
) => {
    const classes = useSelectedLeaveRequestStyles();
    const { $currentUser } = useStoreContext();
    const $category = useHook<string>('');
    const $department = useHook<string>('');
    const $isDeleteOpen = useHook(false);
    const $showWeekendsIncluded = useHook<boolean>(false);

    useEffect(() => {
        if (selectedLeave && selectedLeave.startDate && selectedLeave.endDate) {
            let daysBetweenDates = getDatesInRange(selectedLeave.startDate, selectedLeave.endDate);
            // eslint-disable-next-line eqeqeq
            let showWeekendStatus = daysBetweenDates.filter(x => x.getDay() === 6 || x.getDay() === 0).length > 0 && selectedLeave.includeWeekends == true
            // console.log(selectedLeave.vacationId, selectedLeave.includeWeekends, selectedLeave.startDate, selectedLeave.endDate, showWeekendStatus);
            $showWeekendsIncluded.set(showWeekendStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        CategoryService.getAll()
            .then(data => {
                const category = data.find(x => x.id === selectedLeave.category);
                $category.set(category ? category.name : 'MISSING CATEGORY');
            });

        DepartmentService.getAll()
            .then(data => {
                const department = data.find(x => x.id === user.departmentId);
                $department.set(department ? department.name : 'UNKNOWN DEPARTMENT');
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLeave]);

    // eslint-disable-next-line eqeqeq
    const isHalfDay = selectedLeave.category == LeaveCategory.HalfDay;
    const canCurrentUserSee = !selectedLeave?.approvers
            || Boolean(selectedLeave?.approvers?.includes($currentUser.value.id))
        || user.id === $currentUser.value.id;

    return <BrandModal
        title={`ID ${selectedLeave.id}`}
        open={$isOpen.value}
        onClose={() => $isOpen.set(false)}
        onCloseButton={() => $isOpen.set(false)}
        classes={{ paper: classes.paper }}
    >
        <div className={classes.root}>
            <div className={classes.content}>
                <Avatar
                    className={clsx(
                        classes.avatar,
                        user.status === StatusType.Inactive ? classes.avatarInactive
                            : user.gender === GenderType.Female ? classes.avatarFemale
                                : classes.avatarMale
                    )}
                >
                    {user.firstName[0] + user.lastName[0]}
                </Avatar>
                <div className={classes.column}>
                    <BoldText>{user.firstName} {user.lastName}</BoldText>
                    <span>{user.jobPosition}</span>
                </div>
                <div className={classes.column}>
                    <BoldText>ID</BoldText>
                    <span>ID-{user.id}</span>
                </div>
                <div className={classes.column}>
                    <BoldText>Leave type</BoldText>
                    <span>{$category.value}</span>
                </div>
                <div className={classes.column}>
                    <BoldText>Department</BoldText>
                    <span>{user?.department || $department.value}</span>
                </div>
                <div className={clsx(classes.column, isHalfDay ? classes.secondColumn : classes.firstColumn)}>
                    <BoldText>From</BoldText>
                    <span>{selectedLeave.startDate.toDateString()}</span>
                </div>
                {isHalfDay ? null :
                    <div className={classes.column}>
                        <BoldText>To</BoldText>
                        <span>{selectedLeave.endDate.toDateString()}</span>
                    </div>
                }
                <div className={classes.column}>
                    <BoldText>Days used</BoldText>
                    <span>{selectedLeave.daysUsed}</span>
                </div>
                {$showWeekendsIncluded.value ?
                    <div className={classes.column}>
                        <BoldText>Weekends Included</BoldText>
                        <span>Yes</span>
                    </div>
                    :
                    null
                }
                {hideReason || !canCurrentUserSee ? null :
                    <Input
                        label="Reason"
                        disabled
                        className={clsx(classes.inputField, classes.secondColumn)}
                        multiline
                        rows={4}
                        // rowsMax={4}
                        value={selectedLeave.reason}
                    />
                }
                {canCurrentUserSee
                    && selectedLeave.approvedByUserId
                    && ($currentUser.value.userType === UserType.Approver || user.id === $currentUser.value.id) ?
                    <>
                        <div className={clsx(classes.column, classes.approverOffset)}>
                            <BoldText>Approved by</BoldText>
                            <span>{selectedLeave.approverFirstName} {selectedLeave.approverLastName}</span>
                        </div>
                        <div className={clsx(classes.column, classes.approverOffset)}>
                            <BoldText>Job position</BoldText>
                            <span>{selectedLeave.approverJobPosition}</span>
                        </div>
                    </>
                    : null}
            </div>
            {canCurrentUserSee
                && selectedLeave.status === LeaveStatus.Approved
                && ($currentUser.value.userType === UserType.Approver || user.id === $currentUser.value.id) ?
                <div className={classes.buttons}>
                    <BrandButton onClick={() => $isOpen.set(false)}>Close</BrandButton>
                    {onDeletedRequest ?
                        <>
                            <BrandButton color="secondary" onClick={() => $isDeleteOpen.set(true)}>
                                Delete
                            </BrandButton>
                            <DeleteDialog
                                $isOpen={$isDeleteOpen}
                                selectedLeave={selectedLeave}
                                onDelete={() => {
                                    $isOpen.set(false);
                                    onDeletedRequest(selectedLeave);
                                }}
                            />
                        </>
                        : null}
                </div>
                : null}
        </div>
    </BrandModal>
}

const useUserDashboardStyles = makeStyles(theme => ({
    root: {
        display: 'inline-flex',
        margin: 'auto',
    },
    calendarsRoot: {
        width: '70%',
        maxWidth: theme.spacing(3.2 * 40),
        margin: theme.spacing(0, 3, 0, 0),
        display: 'flex',
        flexWrap: 'wrap',
    },
    controls: {
        width: '100%',
        // position: 'relative',
        // marginBottom: theme.spacing(5),
        display: 'inline-flex',
    },
    calendarBarDate: {
        fontWeight: 'bold',
        fontSize: '1.5em',
    },
    calendarControls: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: 'auto',
        marginTop: 'auto',
        '& > *': {
            margin: theme.spacing('auto', 1.2),
        },
    },
    calendar: {
        height: theme.spacing(30),
        margin: theme.spacing(6, 'auto', 0, 'auto'),
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    calendarMonth: {
        marginBottom: theme.spacing(2),
    },
    sideInfo: {
        // marginLeft: 'auto',
    },
    requestLeaveButton: {
        // position: 'absolute',
        // right: 0,
        marginLeft: 'auto',
        marginBottom: 'auto',
    },
    requestLeaveButtonIcon: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        color: '#18a0fb',
    },
}));

function calculatePagination(date: Date) {
    return {
        startDate: startOfMonth(subMonths(date, 2)),
        endDate: endOfMonth(addMonths(date, 3))
    };
}

export const UserLeaveWall = ({ $userData, $navigationComponent }) => {
    const { $currentUser, $selectedUser } = useStoreContext();
    const classes = useUserDashboardStyles();
    const $showVacationRequestModal = useHook(false);
    const $history = useHook<HistoryEntry | undefined>(undefined);
    const $pagination = useHook(calculatePagination(new Date()));
    const $date = useHook(new Date());
    const $requestedLeaves = useHook<LeaveRequest[]>([]);
    const $nationalHolidays = useHook<NationalHoliday[]>([]);
    const $selectedRequestedLeave = useHook<LeaveRequest | undefined>(undefined);
    const $isSelectedRequestedLeave = useHook<boolean>(false);
    const $shouldUpdateUser = useHook(false);
    const $months = useHook<Date[]>([]);
    const $loading = useHook<boolean>(false);

    const isAsApprover = $selectedUser === $userData;

    useEffect(() => {
        $loading.set(true);
        Promise.all([
            VacationService.getHistory($userData.value.id, 50, 0)
                .then(data => $history.set(data)),
            AdminService.nationalHolidays()
                .then(data => $nationalHolidays.set(data))
        ]).then(() => $loading.set(false))

        if ($currentUser.value.userType === UserType.Approver) {
            $navigationComponent.set(
                <ApproverSearch
                    showBackButton={isAsApprover}
                    showSearchButton
                />
            );

            return () => {
                $navigationComponent.set(null);
            };
        } else {
            $navigationComponent.set(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const range = calculatePagination($date.value);
        $pagination.set(range);
        $loading.set(true);
        VacationService.getRequestedLeaves($userData.value.id, range.startDate, range.endDate)
            .then(data => {
                $requestedLeaves.set(data);
                $loading.set(false);
            });

        $months.set(
            [
                subMonths($date.value, 2),
                subMonths($date.value, 1),
                $date.value,
                addMonths($date.value, 1),
                addMonths($date.value, 2),
                addMonths($date.value, 3),
            ]
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$date.value])

    useEffect(() => {
        if (!$shouldUpdateUser.value) {
            return;
        }

        // WARN: could require loading indicator
        UserService.getById($userData.value.id)
            .then(data => {
                $userData.set(data);
                $shouldUpdateUser.set(false);
            })
            .catch(err => { });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$shouldUpdateUser.value])

    const onClickedCalendar = (date, requestedLeave, holiday) => {
        if (!requestedLeave) {
            return;
        }

        $selectedRequestedLeave.set(requestedLeave);
        $isSelectedRequestedLeave.set(true);
    }

    return <div className={classes.root}>
        <div className={classes.calendarsRoot}>
            <div>
                {isAsApprover ?
                    <Typography variant="h3" component="span">
                        {$userData.value.firstName} {$userData.value.lastName} ID-{$userData.value.id}
                    </Typography>
                    : null}
            </div>
            <div className={classes.controls}>
                <div className={classes.calendarControls}>
                    <IconButton onClick={() => $date.set(subMonths($date.value, 6))}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </IconButton>
                    <IconButton onClick={() => $date.set(addMonths($date.value, 6))}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </IconButton>
                    <span className={classes.calendarBarDate}>
                        {format($pagination.value.startDate, 'MMMM y')} - {format($pagination.value.endDate, 'MMMM y')}
                    </span>
                    <BrandButton
                        onClick={() => {
                            $date.set(new Date());
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        Today - {format(new Date(), 'd MMMM')}
                    </BrandButton>
                </div>

                <IconButton className={classes.requestLeaveButton} onClick={() => $showVacationRequestModal.set(true)}>
                    <AddCircleIcon className={classes.requestLeaveButtonIcon} />
                </IconButton>
                {$showVacationRequestModal.value ?
                    <VacationRequest
                        $userData={$userData}
                        $isOpen={$showVacationRequestModal}
                        forUserId={$userData.value.id}
                        nationalHolidays={$nationalHolidays.value}
                        onAddedRequests={newRequest => {
                            $requestedLeaves.set(
                                newRequest.concat($requestedLeaves.value)
                            );
                            $history.set({
                                pending: newRequest.concat(($history.value as any).pending),
                                approved: ($history.value as any).approved
                            });
                            $shouldUpdateUser.set(true);
                        }}
                    />
                    : null}
            </div>
            {$isSelectedRequestedLeave.value ?
                <SelectedLeaveRequest
                    user={$userData.value}
                    $isOpen={$isSelectedRequestedLeave}
                    selectedLeave={$selectedRequestedLeave.value as LeaveRequest}
                    onDeletedRequest={request => {
                        $requestedLeaves.set(
                            $requestedLeaves.value.filter(x => x.id !== request.id)
                        );
                        $history.set({
                            pending: ($history.value as HistoryEntry).pending,
                            approved: ($history.value as HistoryEntry).approved.filter(x => x.id !== request.id)
                        });
                        $shouldUpdateUser.set(true);
                    }}
                />
                : null}

            {$loading.value ?
                <PageDataLoading />
                :
                $date.value ? <>
                    {$months.value.map(m =>
                        <div key={m.getTime()} className={classes.calendar}>
                            <BoldText classes={{ root: classes.calendarMonth }}>{format(m, 'MMMM y')}</BoldText>
                            <BrandVacationsCalendar
                                onlyCurrentMonth
                                // showCurrentMonthTitle
                                requestedLeaves={$requestedLeaves.value}
                                nationalHolidays={$nationalHolidays.value}
                                date={m}
                                // classes={{ root: classes.calendar }}
                                onChange={onClickedCalendar}
                            />
                        </div>
                    )}
                </> : 'SOMETHING COULD BE BROKEN'
            }
        </div>
        <div className={classes.sideInfo}>
            <UserSideInfo $userData={$userData} $history={$history} />
        </div>
    </div>
}

export const UserLeaveWallAsApprover = ({ $navigationComponent }) => {
    const { userId } = useParams<any>();
    const { $selectedUser } = useStoreContext();

    useEffect(() => {
        if ($selectedUser.value
            && ($selectedUser.value.totalDays || $selectedUser.value.totalDays === 0)
        ) {
            // console.log('has selectedUser', $selectedUser.value);
            return;
        }

        UserService.getById(userId)
            .then(data => $selectedUser.set(data));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return $selectedUser.value ?
        <UserLeaveWall
            $userData={$selectedUser}
            $navigationComponent={$navigationComponent}
        />
        :
        <LoadingIndicator />
}
