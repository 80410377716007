import { config } from "../config";
import { deleteData, getData, postData, putData, SuccessResponse } from "./utils";

export interface TagEntry {
    id?: number;
    name: string;
    users: number[];
}

export namespace TagService {

    export function getAll(): Promise<TagEntry[]> {
        return getData(`${config.API_BASE_URL}/tag`);
    }

    export function addTag(tagEntry: TagEntry): Promise<TagEntry> {
        return postData(`${config.API_BASE_URL}/tag`, tagEntry);
    }

    export function editTag(tagEntry: TagEntry): Promise<SuccessResponse> {
        return putData(`${config.API_BASE_URL}/tag/${tagEntry.id}`, tagEntry);
    }

    export function deleteTag(tagId: number): Promise<SuccessResponse> {
        return deleteData(`${config.API_BASE_URL}/tag/${tagId}`);
    }

}
