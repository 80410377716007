import makeStyles from '@material-ui/core/styles/makeStyles';
import { LoadingIndicator } from './LoadingIndicator';


const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        width: '100%',
        height: '70vh',
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));


export const PageDataLoading = () => {
    const classes = useStyles(); 

    return ( 
        <div className={classes.loaderContainer}>
            <div className={classes.loader}>
                <LoadingIndicator />
            </div>
        </div>
     );
}
 