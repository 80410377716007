/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useHook, useQuery, useStoreContext } from "../CoreComponents/Utils";
import clsx from "clsx";
import { Avatar, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useMemo } from 'react';
import { Alert } from "../CoreComponents/Alert";
import { parseDate } from "./Reports";
import { DocumentsIcon, ExportIcon, CreateScheduleIcon, EditScheduleIcon, CopyMemberScheduleIcon } from "../CoreComponents/CustomIcons";
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { addDays, addMonths, addYears, endOfMonth, format, startOfMonth, subDays, subMonths, subYears, parse, isValid } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { BrandButton } from "../CoreComponents/Button";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { DayType, DayTypeDto, ScheduleDto, ScheduleHistory, ScheduleService } from '../Services/schedule.service';
import { membershipType, User, UserService, UserType } from '../Services/user.service';
import { BrandModal } from '../CoreComponents/Modal';
import { Input } from '../CoreComponents/Input';
import Typography from '@material-ui/core/Typography';
import TimeInput from '../CoreComponents/TimeInput';
import { ApproverSearch } from "./ApproverLeaveWall";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UserApproversAutocomplete } from "./User";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { VacationRequest, SelectedLeaveRequest } from "./UserLeaveWall";
import { AdminService, NationalHoliday } from "../Services/admin.service";
import { LeaveRequest, LeaveWallVacations, VacationService } from "../Services/vacation.service";
import Tooltip from '@material-ui/core/Tooltip';
import { debounce } from "../Services/utils";
import XLSX from 'xlsx-color';
import { LoadingIndicator } from "../CoreComponents/LoadingIndicator";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles((theme) => ({
    scheduleModal: {
        '& .MuiPaper-root': {
            paddingLeft: 25,
            paddingRight: 25,
            paddingTop: 25,
        }
    },
    cloneAndExportScheduleModal: {
        '& .MuiDialogContent-root': {
            padding: 10
        }
    },
    noteColumn: {
        WebkitClipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 15%, 70% 0)',
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 15%, 70% 0)',
    },
    yearLabel: {
        height: '48px',
    },
    selectMonthAndYear: {
        padding: theme.spacing(2),
    },
    changeYearRowWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
    },
    changeYearRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(3),
        fontWeight: 'bold',
        fontSize: theme.spacing(2),
    },
    selectedMonth: {
        backgroundColor: '#89D0FF',
        color: '#FFFFFF'
    },
    monthLabel: {
        padding: '6px',
        cursor: 'pointer',
        borderRadius: '10px',
        width: '82px',
        textAlign: 'center',
        height: '23px',
        '&:hover': {
            backgroundColor: '#E3E3E3'
        },
    },
    blueBox: {
        width: '100%',
        background: '#18A0FB',
        color: '#FFFFFF'
    },
    blueBoxWrapper: {
        padding: theme.spacing(1.5),
    },
    selectedMonths: {
        marginLeft: theme.spacing(1),
        height: theme.spacing(3),
    },
    changeYear: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    listPopover: {
        maxHeight: '200px'
    },
    greyButtonSelect: {
        '& .MuiButtonBase-root': {
            border: '1px solid #727272',
            color: '#727272',
            '&:hover': {
                backgroundColor: '#E3E3E3'
            },
        }
    },
    blueBorder: {
        '& .MuiButtonBase-root': {
            border: '1px solid #4dabf5',
        }
    },
    listRoot: {
        width: '100%',
        maxWidth: 360,
    },
    exportIcon: {
        color: '#ffa3ae',
        width: '40px',
        height: '40px',
    },
    iconButton: {
        padding: 0
    },
    requestLeaveButtonIcon: {
        color: '#18a0fb',
        width: '48px',
        height: '48px',
    },
    calendarBarDate: {
        fontWeight: 'bold',
        fontSize: '1.5em',
    },
    calendarControls: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: 'auto',
        marginTop: 'auto',
        '& > *': {
            margin: theme.spacing('auto', 1.2),
        },
    },
    tooltipActions: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    pageLayout: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        marginTop: '-15px'
    },
    legendContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '12px',
    },
    legendContainerGap: {
        gap: theme.spacing(1.5)
    },
    legend: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        cursor: 'pointer'
    },
    legendContainerButton: {
        '& .MuiButtonBase-root': {
            padding: '0px',
            minWidth: 'auto',
            paddingLeft: '4px',
            paddingRight: '4px',
            borderColor: 'transparent'
        }
    },
    legendFontSize: {
        fontSize: '12px',
        '@media (max-width: 1475px)': {
            fontSize: '11px !important',
        }
    },
    square: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        borderRadius: '3px',
        '@media (max-width: 1475px)': {
            width: '13px',
            height: '13px'
        }
    },
    label: {
        color: '#969696',
        fontSize: '12px'
    },
    container: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        textAlign: 'center',
        '& th': {
            fontWeight: 'bold',
            color: '#727272',
            background: 'white',
            margin: 0
        },
        '& td': {
            margin: 0
        }
    },
    stickyThcTh: {
        '& th': {
            position: 'sticky',
            top: '0'
        },
    },
    tableContainer: {
        overflow: 'auto',
        height: '100%',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    firstRowFirstColumn: {
        minWidth: '210px',
        height: '80px',
        borderRight: '1px solid transparent',
    },
    firstRowColumn: {
        height: '80px',
        overflowWrap: 'break-word',
        borderRight: '1px solid white',
    },
    shiftColumn: {
        minWidth: '80px',
    },
    officesColumn: {
        minWidth: '140px',
    },
    firstColumn: {
        minWidth: '210px',
        height: '80px',
        borderRight: '1px solid #E1E1E1',
    },
    column: {
        height: '80px',
        overflowWrap: 'break-word',
        borderRight: '1px solid #E1E1E1'
    },
    tableForTableHeader: {
        marginBottom: '-80px',
        position: 'relative',
        zIndex: 1
    },
    scheduleNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    scheduleName: {
        zIndex: 2,
        position: 'relative',
    },
    avatarCell: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        '& .MuiAvatar-root': {
            cursor: 'pointer'
        }
    },
    maleAvatar: {
        color: theme.palette.primary.main,
        background: '#E6F5FF',
        fontWeight: 'bold',
        padding: 12
    },
    femaleAvatar: {
        color: theme.palette.secondary.main,
        background: '#FFB2BB',
        fontWeight: 'bold',
        padding: 12
    },
    employeeIdLabel: {
        color: theme.palette.grey[500],
        fontSize: theme.spacing(2),
    },
    boldLabel: {
        fontWeight: 'bold',
        color: '#727272'
    },
    fullNameAndIdContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    },
    fullNameContainer: {
        display: 'flex',
        alignItems: 'start',
        flexWrap: 'wrap'
    },
    dayColumn: {
        width: '27px',
        borderLeft: '1px solid #E1E1E1',
        borderRight: '1px solid #E1E1E1',
        cursor: 'pointer'
    },
    copyMemberScheduleIcon: {
        cursor: 'pointer',
        marginTop: '3px'
    },
    dayColumnHeaderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    firstRowDayColumn: {
        width: '27px',
        borderLeft: '1px solid white',
        borderRight: '1px solid white',
    },
    dayColumnBackground: {
        height: '77px',
        width: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        '& .MuiButtonBase-root': {
            padding: '0px'
        }
    },
    scheduleNameRow: {
        borderBottom: '2px solid #E1E1E1 !important',
    },
    reportsTableWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        overflow: 'auto',
        padding: theme.spacing(2),
    },
    shift: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        fontWeight: 'bold'
    },
    disableRipple: {
        marginLeft: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    office: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        fontWeight: 'bold'
    },
    focusCell: {
        borderRadius: '5px',
        outline: '4px solid #FFB800'
    },
    topAndBottomBorder: {
        borderTop: '1px solid #E1E1E1',
        borderBottom: '1px solid #E1E1E1',
    },
    dayIsToday: {
        outline: '3px solid #18A0FB'
    },
    daySquare: {
        width: '24px',
        height: '24px',
    },
    daySquareContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    approveAndCancel: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1.5),
    },
    marginBottomButtons: {
        marginBottom: '5px',
        marginTop: '60px',
    },
    scheduleActionsMarginBottom: {
        marginBottom: '25px',
    },
    spaceBetween: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    amAndPm: {
        width: '56px',
        height: '56px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        border: '1px solid #E1E1E1',
        borderRadius: '5px',
        paddingLeft: '5px',
        paddingRight: '5px',
        fontWeight: 'bold'
    },
    workTime: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
    row: {
        width: '100%',
        display: 'flex',
        alignItems: 'baseline',
        gap: theme.spacing(4)
    },
    borderTop: {
        borderTop: '3px solid #E1E1E1',
    },
    firstRow: {
        marginRight: theme.spacing(7)
    },
    invalidTime: {
        color: '#F44336',
        height: '23px'
    },
    timeContainer: {
        marginTop: '23px',
        '& .MuiInputBase-root': {
            width: '145px'
        }
    },
    inputField: {
        minWidth: theme.spacing(32),
        marginRight: theme.spacing(1.5),
    },
    addForm: {
        display: 'flex',
        flexDirection: 'row',
        width: theme.spacing(30),
        marginBottom: theme.spacing(1),
    },
    addBtn: {
        height: '56px',
        width: theme.spacing(10),
    },
    radioGroup: {
        marginLeft: theme.spacing(5),
        '& .MuiButtonBase-root': {
            padding: '3px'
        }
    },
    membersList: {
        width: '100%',
        maxWidth: 390,
        height: 200,
        overflow: 'auto',
        marginBottom: theme.spacing(1.5)
    },
    deleteScheduleApproveAndCancel: {
        justifyContent: 'space-around',
        width: '100%',
        '& .MuiButtonBase-root': {
            width: theme.spacing(11),
        }
    },
    cloneAndExportScheduleApproveAndCancel: {
        justifyContent: 'end',
        width: '100%',
        marginBottom: theme.spacing(1.5),
        marginTop: '25px',
        gap: theme.spacing(2),
        '& .MuiButtonBase-root': {
            width: theme.spacing(11),
        }
    },
    deleteSchedule: {
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    col: {
        width: '100%'
    },
    dividerWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    divider: {
        width: '65%',
        borderBottom: '1px solid #E1E1E1'
    },
    justifyEnd: {
        justifyContent: 'end'
    },
    secondColumn: {
        gridColumn: '2 / -3',
    },
    noteInput: {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(3.5),
    },
    noteAvatar: {
        display: 'flex',
        alignItems: 'baseline',
        gap: theme.spacing(1.5)
    },
    noteTopRow: {
        display: 'flex',
        alignItems: 'baseline',
        gap: theme.spacing(3.5),
    },
    noteContainer: {
        width: '600px'
    },
    fontSize14: {
        fontSize: '14px'
    },
    fontSize12: {
        fontSize: '12px'
    },
    noteMargin: {
        marginTop: theme.spacing(2.5)
    },
    calendarTile: {
        display: 'flex',
        height: '36px',
        width: '24px',
        marginLeft: '1px',
        textAlign: 'center',
        boxSizing: 'border-box',
        border: 0,
        background: 0,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#e6e6e6',
        },
        '&.pending-national-holiday': {
            backgroundColor: 'rgba(244, 162, 97,1 ) !important',
        },
        '&.national-holiday': {
            backgroundColor: '#00b828 !important',
        },
        '&.pending-unpaid-leave': {
            backgroundColor: 'rgba(236, 188, 253, 1) !important',
        },
        '&.unpaid-leave': {
            backgroundColor: 'rgba(155, 93, 229, 1) !important',
        },
        '&.pending-paid-leave': {
            backgroundColor: 'rgba(255, 184, 0, 0.5) !important',
        },
        '&.paid-leave': {
            backgroundColor: 'rgba(0, 184, 40, 0.38) !important',
        },
        '&.sick-leave': {
            backgroundColor: 'rgba(242, 132, 130, 1) !important',
        },
        '&.pending-half-day-leave': {
            backgroundImage: 'linear-gradient(90deg, rgba(255, 184, 0, 0.5) 50%, rgba(255, 255, 255, 0) 50%) !important',
        },
        '&.half-day-leave': {
            backgroundImage: 'linear-gradient(90deg, rgba(0, 184, 40, 0.38) 50%, rgba(255, 255, 255, 0) 50%) !important',
        },
        '&.other-non-deductible': {
            backgroundColor: 'rgba(141, 153, 174, 1) !important',
        },
        '&.pending-added-day': {
            backgroundColor: 'rgba(221, 190, 169, 1) !important',
        },
        '&.added-day': {
            backgroundColor: 'rgba(176, 137, 104, 1) !important',
        },
    },
    calendarTileText: {
        margin: 'auto',
    },
    homeOfficeFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        cursor: 'pointer'
    },
    cursorAuto: {
        cursor: 'auto'
    },
    isThisMonth: {
        cursor: 'auto',
        '&:hover': {
            backgroundColor: 'unset'
        },
    },
    noteIconButton: {
        "&:disabled": {
            color: '#C4C4C4'
        },
        '& .MuiSvgIcon-root': {
            width: '35px',
            height: '35px',
        }
    },
    alignCopyMemberSchedulePopupActions: {
        width: '100%',
        justifyContent: 'end',
    },
    copyMemberSchedulePopup: {
        '& .MuiPaper-root': {
            maxWidth: '485px'
        }
    },
    fromAndToSchedulesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'baseline',
        marginTop: theme.spacing(4),
    },
    alignBottomLabel: {
        marginBottom: theme.spacing(1.5),
    },
    positionSchedulesForCopying: {
        marginTop: '-1px',
        marginLeft: '-15px',
        paddingTop: '0px',
        minWidth: '170px',
        height: '180px',
        overflowY: 'scroll',
        '& .MuiListItem-dense': {
            paddingTop: '0px',
            paddingBottom: '0px',
        }
    },
    userData: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    }
}));

function getDaysInMonth(month: number, year: number, checkForWeekends: boolean, elementValue?: string) {
    const date = new Date(year, month, 1);
    const days: any[] = [];

    while (date.getMonth() === month) {
        const newDateOfDate = new Date(date);
        const isWeekend = checkForWeekends && ((newDateOfDate.getDay() === 6) || (newDateOfDate.getDay() === 0)); // 6 = Saturday, 0 = Sunday
        // eslint-disable-next-line eqeqeq
        days.push(elementValue == undefined ?
            newDateOfDate.getDate()
            :
            isWeekend ?
                9
                :
                elementValue);
        date.setDate(date.getDate() + 1);
    }
    return days;
}

function isValidDate(day, month, year) {
    const parsed = parse(`${day}/${month}/${year}`, 'P', new Date(), { locale: enGB })
    return isValid(parsed)
}

const getDaysBetweenTwoDates = function (start: string, end: string, category: number, status: number, vacationId: number, asArray: boolean) {
    const dates = asArray ? [] : {};
    const dt = new Date(start);
    while (dt <= new Date(end)) {
        if (asArray) {
            //@ts-ignore
            dates.push(parseDate(dt, '-'));
        } else {
            dates[parseDate(dt, '-')] = { status: status, category: category, vacationId: vacationId };
        }
        dt.setDate(dt.getDate() + 1);
    }
    return dates;
};

function checkLabel(hours, minutes, $label) {
    if (hours >= 0 && hours <= 12) {
        if (hours === 12 && minutes === 0) {
            $label.set('AM');
        } else if (hours < 12) {
            $label.set('AM');
        } else {
            $label.set('PM');
        }
    } else if (hours >= 13 && hours <= 23) {
        if (hours === 23 && minutes <= 59) {
            $label.set('PM');
        } else if (hours <= 23) {
            $label.set('PM');
        } else {
            $label.set('AM');
        }
    }
}

const vacationTypes = {
    1: { //pending
        1: 'pending-paid-leave',
        2: 'pending-unpaid-leave',
        3: 'pending-national-holiday',
        4: 'sick-leave',
        5: 'pending-added-day',
        6: 'other-non-deductible',
        7: 'pending-half-day-leave'
    },
    2: { //active
        1: 'paid-leave',
        2: 'unpaid-leave',
        3: 'national-holiday',
        4: 'sick-leave',
        5: 'added-day',
        6: 'other-non-deductible',
        7: 'half-day-leave'
    },
    vacationLabels: {
        1: 'Paid Leave',
        2: 'Unpaid Leave',
        3: 'National Holiday',
        4: 'Sick Leave',
        5: 'Added Day',
        6: 'Other Non-Deductible',
        7: 'Half Day-Leave'
    },
    vacationAlias: {
        1: {//pending
            1: 'R',
            2: 'UC',
            3: 'N',
            4: 'O',
            5: 'AD',
            6: 'OD',
            7: 'H'
        },
        2: {//active
            1: 'C',
            2: 'UC',
            3: 'N',
            4: 'O',
            5: 'AD',
            6: 'OD',
            7: 'H'
        }
    },
    vacationColors: {
        'C': '9EE4AD',
        'UC': 'ECBCFD',
        '2N': '00B828',
        'O': 'F28482',
        'AD': 'B08968',
        'OD': '8D99AE',
        'H': 'FFDC7F',
        'R': 'FFDB7F',
        '1N': 'F4A261',
    }
}

const monthsDic = {
    'January': 1,
    'February': 2,
    'March': 3,
    'April': 4,
    'May': 5,
    'June': 6,
    'July': 7,
    'August': 8,
    'September': 9,
    'October': 10,
    'November': 11,
    'December': 12
}

const generateTitleCellStyling = (sz: number = 12) => {
    return {
        font: {
            sz: sz,
            bold: true,
            color: { rgb: '000000' }
        },
        alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: '1'
        },
        border: {
            right: {
                style: 'thin',
                color: '000000'
            },
            left: {
                style: 'thin',
                color: '000000'
            },
            top: {
                style: 'thin',
                color: '000000'
            },
            bottom: {
                style: 'thin',
                color: '000000'
            },
        }
    };
};

const centerTextInCell = () => {
    return {
        alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: '1'
        }
    };
};

const getVerbalColumnIndex = (asciiNumber: number, columnIndex: number, row: number) => {
    let headerIndex = asciiNumber + columnIndex; //A1, B1, C1, ..., N1, ..., AA1, ..., BA1, ... BZ1
    if (headerIndex > 90) {
        return String.fromCharCode(64 + Math.ceil((headerIndex - 90) / 26)) + String.fromCharCode(headerIndex - (Math.ceil((headerIndex - 90) / 26) * 26)) + row;
    } else {
        return String.fromCharCode(headerIndex) + row;
    }
}

export const Schedule = ({ $userData, $navigationComponent }) => {
    const classes = useStyles();
    const { $currentUser, $selectedUser, $filters } = useStoreContext();
    const $subordinateUsers = useHook<User[]>([]);
    const { q } = useQuery();
    const $filter = useHook($filters.value.pageId === 'ApproverLeaveWall' ? $filters.value.data.filter : (q || ''));
    const $loading = useHook<boolean>(false);
    const $debouncedFilter = useHook('');
    const $schedules = useHook<any[]>([]);
    const $error = useHook('');
    const $dateFilter = useHook(new Date());
    const $dayTypes = useHook<{ [key: string]: DayType }>({});
    const $dayTypesById = useHook<{ [key: number]: string }>({});
    const $focusedCells = useHook<any[]>([]);
    const $focusCellForNote = useHook<any>({});
    const $selectedSchedules = useHook<any[]>([]);
    const $schedulesFilter = useHook<any[]>([]);
    const $selectedScheduleAnchorEl = useHook(null);
    const $scheduleForEdit = useHook<any>(null);
    const $homeOfficesFilterDic = useHook({});
    const $showCloneAndExportScheduleModal = useHook(false);
    const $showScheduleModal = useHook(false);
    const $deleteSchedule = useHook(false);
    const $deleteNote = useHook(false);
    const $pressedKey = useHook<any[]>([]);
    const prevPressedKeyRef = useRef();
    const $showVacationRequestModal = useHook(false);
    const $nationalHolidays = useHook<NationalHoliday[]>([]);
    const $subordinateUsersLeaves = useHook<LeaveWallVacations | undefined>(undefined);
    const $showNoteModal = useHook(false);
    const $noteModalInfo = useHook<any>({});
    const $vacationRequests = useHook<any>({});
    const $refresh = useHook<any>({});
    const $isSelectedRequestedLeave = useHook(false);
    const $selectedLeaveRequestUser = useHook<User | null>(null);
    const $selectedRequestedLeave = useHook<LeaveRequest | undefined>(undefined);
    const $exporting = useHook(false);
    const $initiallyLoaded = useHook(false);
    const $isAdminOrViewOnlyAdmin = useHook(false);
    const $membershipTypeOfEachSchedule = useHook({});
    const $membershipTypeOfEachScheduleForExporting = useHook({});
    const $scheduleFilterFromDropdown = useHook<any[]>([]);
    const $scheduleFilterFromDropdownAsString = useHook<String>('');
    const $showCopyMemberSchedulePopup = useHook(false);
    const $copyMemberScheduleData = useHook({
        member: {
            gender: 1,
            firstName: '',
            lastName: '',
            jobPosition: '',
            id: 0,
            department: '',
        },
        fromSchedule: {
            id: 0,
            name: '',
        }
    });

    const showCopyMemberSchedulePopup = (e, memberData) => {
        $showCopyMemberSchedulePopup.set(true);
        $copyMemberScheduleData.set(memberData);
    };

    const debounceMemo = useMemo(
        () => debounce(
            () => { }, (value) => {
                $debouncedFilter.set(value);
            }, 500),
        [],
    );

    useEffect(() => {
        if (!$initiallyLoaded.value) {
            $initiallyLoaded.set(true);
            return;
        }
        debounceMemo($filter.value)
    }, [$filter.value])

    useEffect(() => {
        const startDate = subDays(startOfMonth($dateFilter.value), 1);
        const endDate = addDays(endOfMonth($dateFilter.value), 1);
        $loading.set(true);
        VacationService.getSubordinatesLeaves($userData.value.id, startDate, endDate)
            .then(data => {
                $subordinateUsersLeaves.set(data);
                $loading.set(false)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$dateFilter.value]);

    const syncUser = userId => {
        const index = $subordinateUsers.value.findIndex(x => x.id === userId);
        if (index >= 0) {

            // WARN: may need loading indicator
            UserService.getById(userId)
                .then(data => {
                    $subordinateUsers.set([
                        ...$subordinateUsers.value.slice(0, index),
                        data,
                        ...$subordinateUsers.value.slice(index + 1)
                    ]);
                });
        }
    }

    useEffect(() => {
        $loading.set(true);
        Promise.all([
            UserService.getAllSubordinates($userData.value.id)
                .then(data => $subordinateUsers.set(data)),
            AdminService.nationalHolidays()
                .then(data => $nationalHolidays.set(data)),
        ]).then(() => $loading.set(false))

        setTimeout(() => {
            $navigationComponent.set(
                <ApproverSearch initialSearchValue={$filter.value} searchValueChange={$filter.set} />
            )
        }, 50);

        return () => {
            $navigationComponent.set(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchDayTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$refresh.value])

    useEffect(() => {
        fetchMySchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$dateFilter.value, $debouncedFilter.value, $selectedSchedules.value, $refresh.value])

    useEffect(() => {
        $filters.set({
            pageId: 'Schedule',
            data: { filter: $filter.value }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$filter.value])

    const fetchDayTypes = () => {
        ScheduleService.getDayTypes()
            .then(async resDayTypes => {
                const dayTypesDic = {};
                const dayTypesById = {};
                for (const dayType of resDayTypes) {
                    dayTypesDic[dayType.hotkey] = dayType;
                    dayTypesById[dayType.id] = dayType.hotkey;
                }
                $dayTypesById.set(dayTypesById)
                $dayTypes.set(dayTypesDic)
            })
            .catch(err => {
                $error.set(err.message);
                setTimeout(() => {
                    $error.set('');
                }, 5000)
            });
    }

    const fetchMySchedules = (getSchedulesFilter = false) => {
        let scheduleFilter = '';
        if ($selectedSchedules.value && $selectedSchedules.value.length) {
            $selectedSchedules.value.forEach(s => scheduleFilter += `&schedule[]=${s.id}`)
        }

        ScheduleService.getMySchedules(
            $dateFilter.value.getFullYear(),
            $dateFilter.value.getMonth() + 1,
            $debouncedFilter.value,
            scheduleFilter
        )
            .then(async res => {
                if (res && res.length) {
                    let scheduleFilterFromDropdown = [];
                    let scheduleFilterFromDropdownAsString = '';
                    res.forEach(s => {
                        if (s.members && s.members.length) {
                            //@ts-ignore
                            scheduleFilterFromDropdown.push(s.id);
                            scheduleFilterFromDropdownAsString += `&schedule[]=${s.id}`;
                        }
                    })
                    $scheduleFilterFromDropdown.set(scheduleFilterFromDropdown);
                    $scheduleFilterFromDropdownAsString.set(scheduleFilterFromDropdownAsString);
                }
                $schedules.set(res)
                if (($schedulesFilter.value.length === 0 && res.length) || getSchedulesFilter === true) {
                    $schedulesFilter.set(res);
                }
                const homeOfficesFilter = {};
                const vacationRequests = {};

                for (const schedule of res) {
                    // @ts-ignore */
                    homeOfficesFilter[schedule.id] = {};
                    if (schedule.members) {
                        for (const member of schedule.members) {
                            if (member && member.homeOffice && member.homeOffice !== "Missing from DB") {
                                // @ts-ignore */
                                homeOfficesFilter[schedule.id][member.homeOffice] = true;
                            }

                            for (const vacation of member.vacations) {
                                const key = member.id;
                                const days = getDaysBetweenTwoDates(vacation.fromDate, vacation.toDate, vacation.category, vacation.status, vacation.id, false);
                                const vacationIsNationalHoliday = vacation.category === 3;
                                const vacationIsDeleteOrDeclineAndIsNationalHoliday = (vacation.status === 3 || vacation.status === 4) && vacationIsNationalHoliday;
                                if (vacationRequests[key]) {
                                    for (const dayKey of Object.keys(days)) {
                                        const dayKeyExists = Boolean(vacationRequests[key][dayKey]);
                                        if (!dayKeyExists
                                            || vacationIsNationalHoliday
                                            || ((days[dayKey].status === 1 || days[dayKey].status === 2)
                                                && vacationIsDeleteOrDeclineAndIsNationalHoliday)) {
                                            vacationRequests[key][dayKey] = days[dayKey];
                                        }
                                    }
                                } else {
                                    vacationRequests[key] = days;
                                }
                            }
                        }
                    }
                }

                $vacationRequests.set(vacationRequests);
                for (const key of Object.keys(homeOfficesFilter)) {
                    homeOfficesFilter[key] = Object.keys(homeOfficesFilter[key]);
                }
                $homeOfficesFilterDic.set(homeOfficesFilter);
            })
            .catch(err => {
                $error.set(err.message);
                setTimeout(() => {
                    $error.set('');
                }, 5000)
            });
    }

    useEffect(() => {
        setTimeout(() => {
            getMembershipTypes();
        }, 50);
    }, [])

    const getMembershipTypes = () => {
        const today = new Date();
        ScheduleService.getMySchedules(today.getFullYear(), today.getMonth() + 1, '', '')
            .then(async res => {
                const membershipTypeOfEachSchedule = {};
                const membershipTypeOfEachScheduleForExporting = {};

                for (const schedule of res) {
                    if (schedule.members) {
                        for (const member of schedule.members) {
                            if (member.id === $userData.value.id
                                && (member.membershipType === membershipType.ViewOnlyAdmin || member.membershipType === membershipType.Admin)) {
                                $isAdminOrViewOnlyAdmin.set(true);
                                //@ts-ignore
                                membershipTypeOfEachSchedule[schedule.id] = true;
                            }
                            if ($userData.value.userType === UserType.Approver) {
                                //@ts-ignore
                                membershipTypeOfEachScheduleForExporting[schedule.id] = true;
                            }
                        }
                    }
                }
                $membershipTypeOfEachScheduleForExporting.set(membershipTypeOfEachScheduleForExporting);
                $membershipTypeOfEachSchedule.set(membershipTypeOfEachSchedule);
            })
    }

    const handleToggle = (value, $selectedArr) => () => {
        const currentIndex = $selectedArr.value.find(s => s.id === value.id);
        let newChecked = [...$selectedArr.value];

        if (!currentIndex) {
            newChecked.push(value);
        } else {
            newChecked = newChecked.filter(s => s.id !== value.id)
        }
        $selectedArr.set(newChecked);
    };

    useEffect(() => {
        setTimeout(() => {
            //@ts-ignore
            prevPressedKeyRef.current = $pressedKey.value;
        }, 50);
    }, []);

    useEffect(() => {
        if ($dayTypes.value && Object.keys($dayTypes.value).length) {
            setTimeout(() => {
                window.addEventListener("keydown", handleUserKeyPress);
            }, 100);

            return () => {
                window.removeEventListener("keydown", handleUserKeyPress);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$dayTypes.value]);

    const handleUserKeyPress = event => {
        const { key } = event;
        const lowerKey = key.toLowerCase();
        if (lowerKey && ($dayTypes.value[lowerKey] || lowerKey === 'delete')) {
            $pressedKey.set([key]);
        }
    };

    useEffect(() => {
        takeDayAction(null, $pressedKey.value[0]?.toLowerCase());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$pressedKey.value])

    function takeDayAction(e, hotkey) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!hotkey) {
            return;
        }
        if (!$focusedCells.value.length || $focusedCells.value.some(fc => !fc.key)) {
            return;
        }
        if ($loading.value) {
            return;
        }

        $loading.set(true);
        let doneCells = 0;
        for (let keyIndex = 0; keyIndex < $focusedCells.value.length; keyIndex++) {
            const focusCell = $focusedCells.value[keyIndex];
            const _date = new Date(focusCell.year, focusCell.month - 1, focusCell.day)
            const date = parseDate(_date, '-');
            focusCell.date = date;
            focusCell._date = _date;
            const dayTypeDto: DayTypeDto = {
                year: focusCell.year,
                month: focusCell.month,
                day: focusCell.day,
                dayTypeId: hotkey === 'delete' ? 0 : $dayTypes.value[hotkey].id,
            };

            if (dayTypeDto.dayTypeId === 0 && focusCell?.member?.days?.findIndex(d => d.date === date) === -1) {
                doneCells++;
                if (doneCells === $focusedCells.value.length) {
                    unfocus();
                    $loading.set(false);
                }
                continue;
            }

            if (focusCell.note) {
                dayTypeDto.note = focusCell.note;
            }

            ScheduleService.updateScheduleDay(dayTypeDto, focusCell.scheduleId, focusCell.userId)
                // eslint-disable-next-line no-loop-func
                .then(() => {
                    const tempSchedules = [...$schedules.value]
                    const member = tempSchedules[focusCell.scheduleIndex].members[focusCell.memberIndex];
                    if (member) {
                        if (hotkey === 'delete') {
                            const foundDayIndex = member.days.findIndex(md => md.date === focusCell.date)
                            if (foundDayIndex >= 0) {
                                if (focusCell.note) {
                                    const isWeekend = (focusCell._date.getDay() === 6) || (focusCell._date.getDay() === 0);
                                    const dayTypeLabel = isWeekend ? 'we' : 'e';
                                    member.days[foundDayIndex].dayTypeId = $dayTypes.value[dayTypeLabel].id;
                                } else {
                                    member.days.splice(foundDayIndex, 1);
                                }
                            }
                        } else {
                            const foundDay = member.days.find(md => md.date === focusCell.date)
                            if (foundDay) {
                                foundDay.dayTypeId = $dayTypes.value[hotkey].id;
                            } else {
                                member.days.push({
                                    date: focusCell.date,
                                    dayTypeId: $dayTypes.value[hotkey].id,
                                    note: null
                                })
                            }
                        }
                    }

                    $schedules.set(tempSchedules)
                    doneCells++;
                    if (doneCells === $focusedCells.value.length) {
                        unfocus();
                        $loading.set(false);
                    }
                })
                .catch(err => {
                    unfocus();
                    $error.set(err.message);
                    $loading.set(false);
                    setTimeout(() => $error.set(''), 5000)
                });
        }
    }

    function unfocus(e?: any, keyIndex?: number) {
        if (keyIndex || keyIndex === 0) {
            $focusedCells.set($focusedCells.value.slice(0, keyIndex).concat($focusedCells.value.slice(keyIndex + 1)));
        } else {
            $focusedCells.set([]);
        }
        $focusCellForNote.set({});
    }

    return (
        <div className={classes.pageLayout}>
            {$error.value ?
                <Alert severity="error">{$error.value}</Alert>
                :
                null
            }
            {$isSelectedRequestedLeave.value && $selectedLeaveRequestUser.value ?
                <SelectedLeaveRequest
                    user={$selectedLeaveRequestUser.value}
                    $isOpen={$isSelectedRequestedLeave}
                    selectedLeave={$selectedRequestedLeave.value as LeaveRequest}
                    //TODO instead of $refresh.set({}); find the member in all schedules and remove the vacation day 
                    onDeletedRequest={() => $refresh.set({})}
                    hideReason={
                        $selectedLeaveRequestUser.value.id !== $userData.value.id
                        && $userData.value.userType === UserType.User
                    }
                />
                :
                null
            }
            {$showVacationRequestModal.value ?
                <VacationRequest
                    $userData={$userData}
                    $isOpen={$showVacationRequestModal}
                    forUserId={$userData.value.id}
                    nationalHolidays={$nationalHolidays.value}
                    onAddedRequests={newRequests => {
                        //TODO instead of $refresh.set({}); find the member in all schedules and set the vacation day 
                        $refresh.set({});
                        const userId = newRequests[0].userId;
                        const userReq = $subordinateUsersLeaves.value ? $subordinateUsersLeaves.value[userId] : undefined;
                        $subordinateUsersLeaves.set({
                            ...$subordinateUsersLeaves.value,
                            [userId]: userReq ? newRequests.concat(userReq) : newRequests,
                        });
                        syncUser(userId);
                    }}
                    subordinates={$subordinateUsers.value}
                />
                :
                null
            }
            <BrandModal
                open={$showCopyMemberSchedulePopup.value}
                className={classes.copyMemberSchedulePopup}
                maxWidth='sm'
                title='To which schedule do you want to paste this data to?'
            >
                <CopyMemberSchedulePopup
                    $copyMemberScheduleData={$copyMemberScheduleData}
                    $schedules={$schedules}
                    onClose={() => {
                        $showCopyMemberSchedulePopup.set(false);
                        $copyMemberScheduleData.set({
                            member: {
                                gender: 1,
                                firstName: '',
                                lastName: '',
                                jobPosition: '',
                                id: 0,
                                department: '',
                            },
                            fromSchedule: {
                                id: 0,
                                name: '',
                            }
                        });
                    }}
                    $error={$error}
                    $loading={$loading}
                    $currentUser={$currentUser}
                    onRefresh={() => {
                        //TODO instead of fetchMySchedules(); getMembershipTypes(); find the users in the schedule and edit them
                        fetchMySchedules();
                        getMembershipTypes();
                    }}
                    $dateFilter={$dateFilter}
                />
            </BrandModal>
            <BrandModal
                open={$showScheduleModal.value}
                className={classes.scheduleModal}
                maxWidth='lg'
                title={$deleteSchedule.value ? '' : $scheduleForEdit.value ? "Edit Schedule" : "Create Schedule"}
            >
                <ScheduleModal
                    onClose={() => {
                        $scheduleForEdit.set(null);
                        $showScheduleModal.set(false);
                    }}
                    $selectedUser={$selectedUser}
                    $tempSubordinateUsers={$subordinateUsers}
                    scheduleForEdit={$scheduleForEdit.value}
                    setScheduleForEdit={$scheduleForEdit.set}
                    $dateFilter={$dateFilter}
                    $debouncedFilter={$debouncedFilter}
                    $deleteSchedule={$deleteSchedule}
                    $error={$error}
                    onRefresh={() => {
                        //TODO instead of fetchMySchedules(); getMembershipTypes(); find the users in the schedule and edit them
                        fetchMySchedules(true);
                        getMembershipTypes();
                    }}
                />
            </BrandModal>
            <BrandModal className={classes.cloneAndExportScheduleModal} open={$showCloneAndExportScheduleModal.value} maxWidth='lg'>
                <CloneAndExportScheduleModal
                    onClose={() => {
                        $showCloneAndExportScheduleModal.set(false);
                        $scheduleForEdit.set(null);
                        $exporting.set(false);
                    }}
                    scheduleForEdit={$scheduleForEdit.value}
                    $loading={$loading}
                    $error={$error}
                    $exporting={$exporting.value}
                    $membershipTypeOfEachSchedule={$membershipTypeOfEachScheduleForExporting}
                    $dayTypesById={$dayTypesById}
                    $dayTypes={$dayTypes}
                    filter={$debouncedFilter.value}
                    scheduleFilterFromDropdown={$scheduleFilterFromDropdown.value}
                    scheduleFilterFromDropdownAsString={$scheduleFilterFromDropdownAsString.value}
                />
            </BrandModal>
            <BrandModal
                open={$showNoteModal.value}
                maxWidth='lg'
                title={$deleteNote.value ? '' : 'Note Box'}
            >
                <NoteModal
                    onClose={() => {
                        $showNoteModal.set(false);
                        unfocus();
                        fetchMySchedules();
                    }}
                    $noteModalInfo={$noteModalInfo}
                    $loading={$loading}
                    $error={$error}
                    $schedules={$schedules}
                    $deleteNote={$deleteNote}
                />
            </BrandModal>
            <div className={classes.legendContainer}>
                <div className={classes.tooltipActions}>
                    <IconButton
                        className={classes.noteIconButton}
                        disabled={!$focusCellForNote.value.key}
                        onClick={(e) => {
                            if ($focusCellForNote.value.key) {
                                e.stopPropagation();
                                $showNoteModal.set(true);
                                $noteModalInfo.set($focusCellForNote.value);
                            }
                        }}
                    >
                        <NoteAddIcon style={$focusCellForNote.value.key ? { color: '#EDAB00' } : {}} />
                    </IconButton>
                </div>
                <div>
                    <div className={classes.calendarControls}>
                        <IconButton onClick={() => $dateFilter.set(subMonths($dateFilter.value, 1))}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </IconButton>
                        <IconButton onClick={() => $dateFilter.set(addMonths($dateFilter.value, 1))}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </IconButton>
                        <span className={classes.calendarBarDate}>
                            {format($dateFilter.value, 'MMMM y')}
                        </span>
                        <BrandButton
                            onClick={() => {
                                $dateFilter.set(new Date());
                            }}
                            variant="outlined"
                            color="primary"
                        >
                            Today - {format($dateFilter.value, 'd MMMM')}
                        </BrandButton>
                    </div>
                </div>
                <div className={classes.tooltipActions}>
                    {UserType.Approver === $currentUser.value.userType ?
                        <>
                            <div className={clsx(classes.greyButtonSelect, $selectedScheduleAnchorEl.value ? classes.blueBorder : null)}>
                                <BrandButton color="primary" variant='outlined' onClick={e => $selectedScheduleAnchorEl.set(e.currentTarget)}>
                                    All Schedules <ArrowDropDownIcon />
                                </BrandButton>
                                <Popover
                                    id={Boolean($selectedScheduleAnchorEl.value) ? 'simple-popover' : undefined}
                                    open={Boolean($selectedScheduleAnchorEl.value)}
                                    anchorEl={$selectedScheduleAnchorEl.value}
                                    onClose={() => $selectedScheduleAnchorEl.set(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <List dense className={classes.listPopover}>
                                        {($schedulesFilter.value ? $schedulesFilter.value : []).map((value) => {
                                            const labelId = `checkbox-list-secondary-label-${value.id}`;
                                            return (
                                                <ListItem key={labelId}>
                                                    <ListItemText primary={value.name} />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            color="primary"
                                                            edge="end"
                                                            onChange={handleToggle(value, $selectedSchedules)}
                                                            checked={$selectedSchedules.value.findIndex(s => s.id === value.id) >= 0}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Popover>
                            </div>
                            <div>
                                <IconButton
                                    className={clsx(classes.disableRipple, classes.iconButton)}
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    disableTouchRipple={true}
                                    onClick={() => $showScheduleModal.set(true)}
                                >
                                    <CreateScheduleIcon />
                                </IconButton>
                            </div>
                        </>
                        :
                        null
                    }
                    <div>
                        <IconButton className={classes.iconButton} onClick={() => $showVacationRequestModal.set(true)}>
                            <AddCircleIcon className={classes.requestLeaveButtonIcon} />
                        </IconButton>
                    </div>
                    {UserType.Approver === $currentUser.value.userType || $isAdminOrViewOnlyAdmin.value ?
                        <div>
                            <IconButton
                                className={classes.iconButton}
                                onClick={() => {
                                    $scheduleForEdit.set(null);
                                    $showCloneAndExportScheduleModal.set(true);
                                    $exporting.set(true);
                                }}
                            >
                                <ExportIcon className={classes.exportIcon} />
                            </IconButton>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            <div className={clsx(classes.legendContainer, classes.legendContainerGap, classes.legendContainerButton)}>
                {$dayTypes.value && Object.entries($dayTypes.value).length ? Object.values($dayTypes.value).map(dayType => {
                    if (dayType.id === 9) {
                        return null;
                    }
                    return (
                        <BrandButton
                            key={dayType.id}
                            onClick={(e) => takeDayAction(e, dayType.hotkey)}
                            variant="outlined"
                            disabled={Boolean($focusedCells.value.some(fc => !fc.key))}
                        >
                            <div className={classes.legend}>
                                <div
                                    style={{
                                        background: dayType.color,
                                        border: dayType.color === '#FFFFFF' ? '1px solid #727272' : ''
                                    }}
                                    className={classes.square}
                                />
                                <div className={clsx(classes.label, classes.legendFontSize)}>
                                    {`${dayType.label} - ${dayType.name}`}
                                </div>
                            </div>
                        </BrandButton>
                    );
                })
                    :
                    null
                }
            </div>
            <ReportsTable
                schedules={$schedules.value}
                dateFilter={$dateFilter.value}
                $focusedCells={$focusedCells}
                $scheduleForEdit={$scheduleForEdit}
                $showScheduleModal={$showScheduleModal}
                unfocus={unfocus}
                $dayTypesById={$dayTypesById}
                $dayTypes={$dayTypes}
                $showCloneAndExportScheduleModal={$showCloneAndExportScheduleModal}
                $vacationRequests={$vacationRequests}
                $isSelectedRequestedLeave={$isSelectedRequestedLeave}
                $selectedRequestedLeave={$selectedRequestedLeave}
                $error={$error}
                $exporting={$exporting}
                $homeOfficesFilterDic={$homeOfficesFilterDic}
                membershipTypeOfEachSchedule={$membershipTypeOfEachSchedule}
                $focusCellForNote={$focusCellForNote}
                $selectedLeaveRequestUser={$selectedLeaveRequestUser}
                showCopyMemberSchedulePopup={showCopyMemberSchedulePopup}
                $currentUser={$currentUser}
            />
        </div>
    );
}

const ReportsTable = ({
    schedules,
    dateFilter,
    $focusedCells,
    $scheduleForEdit,
    $showScheduleModal,
    unfocus,
    $dayTypes,
    $dayTypesById,
    $showCloneAndExportScheduleModal,
    $vacationRequests,
    $isSelectedRequestedLeave,
    $selectedRequestedLeave,
    $error,
    $exporting,
    $homeOfficesFilterDic,
    membershipTypeOfEachSchedule,
    $focusCellForNote,
    $selectedLeaveRequestUser,
    showCopyMemberSchedulePopup,
    $currentUser
}) => {
    const classes = useStyles();
    const $allDaysInMonth = useHook<number[]>([]);
    const $todaysDate = useHook<number>(new Date().getDate());
    const $todaysMonth = useHook<number>(new Date().getMonth() + 1);
    const $dateFilterMonth = useHook<number>(new Date().getMonth() + 1);
    const $homeOfficeEl = useHook<any>(null);
    const $homeOfficeFilter = useHook<any[]>([]);
    const $tempFocusedCells = useHook<any[]>([]);
    const $selectedHomeOffices = useHook({});
    const $selectedScheduleId = useHook<number>(0);

    useEffect(() => {
        if ($tempFocusedCells.value.length) {
            $focusedCells.set($tempFocusedCells.value);
            $tempFocusedCells.set([]);
        }
    }, [$tempFocusedCells.value])

    const handleToggle = (value) => () => {
        const key = $selectedScheduleId.value;
        const selectedHomeOffices = $selectedHomeOffices.value;
        if (!selectedHomeOffices[key]) {
            selectedHomeOffices[key] = [];
        }

        const currentIndex = selectedHomeOffices[key].find(s => s === value);
        let newChecked = { ...selectedHomeOffices };

        if (!currentIndex) {
            newChecked[key].push(value);
        } else {
            newChecked[key] = newChecked[key].filter(s => s !== value)
        }
        $selectedHomeOffices.set(newChecked);
    };

    useEffect(() => {
        const dateFilterMonth = dateFilter.getMonth();
        const dateFilterYear = dateFilter.getFullYear();
        const allDaysInMonth = getDaysInMonth(dateFilterMonth, dateFilterYear, false);
        $allDaysInMonth.set(allDaysInMonth);
        $dateFilterMonth.set(dateFilterMonth + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilter])

    const onClickCellWithLeaveRequest = (member, vacationId) => {
        const foundLeaveRequest = member.vacations.find(v => v.id === vacationId);
        VacationService.getLeaveRequest(foundLeaveRequest.id)
            .then(data => {
                if (data && data.approvers) {
                    //@ts-ignore
                    data.approvers = JSON.parse(data.approvers);
                }
                $selectedLeaveRequestUser.set(member);
                $selectedRequestedLeave.set(data);
                $isSelectedRequestedLeave.set(true);
            })
            .catch(err => {
                $error.set(err.message);
                setTimeout(() => {
                    $error.set('');
                }, 5000)
            });
    };

    return (
        !schedules || (schedules && schedules.length === 0) ?
            null
            :
            < div className={classes.reportsTableWrapper} >
                <Popover
                    id={Boolean($homeOfficeEl.value) ? 'simple-popover' : undefined}
                    open={Boolean($homeOfficeEl.value)}
                    anchorEl={$homeOfficeEl.value}
                    onClose={() => {
                        $homeOfficeEl.set(null);
                        $homeOfficeFilter.set([]);
                        $selectedScheduleId.set(0);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List dense className={classes.listPopover}>
                        {($homeOfficeFilter.value ? $homeOfficeFilter.value : []).map((value) => {
                            const labelId = `checkbox-list-secondary-label-${value}`;
                            return (
                                <ListItem key={labelId}>
                                    <ListItemText primary={value} />
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            color="primary"
                                            edge="end"
                                            onChange={handleToggle(value)}
                                            checked={Boolean($selectedHomeOffices.value[$selectedScheduleId.value]) && $selectedHomeOffices.value[$selectedScheduleId.value].findIndex(s => s === value) >= 0}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                </Popover>
                <div className={classes.container}>
                    <table className={clsx(classes.table, classes.stickyThcTh, classes.tableForTableHeader)}>
                        <thead>
                            <tr>
                                <th className={classes.firstRowFirstColumn} />
                                <th className={clsx(classes.firstRowColumn, classes.officesColumn)} />
                                <th className={clsx(classes.firstRowColumn, classes.shiftColumn)}>Shift</th>
                                {$allDaysInMonth.value.map(day =>
                                    <th key={day} className={classes.firstRowDayColumn} >
                                        <div className={classes.daySquareContainer}>
                                            <div className={clsx($dateFilterMonth.value === $todaysMonth.value && $todaysDate.value === day ?
                                                classes.dayIsToday : '',
                                                classes.daySquare
                                            )}>
                                                {day}
                                            </div>
                                        </div>
                                    </th>
                                )}
                            </tr>
                        </thead>
                    </table>
                    <ClickAwayListener onClickAway={unfocus}>
                        <div className={classes.tableContainer}>
                            <div className={classes.container}>
                                {schedules.map((schedule, scheduleIndex) => {
                                    if (schedule && schedule.members && schedule.members.length === 0) {
                                        return null;
                                    }
                                    return (
                                        <table key={schedule.id} className={clsx(classes.table, classes.stickyThcTh)}>
                                            <thead onClick={unfocus}>
                                                <tr className={classes.scheduleNameRow}>
                                                    <th className={clsx(classes.firstRowFirstColumn, classes.scheduleName)}>
                                                        <div className={classes.scheduleNameWrapper}>
                                                            <div onClick={unfocus}>{schedule.name}</div>
                                                            {membershipTypeOfEachSchedule.value[schedule.id] ?
                                                                <>
                                                                    <div className={classes.icon}>
                                                                        <IconButton
                                                                            className={classes.disableRipple}
                                                                            disableFocusRipple={true}
                                                                            disableRipple={true}
                                                                            disableTouchRipple={true}
                                                                            onClick={() => {
                                                                                $scheduleForEdit.set(schedule);
                                                                                $showScheduleModal.set(true);
                                                                            }}
                                                                        >
                                                                            <EditScheduleIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                    <div className={classes.icon}>
                                                                        <IconButton
                                                                            className={classes.disableRipple}
                                                                            disableFocusRipple={true}
                                                                            disableRipple={true}
                                                                            disableTouchRipple={true}
                                                                            onClick={() => {
                                                                                $scheduleForEdit.set(schedule);
                                                                                $showCloneAndExportScheduleModal.set(true);
                                                                                $exporting.set(false);
                                                                            }}
                                                                        >
                                                                            <DocumentsIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </th>
                                                    <th onClick={unfocus} className={clsx(classes.firstRowColumn, classes.officesColumn, classes.scheduleName)} >
                                                        <div>Office</div>
                                                        <div className={classes.dividerWrapper}><div className={classes.divider} /></div>
                                                        <div className={clsx(classes.homeOfficeFilter, membershipTypeOfEachSchedule.value[schedule.id] ? null : classes.cursorAuto)}
                                                            onClick={e => {
                                                                if (schedule.id) {
                                                                    $selectedScheduleId.set(schedule.id);
                                                                    $homeOfficeEl.set(e.currentTarget);
                                                                    $homeOfficeFilter.set($homeOfficesFilterDic.value[schedule.id]);
                                                                }
                                                            }}
                                                        >
                                                            <div>Home Office</div>
                                                            {membershipTypeOfEachSchedule.value[schedule.id] ?
                                                                <div><ArrowDropDownIcon /></div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </th>
                                                    <th onClick={unfocus} className={clsx(classes.firstRowColumn, classes.shiftColumn)} />
                                                    {$allDaysInMonth.value.map(day => <th key={`blank-${day}`} onClick={unfocus} className={classes.firstRowDayColumn} />)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {schedule.members.map((member, memberIndex) => {
                                                    if ($selectedHomeOffices.value
                                                        && $selectedHomeOffices.value[schedule.id]
                                                        && $selectedHomeOffices.value[schedule.id].length
                                                    ) {
                                                        const foundMember = $selectedHomeOffices.value[schedule.id].find(ho => ho === member.homeOffice);
                                                        if (!foundMember) {
                                                            return null;
                                                        }
                                                    }

                                                    return (
                                                        <tr className={clsx(classes.topAndBottomBorder, memberIndex === 0 ? classes.borderTop : null)}
                                                            key={member.id + memberIndex + schedule.id}>
                                                            <td onClick={unfocus} className={clsx(classes.firstColumn, classes.avatarCell)}>
                                                                <Avatar
                                                                    className={member.gender === 1 ? classes.maleAvatar : classes.femaleAvatar}
                                                                    onClick={() => {
                                                                        const focusedCells: any[] = [];

                                                                        for (let i = 0; i < $allDaysInMonth.value.length; i++) {
                                                                            const day = $allDaysInMonth.value[i];

                                                                            const key = `${day}/${member.id}/${schedule.id}`;
                                                                            const year = dateFilter.getFullYear();
                                                                            const month = dateFilter.getMonth();
                                                                            const date = parseDate(new Date(year, month, day), '-');

                                                                            if ($vacationRequests.value
                                                                                && $vacationRequests.value[member.id]
                                                                                && $vacationRequests.value[member.id][date]) {
                                                                                const vacationType = $vacationRequests.value[member.id][date];
                                                                                const status = vacationType.status;
                                                                                const category = vacationType.category;
                                                                                if (!((status === 3 || status === 4) && category === 3)) {
                                                                                    continue;
                                                                                }
                                                                            }

                                                                            const _date = new Date(year, month, day)
                                                                            const parsedDate = parseDate(_date, '-');
                                                                            const isWeekend = (_date.getDay() === 6) || (_date.getDay() === 0);
                                                                            if (isWeekend) {
                                                                                continue;
                                                                            }

                                                                            const memberDay = member.days.find(md => md.date === parsedDate);
                                                                            let dayTypeId = null;
                                                                            let dayType = {};
                                                                            let note = null;

                                                                            if (memberDay) {
                                                                                dayTypeId = memberDay.dayTypeId;
                                                                                if (memberDay.note && memberDay.note === 'NULL') {
                                                                                    memberDay.note = null;
                                                                                } else {
                                                                                    note = memberDay.note
                                                                                }
                                                                                dayType = $dayTypes.value[$dayTypesById.value[memberDay.dayTypeId]];
                                                                            } else {
                                                                                const isWeekend = (_date.getDay() === 6) || (_date.getDay() === 0);
                                                                                const dayTypeLabel = isWeekend ? 'we' : 'e';
                                                                                const dayType = $dayTypes.value[dayTypeLabel];
                                                                                if (dayType) {
                                                                                    dayTypeId = dayType.id;
                                                                                }
                                                                            }

                                                                            const focusCellData = {
                                                                                dayTypeId: dayTypeId,
                                                                                key: key,
                                                                                year: year,
                                                                                month: month + 1,
                                                                                day: day,
                                                                                scheduleIndex: scheduleIndex,
                                                                                memberIndex: memberIndex,
                                                                                scheduleId: schedule.id,
                                                                                userId: member.id,
                                                                                note: note,
                                                                                member: member,
                                                                                scheduleName: schedule.name,
                                                                                shiftStart: `${schedule.shiftStart} ${schedule.shiftStartTime}`,
                                                                                shiftEnd: `${schedule.shiftEnd} ${schedule.shiftEndTime}`,
                                                                                isAdminOrViewOnlyAdmin: membershipTypeOfEachSchedule.value[schedule.id]
                                                                            };
                                                                            focusedCells.push(focusCellData);
                                                                        }

                                                                        if (membershipTypeOfEachSchedule.value[schedule.id]) {
                                                                            $tempFocusedCells.set(focusedCells);
                                                                        }
                                                                    }}
                                                                >
                                                                    {member.firstName.charAt(0) + member.lastName.charAt(0)}
                                                                </Avatar>
                                                                <div className={classes.userData}>
                                                                    <div className={classes.fullNameAndIdContainer}>
                                                                        <div className={clsx(classes.boldLabel, classes.fullNameContainer)}>
                                                                            <div>{member.firstName}</div>&nbsp;<div>{member.lastName}</div>
                                                                        </div>
                                                                        <div className={classes.employeeIdLabel}>ID-{member.id}</div>
                                                                    </div>
                                                                    {schedule.members.find(m => (m.membershipType === 2 || m.membershipType === 3) && m.id === $currentUser.value.id) ?
                                                                        <div
                                                                            className={classes.copyMemberScheduleIcon}
                                                                            onClick={(e) => {
                                                                                showCopyMemberSchedulePopup(e, {
                                                                                    member: member,
                                                                                    fromSchedule: schedule
                                                                                });
                                                                            }}
                                                                        >
                                                                            <CopyMemberScheduleIcon />
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td onClick={unfocus} className={clsx(classes.column, classes.officesColumn)}>
                                                                <div className={classes.office}>
                                                                    <div>{member.office}</div>
                                                                    <div>{member.homeOffice === 'Missing from DB' ? '' : member.homeOffice}</div>
                                                                </div>
                                                            </td>
                                                            <td onClick={unfocus} className={clsx(classes.column, classes.shiftColumn)}>
                                                                <div className={classes.shift}>
                                                                    <div>{`${schedule.shiftStart} ${schedule.shiftStartTime}`}</div>
                                                                    <div>{`${schedule.shiftEnd} ${schedule.shiftEndTime}`}</div>
                                                                </div>
                                                            </td>
                                                            {$allDaysInMonth.value.map(day => {
                                                                const key = `${day}/${member.id}/${schedule.id}`
                                                                const year = dateFilter.getFullYear()
                                                                const month = dateFilter.getMonth()
                                                                const date = parseDate(new Date(year, month, day), '-');

                                                                if ($vacationRequests.value
                                                                    && $vacationRequests.value[member.id]
                                                                    && $vacationRequests.value[member.id][date]) {
                                                                    const vacationType = $vacationRequests.value[member.id][date];
                                                                    const status = vacationType.status;
                                                                    const category = vacationType.category;
                                                                    const vacationId = vacationType.vacationId;
                                                                    if (!((status === 3 || status === 4) && category === 3)) {
                                                                        return (
                                                                            <td
                                                                                key={key}
                                                                                className={classes.dayColumn}
                                                                                onClick={() => onClickCellWithLeaveRequest(member, vacationId)}
                                                                            >
                                                                                <Tooltip title={`${status === 1 ? 'Pending ' : ''}${vacationTypes.vacationLabels[category]}`}>
                                                                                    <div className={clsx(classes.calendarTile, vacationTypes[status][category])} key={key + '-week'}>
                                                                                        <abbr className={classes.calendarTileText}>
                                                                                            {vacationTypes.vacationAlias[status][category]}
                                                                                        </abbr>
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </td>
                                                                        );
                                                                    }
                                                                }

                                                                let status = { background: '#FFFFFF', status: 'E' };
                                                                const memberDay = member.days.find(md => md.date === date);
                                                                let dayTypeId = null;
                                                                let note = null;
                                                                let dayType = {};
                                                                if (memberDay) {
                                                                    dayTypeId = memberDay.dayTypeId;
                                                                    if (memberDay.note && memberDay.note === 'NULL') {
                                                                        memberDay.note = null;
                                                                    } else {
                                                                        note = memberDay.note
                                                                    }
                                                                    dayType = $dayTypes.value[$dayTypesById.value[memberDay.dayTypeId]]
                                                                    if (dayType) {
                                                                        //@ts-ignore
                                                                        const dayTypeLabel = (dayType?.id === 8) || (dayType?.id === 9) ? '' : dayType.label;
                                                                        //@ts-ignore
                                                                        status = { background: dayType.color, status: dayTypeLabel }
                                                                    }
                                                                } else {
                                                                    const _date = new Date(year, month, day)
                                                                    const isWeekend = (_date.getDay() === 6) || (_date.getDay() === 0);
                                                                    const dayTypeLabel = isWeekend ? 'we' : 'e'
                                                                    const dayType = $dayTypes.value[dayTypeLabel]
                                                                    if (dayType) {
                                                                        dayTypeId = dayType.id;
                                                                        const dayTypeLabel = (dayType?.id === 8) || (dayType?.id === 9) ? '' : dayType.label;
                                                                        status = { background: dayType.color, status: dayTypeLabel }
                                                                    }
                                                                }

                                                                return (
                                                                    <td
                                                                        key={key}
                                                                        className={clsx(classes.dayColumn, membershipTypeOfEachSchedule.value[schedule.id] ? null : classes.cursorAuto)}
                                                                        onClick={() => {

                                                                            if ($focusedCells.value && $focusedCells.value.length > 0) {
                                                                                const keyIndex = $focusedCells.value.findIndex(fc => fc.key === key);
                                                                                if (keyIndex >= 0) {
                                                                                    unfocus(null, keyIndex);
                                                                                    return;
                                                                                }
                                                                            }

                                                                            const focusCellData = {
                                                                                dayTypeId: dayTypeId,
                                                                                scheduleIndex: scheduleIndex,
                                                                                memberIndex: memberIndex,
                                                                                key: key,
                                                                                scheduleId: schedule.id,
                                                                                userId: member.id,
                                                                                year: year,
                                                                                month: month + 1,
                                                                                day: day,
                                                                                note: note,
                                                                                member: member,
                                                                                scheduleName: schedule.name,
                                                                                shiftStart: `${schedule.shiftStart} ${schedule.shiftStartTime}`,
                                                                                shiftEnd: `${schedule.shiftEnd} ${schedule.shiftEndTime}`,
                                                                                isAdminOrViewOnlyAdmin: membershipTypeOfEachSchedule.value[schedule.id]
                                                                            };

                                                                            if (membershipTypeOfEachSchedule.value[schedule.id]) {
                                                                                $focusedCells.set([focusCellData])
                                                                            }
                                                                            $focusCellForNote.set(focusCellData)
                                                                        }}
                                                                    >
                                                                        {/*
                                                                        // @ts-ignore */}
                                                                        <Tooltip title={dayType && dayType.name ? dayType.name : ''}>
                                                                            <div
                                                                                style={memberDay && memberDay.note ?
                                                                                    { background: 'linear-gradient(90deg, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0) 50%) !important', width: '24px', marginLeft: '1px', height: '76px' }
                                                                                    :
                                                                                    {}
                                                                                }
                                                                                className={clsx(classes.dayColumnHeaderWrapper, $focusedCells.value.find(fc => fc.key === key) ? classes.focusCell : null)}
                                                                            >
                                                                                <div
                                                                                    style={memberDay && memberDay.note ?
                                                                                        { background: 'linear-gradient(to top, rgba(0,0,0,0) 84%, #6C0BA9 20%)', width: '24px', marginLeft: '1px', height: '76px' }
                                                                                        :
                                                                                        {}
                                                                                    }
                                                                                    className={clsx(classes.dayColumnHeaderWrapper, $focusedCells.value.find(fc => fc.key === key) ? classes.focusCell : null)}
                                                                                >
                                                                                    <div style={{ background: status.background }}
                                                                                        className={clsx(classes.dayColumnBackground, memberDay && memberDay.note ? classes.noteColumn : null)}
                                                                                    >
                                                                                        <div>
                                                                                            {status.status === 'E' || status.status === 'WE' ? '' : status.status}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    );
                                })}
                            </div>
                        </div>
                    </ClickAwayListener>
                </div >
            </div >
    );
};

const ScheduleModal = ({ onClose, $selectedUser, $tempSubordinateUsers, scheduleForEdit, $debouncedFilter, $dateFilter, setScheduleForEdit, $deleteSchedule, $error, onRefresh }) => {
    const { $currentUser } = useStoreContext();
    const classes = useStyles();
    const $name = useHook(scheduleForEdit?.name || '');

    const $from = useHook(scheduleForEdit?.shiftStart || '00:00');
    const $fromLabel = useHook(scheduleForEdit?.shiftStartTime || 'AM');
    const $fromIsValid = useHook(false);

    const $to = useHook(scheduleForEdit?.shiftEnd || '00:00');
    const $toLabel = useHook(scheduleForEdit?.shiftEndTime || 'PM');
    const $toIsValid = useHook(false);
    const $subordinateUsers = useHook<User[]>($tempSubordinateUsers?.value ? [...$tempSubordinateUsers.value] : []);

    const $selectedApprovers = useHook<any[]>([]);
    const $selectedUsers = useHook<any[]>([]);
    const $office = useHook<string>(scheduleForEdit?.office || 'Sofia');

    const $scheduleHistory = useHook<ScheduleHistory[]>([]);
    const $executeFilter = useHook(false);

    const fetchScheduleHistory = () => {
        ScheduleService.getScheduleHistory(scheduleForEdit.id)
            .then(res => $scheduleHistory.set(res))
            .catch(err => {
                $error.set(err.message);
                setTimeout(() => {
                    $error.set('');
                }, 5000)
            });
    }

    function createSchedule(e) {
        e.preventDefault();
        const members = {};
        for (const user of $selectedUsers.value) {
            user.membershipType = membershipType.Member;
            members[user.id] = user;
        }

        for (const approver of $selectedApprovers.value) {
            if (members[approver.id]) {
                members[approver.id].membershipType = membershipType.ViewOnlyAdmin;
            } else {
                approver.membershipType = membershipType.Admin;
                members[approver.id] = approver;
            }
        }

        const schedule: ScheduleDto = {
            name: $name.value,
            shiftStart: $from.value,
            shiftStartTime: $fromLabel.value,
            shiftEnd: $to.value,
            shiftEndTime: $toLabel.value,
            office: $office.value,
            members: Object.values(members)
        }

        ScheduleService.createSchedule(schedule)
            .then(async res => {
                onClose();
                onRefresh();
            })
            .catch(err => {
                $error.set(err.message);
                setTimeout(() => {
                    $error.set('');
                }, 5000)
            });
    }

    function editSchedule(e) {
        e.preventDefault();
        const members = {};
        for (const user of $selectedUsers.value) {
            user.membershipType = membershipType.Member;
            members[user.id] = user;
        }

        for (const approver of $selectedApprovers.value) {
            if (members[approver.id]) {
                members[approver.id].membershipType = membershipType.ViewOnlyAdmin;
            } else {
                approver.membershipType = membershipType.Admin;
                members[approver.id] = approver;
            }
        }

        const schedule: ScheduleDto = {
            name: $name.value,
            shiftStart: $from.value,
            shiftStartTime: $fromLabel.value,
            shiftEnd: $to.value,
            shiftEndTime: $toLabel.value,
            office: $office.value,
            ownerId: scheduleForEdit.ownerId,
            createdDate: scheduleForEdit.createdDate,
            lastUpdateDate: scheduleForEdit.lastUpdateDate,
            id: scheduleForEdit.id,
            members: Object.values(members),
        }

        ScheduleService.updateSchedule(schedule, scheduleForEdit.id)
            .then(async res => {
                onClose();
                onRefresh();
            })
            .catch(err => {
                $error.set(err.message);
                setTimeout(() => {
                    $error.set('');
                }, 5000)
            });
    }

    function onDeleteSchedule(e) {
        e.preventDefault();
        ScheduleService.deleteSchedule(scheduleForEdit.id)
            .then(async res => {
                $deleteSchedule.set(false);
                onClose();
                onRefresh()
            })
            .catch(err => {
                $deleteSchedule.set(false);
                $error.set(err.message);
                setTimeout(() => {
                    $error.set('');
                }, 5000)
            });
    }

    useEffect(() => {
        // @ts-ignore
        $selectedUser.set(null);
        if (scheduleForEdit) {
            fetchScheduleHistory();
            if ($debouncedFilter.value) {
                let scheduleFilter = `&schedule[]=${scheduleForEdit.id}`;
                ScheduleService.getMySchedules(
                    $dateFilter.value.getFullYear(),
                    $dateFilter.value.getMonth() + 1,
                    '',
                    scheduleFilter
                )
                    .then((res) => {
                        setScheduleForEdit(res[0]);
                        assignUsersAndApprovers(res[0]);
                    })
                    .catch(err => {
                        $error.set(err.message);
                        setTimeout(() => {
                            $error.set('');
                        }, 5000)
                    });
            } else {
                assignUsersAndApprovers(scheduleForEdit);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const assignUsersAndApprovers = (_schedule) => {
        const selectedUsers = [];
        const selectedApprovers = [];

        if (_schedule.members && _schedule.members.length) {
            for (const member of _schedule.members) {
                if (member && member.membershipType && member.id !== _schedule.ownerId) {
                    switch (member.membershipType) {
                        case membershipType.Admin:
                            // @ts-ignore
                            selectedApprovers.push(member)
                            break;
                        case membershipType.ViewOnlyAdmin:
                            // @ts-ignore
                            selectedUsers.push(member)
                            // @ts-ignore
                            selectedApprovers.push(member)
                            break;
                        case membershipType.Member:
                            // @ts-ignore
                            selectedUsers.push(member)
                            break;
                    }
                }
            }


            $selectedApprovers.set(selectedApprovers);
            $selectedUsers.set(selectedUsers);
            $executeFilter.set(true);
        }
    };

    useEffect(() => {
        if (scheduleForEdit && $executeFilter.value) {
            if ($selectedUsers?.value?.length && $subordinateUsers?.value?.length) {
                $subordinateUsers.set($subordinateUsers.value.filter(u => $selectedUsers.value.findIndex(su => su.id === u.id) < 0));
            }
            $executeFilter.set(false);
        }
    }, [$selectedUsers.value, $subordinateUsers.value, $executeFilter.value])

    useEffect(() => {
        const re = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
        const isValidFormat = re.test($from.value);
        $fromIsValid.set(isValidFormat);

        if (isValidFormat) {
            const timeTokens = $from.value.split(':');
            const hours = Number(timeTokens[0]);
            const minutes = Number(timeTokens[1]);
            checkLabel(hours, minutes, $fromLabel)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$from.value])

    useEffect(() => {
        const re = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
        const isValidFormat = re.test($to.value);
        $toIsValid.set(isValidFormat);

        if (isValidFormat) {
            const timeTokens = $to.value.split(':');
            const hours = Number(timeTokens[0]);
            const minutes = Number(timeTokens[1]);
            checkLabel(hours, minutes, $toLabel)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$to.value])

    return (
        $deleteSchedule.value ?
            <div className={classes.deleteSchedule}>
                <Typography style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 16 }} variant='h6' component="div">
                    Are you sure you want to delete<br />this Schedule?
                </Typography>
                <Typography style={{ textAlign: 'center', marginBottom: 16 }} variant='subtitle1' component="div">
                    {$name.value}
                </Typography>
                <div className={clsx(classes.approveAndCancel, classes.deleteScheduleApproveAndCancel)}>
                    <BrandButton onClick={() => $deleteSchedule.set(false)}>Cancel</BrandButton>
                    <BrandButton color="secondary" onClick={onDeleteSchedule}>
                        Yes
                    </BrandButton>
                </div>
            </div >
            :
            <div>
                <div>
                    <div className={classes.row}>
                        <div className={classes.firstRow}>
                            <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' component="div"><span style={{ color: '#F44336' }}>*</span>Name of schedule</Typography>
                            <Input required $value={$name} />
                        </div>
                        <div>
                            <Typography style={{ fontWeight: 'bold', marginBottom: '-23px' }} variant='subtitle1' component="div"><span style={{ color: '#F44336' }}>*</span>Work time of schedule</Typography>
                            <div className={classes.workTime}>
                                <Typography variant='subtitle1' component="span">From (EST)</Typography>
                                <div className={classes.timeContainer}>
                                    {/*
                                     // @ts-ignore */}
                                    <TimeInput
                                        name="from"
                                        initTime={$from.value}
                                        mountFocus='true'
                                        onTimeChange={(props) => $from.set(props)}
                                    />
                                    <div className={classes.invalidTime}>{!$fromIsValid.value ? 'Invalid Time Format' : ''}</div>
                                </div>
                                <div className={classes.amAndPm}><div>{$fromLabel.value}</div></div>
                                <Typography variant='subtitle1' component="span">To (EST)</Typography>
                                <div className={classes.timeContainer}>
                                    {/*
                                    // @ts-ignore */}
                                    <TimeInput
                                        name="to"
                                        initTime={$to.value}
                                        mountFocus='true'
                                        onTimeChange={(props) => $to.set(props)}
                                    />
                                    <div className={classes.invalidTime}>{!$toIsValid.value ? 'Invalid Time Format' : ''}</div>
                                </div>
                                <div className={classes.amAndPm}><div>{$toLabel.value}</div></div>
                            </div>
                        </div>
                        <div className={classes.radioGroup}>
                            <FormControl component="fieldset">
                                <Typography style={{ fontWeight: 'bold', marginLeft: '-7px' }} variant='subtitle1' component="div"><span style={{ color: '#F44336' }}>*</span>Select Offices</Typography>
                                <RadioGroup aria-label="gender" name="gender1" value={$office.value} onChange={(e) => $office.set(e.target.value)}>
                                    <FormControlLabel value="Sofia" control={<Radio color="primary" />} label="Sofia" />
                                    <FormControlLabel value="Burgas" control={<Radio color="primary" />} label="Burgas" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.col}>
                            <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' component="div">Members</Typography>
                            <div className={classes.addForm}>
                                <Autocomplete
                                    classes={{ root: classes.inputField }}
                                    options={$subordinateUsers.value}
                                    getOptionLabel={(option) => `${option.firstName} ${option.middleName} ${option.lastName} ID-${option.id}`}
                                    value={$selectedUser.value}
                                    onChange={(_, user) => $selectedUser.set(user)}
                                    renderInput={(params) => <Input {...params} required variant="outlined" />}
                                />
                                <BrandButton
                                    color="primary"
                                    className={classes.addBtn}
                                    onClick={() => {
                                        if ($selectedUser.value) {
                                            $selectedUsers.set([...$selectedUsers.value, $selectedUser.value]);
                                            // @ts-ignore
                                            $subordinateUsers.set($subordinateUsers.value.filter(u => u.id !== $selectedUser.value.id));
                                            $selectedUser.set(null);
                                        }
                                    }}
                                >
                                    Add
                                </BrandButton>
                            </div>
                            <List dense className={classes.membersList}>
                                {$selectedUsers.value.map((user, i) => {
                                    if (scheduleForEdit?.ownerId === user.id || $currentUser.value.id === user.id) {
                                        return null;
                                    };

                                    const key = `member-by-list-secondary-label-${user.id}-${i}`;
                                    return (
                                        <ListItem key={key}>
                                            <ListItemText primary={`${user.firstName} ${user.lastName} ID-${user.id}`} />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    onClick={() => {
                                                        $subordinateUsers.set([...$subordinateUsers.value, user]);
                                                        $selectedUsers.set($selectedUsers.value.filter(user2 => user2.id !== user.id));
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>
                        <div className={classes.col}>
                            <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' component="div">Share with Approvers</Typography>
                            <UserApproversAutocomplete $selectedUsers={$selectedApprovers} onLoadFilterUnselected={scheduleForEdit} filterMe={true} />
                        </div>
                        {scheduleForEdit ?
                            <div className={classes.col}>
                                <Typography style={{ fontWeight: 'bold', marginBottom: '60px', marginLeft: '14px' }} variant='subtitle1' component="div">Last edited by: </Typography>
                                <List dense className={classes.membersList}>
                                    {$scheduleHistory.value.map((edit, i) => {
                                        const key = `edited-by-list-secondary-label-${edit.userId}-${i}`;
                                        return (
                                            <ListItem key={key}>
                                                <ListItemText
                                                    primary={`${edit.fullName} ID-${edit.userId}`}
                                                    secondary={format(new Date(edit.createdDate), 'MMMM d HH:mm')}
                                                />

                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </div>
                            :
                            <div className={classes.col} />
                        }
                    </div>
                </div>
                <div className={clsx(classes.spaceBetween, classes.scheduleActionsMarginBottom)}>
                    {scheduleForEdit ?
                        <BrandButton color='secondary' onClick={() => $deleteSchedule.set(true)}>Delete</BrandButton>
                        :
                        <div />
                    }
                    <div className={classes.approveAndCancel}>
                        <BrandButton onClick={onClose}>{scheduleForEdit ? 'Close' : 'Cancel'}</BrandButton>
                        <BrandButton
                            disabled={!$fromIsValid.value || !$toIsValid.value || !$name.value /*|| !$selectedUsers.value.length || !$selectedApprovers.value.length*/}
                            color="primary"
                            onClick={scheduleForEdit ? editSchedule : createSchedule}
                        >
                            {scheduleForEdit ? 'Update' : 'Create'}
                        </BrandButton>
                    </div>
                </div>
            </div>
    );
}

const CloneAndExportScheduleModal = ({ onClose, scheduleForEdit, $loading, $error, $exporting, $membershipTypeOfEachSchedule, $dayTypesById, $dayTypes, filter, scheduleFilterFromDropdown, scheduleFilterFromDropdownAsString }) => {
    const classes = useStyles();
    const $selectedMonths = useHook<any>({});
    const $year = useHook(new Date());
    const $allMemberDays = useHook<any[]>([]);
    const $customDayTypes = useHook<{ [key: string]: DayType }>({});
    const $thisMonth = useHook(new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date()));
    const $thisYear = useHook(new Date().getFullYear());

    useEffect(() => {
        if (!scheduleForEdit) {
            return;
        }
        const allMemberDays = [];
        for (const member of scheduleForEdit.members) {
            for (const day of member.days) {
                const tempDay = { ...day };
                tempDay.userId = member.id;
                //@ts-ignore
                allMemberDays.push(tempDay);
            }
        }
        $allMemberDays.set(allMemberDays);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleForEdit])

    useEffect(() => {
        if ($exporting && $dayTypes.value) {
            const customDayTypes = { ...$dayTypes.value };
            for (const key of Object.keys(customDayTypes)) {
                customDayTypes[key] = { ...$dayTypes.value[key] };
                customDayTypes[key].color = customDayTypes[key].color.replace('#', '');
            }
            $customDayTypes.set(customDayTypes);
        }
    }, [$dayTypes.value])

    async function onExport() {
        $loading.set(true);
        //@ts-ignore
        const sortedKeysByMonth = Object.keys($selectedMonths.value).sort((a, b) => new Date(a + '01') - new Date(b + '01'));
        const scheduleIds = scheduleFilterFromDropdown?.length ? scheduleFilterFromDropdown : Object.keys($membershipTypeOfEachSchedule.value);
        const comments = {};
        const headersLvl1 = ['', '', '', '', '', 'Month'];
        const headersLvl2 = ['', '', '', '', '', 'Day'];
        const headersLvl3 = ['ID', 'First Last Name', 'Schedule Name', 'Shift', 'Office', 'Home Office'];
        const merges: any[] = [{ s: { r: 0, c: 0 }, e: { r: 1, c: 4 } }];
        const widths: any[] = [{ width: 7 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 12 }, { width: 16 }];
        const titleCols: any[] = ['A1', 'A2', 'A3', 'B1', 'B2', 'B3', 'C1', 'C2', 'C3', 'D1', 'D2', 'D3', 'E1', 'E2', 'E3', 'F1', 'F2', 'F3'];
        const monthsCols: any[] = [];
        const daysCols: any[] = [];
        const colIForDays = 71; //G
        const colIForStart = 65; //A

        //Gets cells for styling and fills months and days
        let columnIndex = 0;
        for (const key of sortedKeysByMonth) {
            const start = headersLvl2.length;
            const keyTokens = key.split('-');
            const year = Number(keyTokens[0]);
            const monthVerbal = keyTokens[1];
            const monthNumerical = monthsDic[monthVerbal];
            const days = getDaysInMonth(monthNumerical - 1, year, false);
            for (let i = 0; i < days.length; i++) {
                daysCols.push(getVerbalColumnIndex(colIForDays, columnIndex, 2));
                monthsCols.push(getVerbalColumnIndex(colIForDays, columnIndex, 1));
                widths.push({ width: 4 });
                const day = days[i];
                if (i === 0) {
                    headersLvl1.push(monthVerbal + ' ' + year);
                } else {
                    headersLvl1.push('');
                }
                headersLvl2.push(day);
                columnIndex++;
            }
            merges.push({ s: { r: 0, c: start }, e: { r: 0, c: headersLvl2.length - 1 } })
        }
        merges.push({ s: { r: 2, c: headersLvl3.length }, e: { r: 2, c: headersLvl2.length - 1 } })

        //Fills rows
        let rows = [headersLvl1, headersLvl2, headersLvl3];
        let scheduleFilter = scheduleFilterFromDropdownAsString || '';
        if (!scheduleFilterFromDropdownAsString && scheduleIds && scheduleIds.length) {
            scheduleIds.forEach(sId => scheduleFilter += `&schedule[]=${sId}`)
        }
        for (let i = 0; i < scheduleIds.length; i++) {
            const scheduleId = scheduleIds[i];
            let rowsDic = {};
            for (const key of sortedKeysByMonth) {
                const keyTokens = key.split('-');
                const year = Number(keyTokens[0]);
                const monthVerbal = keyTokens[1];
                const monthNumerical = monthsDic[monthVerbal];
                const days = getDaysInMonth(monthNumerical - 1, year, true, '');
                const data = await ScheduleService.getMySchedules(year, monthNumerical, filter, scheduleFilter);
                for (const schedule of data) {
                    if (Number(scheduleId) === schedule.id) {
                        for (const member of schedule.members) {
                            if (!rowsDic[member.id]) {
                                let row = [
                                    member.id,
                                    `${member.firstName} ${member.lastName}`,
                                    schedule.name,
                                    `${schedule.shiftStart} - ${schedule.shiftEnd} ${schedule.shiftEndTime}`,
                                    schedule.office,
                                    member.homeOffice || ',,,',
                                ];
                                rowsDic[member.id] = row;
                            }
                            const tempDays = [...days];
                            const nationalHolidayWithStatusDeletedOrDeclined: any = {};
                            const nationalHolidays: any = {};
                            for (const vacation of member.vacations) {
                                const days = getDaysBetweenTwoDates(vacation.fromDate, vacation.toDate, vacation.category, vacation.status, vacation.id, true);
                                //@ts-ignore
                                for (const day of days) {
                                    const dateOfTheDay = new Date(day);
                                    const dayIndex = dateOfTheDay.getDate() - 1;
                                    const monthIndex = dateOfTheDay.getMonth() + 1;
                                    if (dayIndex > tempDays.length || monthIndex !== monthNumerical) {
                                        continue;
                                    } else {
                                        if ((vacation.status === 3 || vacation.status === 4) && vacation.category === 3) {
                                            nationalHolidayWithStatusDeletedOrDeclined[day] = true;
                                        }
                                        if ((tempDays[dayIndex] === 9 || !tempDays[dayIndex].length) && (vacation.status === 1 || vacation.status === 2)) {
                                            if (vacation.category === 3) {
                                                tempDays[dayIndex] = `${vacation.status}${vacationTypes.vacationAlias[vacation.status][vacation.category]}`;
                                                nationalHolidays[day] = true;
                                            } else {
                                                tempDays[dayIndex] = vacationTypes.vacationAlias[vacation.status][vacation.category];
                                            }
                                        }
                                    }
                                }
                            }
                            for (const date of Object.keys(nationalHolidays)) {
                                if (nationalHolidayWithStatusDeletedOrDeclined[date]) {
                                    delete nationalHolidayWithStatusDeletedOrDeclined[date];
                                }
                            }
                            for (const date of Object.keys(nationalHolidayWithStatusDeletedOrDeclined)) {
                                const dayIndex = new Date(date).getDate() - 1;
                                tempDays[dayIndex] = ''
                            }
                            for (const day of member.days) {
                                const dayIndex = new Date(day.date).getDate() - 1;
                                if (tempDays[dayIndex] === 9 || !tempDays[dayIndex].length) {
                                    tempDays[dayIndex] = day.dayTypeId;
                                    if (day.note && day.note !== 'NULL') {
                                        if (comments[`${member.id}-${schedule.name}`]) {
                                            comments[`${member.id}-${schedule.name}`].push({ colI: rowsDic[member.id].length + dayIndex, comment: day.note });
                                        } else {
                                            comments[`${member.id}-${schedule.name}`] = [{ colI: rowsDic[member.id].length + dayIndex, comment: day.note }];
                                        }
                                    }
                                }
                            }
                            rowsDic[member.id] = [...rowsDic[member.id], ...tempDays];
                        }
                    }
                }
            }
            //@ts-ignore
            rows = [...rows, ...Object.values(rowsDic)];
            const emptyValueArr: string[] = [];
            rows[rows.length - 1].forEach((col, i) => i < 6 ? emptyValueArr.push(',,,') : '');
            rows.push(emptyValueArr);
        }

        const file = XLSX.utils.book_new();
        const sheet = XLSX.utils.aoa_to_sheet(rows);
        //@ts-ignore
        sheet["!merges"] = merges;
        sheet['!cols'] = widths;
        sheet['!rows'] = [{ hpt: 30 }];
        titleCols.forEach(col => sheet[col].s = generateTitleCellStyling());
        daysCols.forEach(col => sheet[col].s = generateTitleCellStyling());
        monthsCols.forEach(col => sheet[col].s = generateTitleCellStyling());

        //Adds styling to every day cell
        let rowI = 4;
        let colI = 0;
        let sheetKey = getVerbalColumnIndex(colIForDays, colI, rowI);
        while (sheet[sheetKey]) {
            sheet[sheetKey].s = generateTitleCellStyling();
            if (vacationTypes.vacationColors[sheet[sheetKey].v]) {
                sheet[sheetKey].s.fill = {
                    patternType: 'solid',
                    fgColor: { rgb: vacationTypes.vacationColors[sheet[sheetKey].v] }
                };
                if (sheet[sheetKey].v === '2N' || sheet[sheetKey].v === '1N') {
                    sheet[sheetKey].v = 'N';
                }
            } else if (!isNaN(Number(sheet[sheetKey].v)) && Number(sheet[sheetKey].v) > 0) {
                const dayLabel = $dayTypesById.value[sheet[sheetKey].v];
                const dayType = $customDayTypes.value[dayLabel];
                sheet[sheetKey].v = dayType.label === 'E' || dayType.label === 'EW' ? '' : dayType.label;
                sheet[sheetKey].s.fill = {
                    patternType: 'solid',
                    fgColor: { rgb: dayType.color }
                };
            }

            colI++;
            sheetKey = getVerbalColumnIndex(colIForDays, colI, rowI);
            if (!sheet[sheetKey]) {
                rowI++;
                colI = 0;
                sheetKey = getVerbalColumnIndex(colIForDays, colI, rowI);
            }
            if (!sheet[sheetKey]) {
                rowI++;
                colI = 0;
                sheetKey = getVerbalColumnIndex(colIForDays, colI, rowI);
            }
        }

        //Adds styling to every cell from A3:F3
        for (let i = 0; i < 6; i++) {
            rowI = 4;
            sheetKey = getVerbalColumnIndex(colIForStart, i, rowI);
            while (sheet[sheetKey]) {
                const scheduleColumnSheetKey = getVerbalColumnIndex(colIForStart, 2, rowI);
                const value = sheet[sheetKey].v;
                const scheduleName = sheet[scheduleColumnSheetKey].v;
                if (comments[`${value}-${scheduleName}`]) {
                    for (const comment of comments[`${value}-${scheduleName}`]) {
                        const cellForComment = getVerbalColumnIndex(colIForStart, comment.colI, rowI);
                        const cell = sheet[cellForComment];
                        if (!cell.c) cell.c = [];
                        cell.c.hidden = true;
                        cell.c.push({ a: "Schedule", t: comment.comment });
                    }
                }
                sheet[sheetKey].s = centerTextInCell();
                if (value === ',,,') {
                    sheet[sheetKey].v = '';
                }

                rowI++
                sheetKey = getVerbalColumnIndex(colIForStart, i, rowI);
                if (!sheet[sheetKey]) {
                    rowI++;
                    sheetKey = getVerbalColumnIndex(colIForStart, i, rowI);
                }
            }
        }

        sheet['!autofilter'] = { ref: 'A3:F3' };
        file.SheetNames.push('Schedule');
        file.Sheets['Schedule'] = sheet;
        XLSX.writeFile(file, `schedule.xlsx`);
        $loading.set(false);
        onClose();
    }

    function onClone() {
        const daysForCloning: Array<any> = [];
        const yearsAndMonths = Object.keys($selectedMonths.value)

        for (const yearAndMonth of yearsAndMonths) {
            const yearAndMonthTokens = yearAndMonth.split('-');
            const year = Number(yearAndMonthTokens[0]);
            const month = monthsDic[yearAndMonthTokens[1]]

            for (const memberDay of $allMemberDays.value) {
                if (memberDay.dayTypeId === 8 || memberDay.dayTypeId === 9) {
                    continue;
                } else {
                    const date = new Date(memberDay.date);
                    if (isValidDate(date.getDate(), month, year)) {
                        daysForCloning.push({
                            year: year,
                            month: month,
                            day: date.getDate(),
                            dayTypeId: memberDay.dayTypeId,
                            note: null,
                            userId: memberDay.userId
                        })
                    }
                }
            }
        }
        if (daysForCloning.length && scheduleForEdit.id) {
            ScheduleService.cloneSchedule(daysForCloning, scheduleForEdit.id, yearsAndMonths)
                .then(res => {
                    $loading.set(false);
                    onClose();
                })
                .catch(err => {
                    $error.set(err.message);
                    $loading.set(false);
                    onClose();
                    setTimeout(() => $error.set(''), 5000)
                });
        } else {
            onClose();
        }
    }

    function selectMonth(e) {
        if (!$exporting && e.target.textContent === $thisMonth.value && $year.value.getFullYear() === $thisYear.value) {
            return;
        }
        if ($selectedMonths.value[`${$year.value.getFullYear()}-${e.target.textContent}`]) {
            delete $selectedMonths.value[`${$year.value.getFullYear()}-${e.target.textContent}`];
            $selectedMonths.set({ ...$selectedMonths.value });
        } else {
            if (Object.keys($selectedMonths.value).length === 12 && $exporting) {
                return;
            }
            $selectedMonths.value[`${$year.value.getFullYear()}-${e.target.textContent}`] = true;
            $selectedMonths.set({ ...$selectedMonths.value });
        }
    }

    return (
        <div className={classes.selectMonthAndYear}>
            <div className={classes.blueBox}>
                <div className={classes.blueBoxWrapper}>
                    {$exporting ?
                        <div style={{ textAlign: 'center' }}>For which month / s do you want<br />to make the export for?</div>
                        :
                        <div style={{ textAlign: 'center' }}>Cloning Schedule: {scheduleForEdit?.name}<br />to the following months</div>
                    }
                </div>
            </div>
            <div className={classes.changeYear}>
                <IconButton style={{ marginLeft: '25px' }} onClick={() => $year.set(subYears($year.value, 1))}>
                    <ArrowBackIosIcon />
                </IconButton>
                <div className={clsx(classes.changeYearRowWrapper, classes.yearLabel)}><div>{$year.value.getFullYear()}</div></div>
                <IconButton style={{ marginRight: '25px' }} onClick={() => $year.set(addYears($year.value, 1))}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </div>
            <div className={classes.changeYear}>
                <div>
                    <div className={classes.changeYearRowWrapper}>
                        <div className={classes.changeYearRow}>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-January`] ?
                                        classes.selectedMonth : null,
                                    'January' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>January</div>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-February`] ?
                                        classes.selectedMonth : null,
                                    'February' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>February</div>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-March`] ?
                                        classes.selectedMonth : null,
                                    'March' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>March</div>
                        </div>
                    </div>
                    <div className={classes.changeYearRowWrapper}>
                        <div className={classes.changeYearRow}>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-April`]
                                        ? classes.selectedMonth : null,
                                    'April' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>April</div>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-May`] ?
                                        classes.selectedMonth : null,
                                    'May' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>May</div>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-June`] ?
                                        classes.selectedMonth : null,
                                    'June' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>June</div>
                        </div>
                    </div>
                    <div className={classes.changeYearRowWrapper}>
                        <div className={classes.changeYearRow}>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-July`] ?
                                        classes.selectedMonth : null,
                                    'July' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>July</div>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-August`] ?
                                        classes.selectedMonth : null,
                                    'August' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>August</div>
                            <div
                                onClick={selectMonth}
                                className={clsx(classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-September`] ?
                                        classes.selectedMonth : null,
                                    'September' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>September</div>
                        </div>
                    </div>
                    <div className={classes.changeYearRowWrapper}>
                        <div className={classes.changeYearRow}>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-October`] ?
                                        classes.selectedMonth : null,
                                    'October' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>October</div>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-November`] ?
                                        classes.selectedMonth : null,
                                    'November' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>November</div>
                            <div
                                onClick={selectMonth}
                                className={clsx(
                                    classes.monthLabel,
                                    $selectedMonths.value[`${$year.value.getFullYear()}-December`] ?
                                        classes.selectedMonth : null,
                                    'December' === $thisMonth.value && !$exporting ?
                                        classes.isThisMonth : null
                                )}>December</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={clsx(classes.spaceBetween, classes.marginBottomButtons)}>
                {$exporting ?
                    <div>Selected - {Object.keys($selectedMonths.value).length}/12</div>
                    :
                    <div />
                }
                <div className={classes.approveAndCancel}>
                    <BrandButton onClick={onClose}>Cancel</BrandButton>
                    {$exporting ?
                        <BrandButton
                            color="primary"
                            disabled={$loading.value || !Object.keys($selectedMonths.value).length}
                            onClick={onExport}
                        >
                            {$loading.value ?
                                <LoadingIndicator />
                                :
                                'Export'
                            }
                        </BrandButton>
                        :
                        <BrandButton color="primary" disabled={$loading.value} onClick={onClone}>
                            {$loading.value ?
                                <LoadingIndicator />
                                :
                                'Clone'
                            }
                        </BrandButton>
                    }
                </div>
            </div>
        </div >
    );
}

const NoteModal = ({ onClose, $noteModalInfo, $loading, $error, $schedules, $deleteNote }) => {
    const classes = useStyles();
    const $note = useHook('');
    const $editing = useHook(false);

    useEffect(() => {
        $editing.set(Boolean($noteModalInfo.value.note))
        $note.set($noteModalInfo.value.note);
    }, [$noteModalInfo.value])

    function takeActionNote(isDelete = false) {
        $loading.set(true);
        const dayTypeDto: DayTypeDto = {
            year: $noteModalInfo.value.year,
            month: $noteModalInfo.value.month,
            day: $noteModalInfo.value.day,
            note: isDelete ? '' : $note.value,
            dayTypeId: $noteModalInfo.value.dayTypeId
        };
        ScheduleService.updateScheduleDay(dayTypeDto, $noteModalInfo.value.scheduleId, $noteModalInfo.value.userId)
            .then(res => {
                const tempSchedules = [...$schedules.value]
                const date = parseDate(new Date(dayTypeDto.year, dayTypeDto.month - 1, dayTypeDto.day), '-');
                const member = tempSchedules[$noteModalInfo.value.scheduleIndex].members[$noteModalInfo.value.memberIndex];
                const foundDay = member.days.find(md => md.date === date);
                if (foundDay) {
                    foundDay.note = $note.value;
                } else {
                    member.days.push({
                        date: date,
                        note: $note.value
                    })
                }
                $schedules.set(tempSchedules)
                onClose();
                $loading.set(false);
                $deleteNote.set(false);
            })
            .catch(err => {
                onClose();
                $error.set(err.message);
                $loading.set(false);
                $deleteNote.set(false);
                setTimeout(() => $error.set(''), 5000)
            });
    }

    return (
        $deleteNote.value ?
            <div className={classes.deleteSchedule}>
                <Typography style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 16 }} variant='h6' component="div">
                    Are you sure you want to delete<br />this note?
                </Typography>
                <div className={clsx(classes.approveAndCancel, classes.deleteScheduleApproveAndCancel)}>
                    <BrandButton onClick={() => $deleteNote.set(false)}>Cancel</BrandButton>
                    <BrandButton color="secondary" onClick={() => takeActionNote(true)}>
                        Yes
                    </BrandButton>
                </div>
            </div >
            :
            <div className={classes.noteContainer}>
                <div className={classes.noteTopRow}>
                    <div className={classes.noteAvatar}>
                        <div>
                            <Avatar className={$noteModalInfo.value.member.gender === 1 ? classes.maleAvatar : classes.femaleAvatar}>
                                {$noteModalInfo.value.member.firstName.charAt(0) + $noteModalInfo.value.member.lastName.charAt(0)}
                            </Avatar>
                        </div>
                        <div style={{ minWidth: '115px' }}>
                            <div className={clsx(classes.boldLabel, classes.fullNameContainer, classes.fontSize14)}>
                                <div>{$noteModalInfo.value.member.firstName}</div>&nbsp;<div>{$noteModalInfo.value.member.lastName}</div>
                            </div>
                            <div className={clsx(classes.employeeIdLabel, classes.fontSize12)}>{$noteModalInfo.value.member.jobPosition}</div>
                        </div>
                    </div>
                    <div>
                        <div className={clsx(classes.boldLabel, classes.fontSize14)}>ID</div>
                        <div className={classes.fontSize12}>ID-{$noteModalInfo.value.member.id}</div>
                    </div>
                    <div>
                        <div className={clsx(classes.boldLabel, classes.fontSize14)}>Department</div>
                        <div className={classes.fontSize12}>{$noteModalInfo.value.member.department}</div>
                    </div>
                    <div>
                        <div className={clsx(classes.boldLabel, classes.fontSize14)}>Shift</div>
                        <div style={{ minWidth: '53px' }} className={classes.fontSize12}>{$noteModalInfo.value.shiftStart}</div>
                        <div style={{ minWidth: '53px' }} className={classes.fontSize12}>{$noteModalInfo.value.shiftEnd}</div>
                    </div>
                    <div>
                        <div className={clsx(classes.boldLabel, classes.fontSize14)}>Schedule</div>
                        <div className={classes.fontSize12}>{$noteModalInfo.value.scheduleName}</div>
                    </div>
                </div>
                <div className={clsx(classes.row, classes.noteMargin)}>
                    <div className={clsx(classes.boldLabel, classes.fontSize14)}>Note</div>
                    <div className={classes.fontSize12}>{format(new Date($noteModalInfo.value.year, $noteModalInfo.value.month - 1, $noteModalInfo.value.day), 'dd.MM.y')}</div>
                </div>
                <Input
                    disabled={!$noteModalInfo.value.isAdminOrViewOnlyAdmin}
                    className={clsx(classes.col, classes.secondColumn, classes.noteInput)}
                    multiline
                    rows={15}
                    $value={$note}
                />
                <div className={clsx(classes.spaceBetween, classes.marginBottomButtons, classes.scheduleActionsMarginBottom)}>
                    {$editing.value && $noteModalInfo.value.isAdminOrViewOnlyAdmin ?
                        <BrandButton color='secondary' onClick={() => $deleteNote.set(true)}>Delete</BrandButton>
                        :
                        <div />
                    }
                    <div className={classes.approveAndCancel}>
                        <BrandButton onClick={onClose}>{$noteModalInfo.value.isAdminOrViewOnlyAdmin ? 'Cancel' : 'Close'}</BrandButton>
                        {$noteModalInfo.value.isAdminOrViewOnlyAdmin ?
                            <BrandButton
                                color='primary'
                                onClick={() => takeActionNote()}
                            >
                                {$editing.value ? 'Edit' : 'Create'}
                            </BrandButton>
                            :
                            <div />}
                    </div>
                </div>
            </div>
    );
}

const CopyMemberSchedulePopup = ({ $copyMemberScheduleData, $schedules, onClose, $loading, $error, onRefresh, $dateFilter, $currentUser }) => {
    const classes = useStyles();
    const $selectedSchedules = useHook({});

    const handleToggleCheckbox = (schedule) => {
        if ($selectedSchedules.value[schedule.id]) {
            delete $selectedSchedules.value[schedule.id];
        } else {
            $selectedSchedules.value[schedule.id] = true;
        }
        $selectedSchedules.set({ ...$selectedSchedules.value });
    };

    const copySelectedSchedules = async () => {
        $loading.set(true);
        const fromScheduleId = $copyMemberScheduleData.value.fromSchedule.id;
        const toScheduleIds = Object.keys($selectedSchedules.value).map(s => Number(s));
        const month = $dateFilter.value.getMonth() + 1;
        const year = $dateFilter.value.getFullYear();

        ScheduleService.copyMemberSchedule(fromScheduleId, toScheduleIds, $copyMemberScheduleData.value.member.id, year, month)
            .then(() => {
                onClose();
                onRefresh();
                $loading.set(false);
            })
            .catch(err => {
                onClose();
                $error.set(err.message);
                $loading.set(false);
                setTimeout(() => $error.set(''), 3000)
            })
    };

    return (
        <div className={classes.noteContainer}>
            <div className={classes.noteTopRow}>
                <div className={classes.noteAvatar}>
                    <div>
                        <Avatar className={$copyMemberScheduleData.value.member.gender === 1 ? classes.maleAvatar : classes.femaleAvatar}>
                            {$copyMemberScheduleData.value.member.firstName.charAt(0) + $copyMemberScheduleData.value.member.lastName.charAt(0)}
                        </Avatar>
                    </div>
                    <div style={{ minWidth: '115px' }}>
                        <div className={clsx(classes.boldLabel, classes.fullNameContainer, classes.fontSize14)}>
                            <div>{$copyMemberScheduleData.value.member.firstName}</div>&nbsp;<div>{$copyMemberScheduleData.value.member.lastName}</div>
                        </div>
                        <div className={clsx(classes.employeeIdLabel, classes.fontSize12)}>{$copyMemberScheduleData.value.member.jobPosition}</div>
                    </div>
                </div>
                <div>
                    <div className={clsx(classes.boldLabel, classes.fontSize14)}>ID</div>
                    <div className={classes.fontSize12}>ID-{$copyMemberScheduleData.value.member.id}</div>
                </div>
                <div>
                    <div className={clsx(classes.boldLabel, classes.fontSize14)}>Department</div>
                    <div className={classes.fontSize12}>{$copyMemberScheduleData.value.member.department}</div>
                </div>
            </div>
            <div className={classes.fromAndToSchedulesContainer}>
                <div>
                    <div className={clsx(classes.boldLabel, classes.fontSize14, classes.alignBottomLabel)}>Copying data from:</div>
                    <div>{$copyMemberScheduleData.value.fromSchedule.name}</div>
                </div>
                <div>
                    <div className={clsx(classes.boldLabel, classes.fontSize14)}>To:</div>
                    <List dense className={classes.positionSchedulesForCopying}>
                        {$schedules.value.filter(s => {
                            return s.members
                                && s.members.length
                                && s.members.find(m => m.id === $copyMemberScheduleData.value.member.id)
                                && s.id !== $copyMemberScheduleData.value.fromSchedule.id
                        }).map((schedule) => {
                            if (schedule.members.find(m => (m.membershipType === 2 || m.membershipType === 3) && m.id === $currentUser.value.id)) {
                                return (
                                    <ListItem key={schedule.id}>
                                        <ListItemIcon>
                                            <Checkbox
                                                color="primary"
                                                edge="start"
                                                disableRipple
                                                onChange={() => handleToggleCheckbox(schedule)}
                                                checked={Boolean($selectedSchedules.value[schedule.id])}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={schedule.name} />
                                    </ListItem>
                                );
                            }

                            return null;
                        })}
                    </List>
                </div>
            </div>
            <div className={clsx(classes.spaceBetween, classes.marginBottomButtons, classes.scheduleActionsMarginBottom, classes.alignCopyMemberSchedulePopupActions)}>
                <div className={classes.approveAndCancel}>
                    <BrandButton onClick={onClose}>
                        Cancel
                    </BrandButton>
                    <BrandButton color='primary' onClick={copySelectedSchedules}>
                        Done
                    </BrandButton>
                </div>
            </div>
        </div>
    );
}
