import React, { forwardRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Hook, useForceValidateContext, useHook, useReadOnlyContext } from './Utils';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
    input: {
        '& .MuiInputLabel-asterisk': {
            color: 'red'
        }
    }
}));

// https://reactjs.org/docs/strict-mode.html#warning-about-deprecated-finddomnode-usage
export const BrandMenuItem = forwardRef((props: any, ref: any) =>
    <MenuItem innerRef={ref} {...props} />)

export interface BrandSelectProps extends SelectProps {
    $value?: Hook<any>;
    selectProps?: any;
    readOnly?: boolean;
    size?: 'small' | 'medium';
    onChange?: (
      event: React.ChangeEvent<{ name?: string; value: any }>,
      child: React.ReactNode
    ) => void;
}

export const BrandSelect = ({ $value, readOnly, ...props } : BrandSelectProps) => {
    const $firstFocus = useHook(true);
    const readOnlyContext = useReadOnlyContext();
    const forceValidateContext = useForceValidateContext();
    const classes = useStyles();

    let errors: any = undefined;
    if (!$firstFocus.value || forceValidateContext) {
        errors = $value?.validator ? $value.validator($value.value) : undefined;
    }

    return <FormControl
            className={props.className}
            classes={{ root: classes.input }}
            variant="outlined"
            error={Boolean(errors && errors.length)}
            size={props.size}
        >
            {props.label ?
                <InputLabel id={`${props.id}-label`} required={props.required}>{props.label}</InputLabel>
            : null}
            <Select
                onBlur={() => $firstFocus.set(false)}
                labelId={`${props.id}-label`}
                id={props.id}
                value={$value?.value == null ? props.value : $value?.value}
                onChange={(e, v) => {
                    $value?.set(e.target.value);
                    if (props.onChange) {
                        props.onChange(e, v);
                    }
                }}
                label={props.label}
                disabled={readOnly != null ? readOnly : readOnlyContext}
                {...props.selectProps}
            >
                {props.children}
            </Select>
            {errors?.length ?
                <FormHelperText>
                    {errors?.join('\n')}
                </FormHelperText>
            : null}
        </FormControl>
}
