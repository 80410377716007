
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LastReadNotificationHook } from '../Services/notification.service';
import { User } from '../Services/user.service';

type Validator<T> = (value: T) => string[] | undefined;
type ObjectErrorResult = { [propName: string]: string[] } | undefined;

export interface Hook<T> {
    value: T;
    set: (newValue: T) => void;
    validator?: Validator<T>;
}


export function useHook<T>(value: T, validator?: Validator<T>): Hook<T> {
    const [val, setter] = useState(value);
    return {
        value: val,
        set: setter,
        validator: validator,
    }
}

export interface ObjectHook<T> extends Omit<Hook<T>, 'validator'> {
    getPropHook: <K extends keyof T>(propName: K) => Hook<T[K]>;
    validator: (value: T) => ObjectErrorResult;
}

type ValidatorObject = { [key: string]: Validator<any>[] };

export function useObjectHook<T>(value: T, validators?: ValidatorObject, objectValidator?: (val: T) => ObjectErrorResult): ObjectHook<T> {
    const [obj, setter] = useState(value);
    return {
        value: obj,
        set: setter,
        getPropHook: (propName) => {
            return {
                value: obj[propName],
                set: (v) => {
                    setter({ ...obj, [propName]: v });
                },
                validator: (val): string[] | undefined => {
                    if (objectValidator) {
                        const err = objectValidator(obj) as any;
                        if (err && err[propName]) {
                            return err[propName];
                        }
                    }

                    if (!validators || !validators[propName as any]) {
                        return undefined;
                    }

                    const errors: string[] = [];
                    for (const validator of validators[propName as any]) {
                        const err = validator(val);
                        if (err) {
                            errors.push(...err);
                        }
                    }

                    return errors.length ? errors : undefined;
                }
            }
        },
        validator: (val): { [propName: string]: string[] } | undefined => {
            const result = {};
            let hasErrors = false;

            if (validators) {
                for (const propName in validators) {
                    const errors: string[] = [];
                    for (const validator of validators[propName]) {
                        const err = validator(val[propName]);
                        if (err) {
                            errors.push(...err);
                        }
                    }

                    if (errors.length) {
                        result[propName] = errors;
                        hasErrors = true;
                    }
                }
            }

            if (objectValidator) {
                const err = objectValidator(val);
                if (err) {
                    for (const propName in err) {
                        if (result[propName]) {
                            result[propName] = err[propName].concat(result[propName]);
                        } else {
                            result[propName] = err[propName];
                        }
                    }

                    hasErrors = true;
                }
            }

            return hasErrors ? result : undefined;
        }
    }
}

function paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }

    return result;
}

export function useQuery(): any {
    const entries = (new URLSearchParams(useLocation().search)).entries();
    return paramsToObject(entries);
}

export const ReadOnlyContext = React.createContext(false);
export const useReadOnlyContext = () => React.useContext(ReadOnlyContext);

export const ForceValidateContext = React.createContext(false);
export const useForceValidateContext = () => React.useContext(ForceValidateContext);

export interface FilterState {
    pageId: string;
    data: any;
}

export const StoreContext = React.createContext<{
    $currentUser: Hook<User>,
    $selectedUser: Hook<User>,
    $blockNotificationCounterUpdate: Hook<boolean>,
    $missedNotificationsCount: Hook<number>,
    $lastReadNotificationDate: LastReadNotificationHook,
    $filters: Hook<FilterState>
}>({} as any);
export const useStoreContext = () => React.useContext(StoreContext);

export function areFieldsValid(obj, validators, filteredProperties?: string[] | null, excludedProperties?: string[]): boolean {
    for (const k in validators) {
        if (filteredProperties && !filteredProperties.includes(k)) {
            continue;
        }

        if (excludedProperties && excludedProperties.includes(k)) {
            continue;
        }

        if (!obj.hasOwnProperty(k)) {
            console.warn(`validation error missing object property (${k})`);
            // return false;
            continue;
        }

        for (const validator of validators[k]) {
            const err = validator(obj[k]);
            if (err && err.length) {
                console.log('validation err', k, err)
                return false;
            }
        }
    }

    return true;
}

export function requiredValidator(value): string[] {
    return value == null || value === '' ? ['Field is required'] : [];
}

export function maxLengthValidator(maxLength) {
    return function (value): string[] {
        return value == null || value.length < maxLength ? [] : [`Max length is ${maxLength}`];
    }
}

export function emailValidator(email): string[] {
    const format = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return format.test(email) ? [] : ['Invalid format'];
}

export function passwordValidator(password): string[] {
    const letterOrDigit = /^[a-zA-Z-0-9]*$/;
    const lowerCaseLetter = /[a-z]/;
    const upperCaseLetter = /[A-Z]/;
    const number = /[0-9]/;
    const minLength = 6;

    const errors: string[] = [];

    if (password.length < minLength) {
        errors.push(`Password must be at least ${minLength} symbols`);
    }

    if (!number.test(password)) {
        errors.push('Password must have at least one number');
    }

    if (!lowerCaseLetter.test(password)) {
        errors.push('Password must have at least one lowercase character');
    }

    if (!upperCaseLetter.test(password)) {
        errors.push('Password must have at least one uppercase character');
    }

    if (letterOrDigit.test(password)) {
        errors.push('Password must have at least one special character');
    }

    return errors;
}

export function getDatesInRange(startDate, endDate): any[] {
    const date = new Date(startDate.getTime());

    const dates: any[] = [];

    while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}
