import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
    card: {
        // add BrandCard component?
        borderRadius: theme.spacing(1.8),
        boxShadow: `${theme.spacing(0, 1, 1)} rgba(0, 0, 0, 0.25)`,
        color: 'inherit',
    },
}));

export const BrandCard = props => {
    const classes = useStyles();
    return <Card classes={{ root: classes.card }} {...props} />
}
