import { startOfDay } from "date-fns";
import { config } from "../config";
import { getData, postData } from "./utils";

export interface ResetDays {
    nationalHolidays: Date[];
}

export interface NationalHoliday {
    id: number;
    active: boolean;
    calendarDate: Date;
}

export namespace AdminService {

    export function resetDays(resetDays: ResetDays): Promise<any> {
        return postData(config.API_BASE_URL + '/admin/reset-days', resetDays);
    }

    export async function nationalHolidays(): Promise<NationalHoliday[]> {
        return getData(config.API_BASE_URL + '/admin/national-holidays')
            .then(data => data.map(x => ({...x, calendarDate: startOfDay(new Date(x.calendarDate))})));
    }

}
