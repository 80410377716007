import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    default: {
        borderRadius: theme.spacing(1),
        '& .MuiButton-label': {
            fontWeight: 'bold',
        },
        height: theme.spacing(5),
    }
}));

export const BrandButton = ({ className, ...props } : any) => {
    const classes = useStyles();
    return <Button
        className={clsx(classes.default, className)}
        variant="contained"
        {...props}
    />
}
