import React, { useRef, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import makeStyles from "@material-ui/core/styles/makeStyles";
import TodayIcon from '@material-ui/icons/Today';
import clsx from "clsx";
import {
    addDays,
    addMonths,
    endOfWeek,
    format,
    isAfter,
    isBefore,
    isFirstDayOfMonth,
    isSameDay,
    isSameMonth,
    isToday,
    isWeekend,
    isWithinInterval,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subDays,
    subMonths,
    getWeek
} from "date-fns";
import { LeaveRequest, LeaveStatus } from "../Services/vacation.service";
import { Input } from "./Input";
import { useForceValidateContext, useHook } from "./Utils";
import Popover from "@material-ui/core/Popover";
import { NationalHoliday } from "../Services/admin.service";
import { CategoryService, LeaveCategory } from "../Services/category.service";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
    calendarDayOfWeekLabel: {
        width: theme.spacing(5),
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    calendarDates: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: theme.spacing(5 * 8),
    },
    tooltip: {
        color: '#444',
        backgroundColor: 'white',
        border: '1px solid #888',
        borderRadius: '2px',
        fontSize: theme.typography.fontSize,
        padding: '2px 5px',
    },
    calendarTile: {
        display: 'flex',
        width: theme.spacing(5),
        height: theme.spacing(4.5),
        textAlign: 'center',
        boxSizing: 'border-box',
        border: 0,
        background: 0,
        '&.week-number': {
            // color: '#ffb800',
            color: '#ff6073',
            fontWeight: 'bold',
        },

        '&.weekend': {
            color: '#f50057',
            backgroundColor: '#eaeaea',
        },
        '&.muted': {
            color: '#757575',
            backgroundColor: '#ffffff',
        },
        '&.muted.weekend': {
            color: '#f73378',
            backgroundColor: '#eeeeee',
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#e6e6e6',
        },

        '&.selectedDate > abbr': {
            borderRadius: '50%',
            background: '#2196f3',
            color: '#ffffff',
            width: theme.spacing(3),
            height: theme.spacing(3),
            minWidth: theme.spacing(3),
            minHeight: theme.spacing(3),
            lineHeight: '1.8em',
            // textAlign: 'center',
            // display: 'flex',
            // flexDirection: 'column',
            // justifyContent:  'center',
        },
        '&.currentDate': {
            border: '4px solid #00bbf9',
        },
        '&.national-holiday-date': {
            border: '4px solid #00b828',
        },
        '&.currentDate.national-holiday-date': {
            borderTop: '4px solid #00bbf9',
            borderLeft: '4px solid #00bbf9',
            borderRight: '4px solid #00b828',
            borderBottom: '4px solid #00b828',
        },

        '&.pending-national-holiday': {
            backgroundColor: 'rgba(244, 162, 97,1 ) !important',
        },
        '&.national-holiday': {
            backgroundColor: '#00b828 !important',
        },
        '&.pending-unpaid-leave': {
            backgroundColor: 'rgba(236, 188, 253, 1) !important',
        },
        '&.unpaid-leave': {
            backgroundColor: 'rgba(155, 93, 229, 1) !important',
        },
        '&.pending-paid-leave': {
            backgroundColor: 'rgba(255, 184, 0, 0.5) !important',
        },
        '&.paid-leave': {
            backgroundColor: 'rgba(0, 184, 40, 0.38) !important',
        },
        '&.sick-leave': {
            backgroundColor: 'rgba(242, 132, 130, 1) !important',
        },
        '&.pending-half-day-leave': {
            backgroundImage: 'linear-gradient(90deg, rgba(255, 184, 0, 0.5) 50%, rgba(255, 255, 255, 0) 50%) !important',
        },
        '&.half-day-leave': {
            backgroundImage: 'linear-gradient(90deg, rgba(0, 184, 40, 0.38) 50%, rgba(255, 255, 255, 0) 50%) !important',
        },
        '&.other-non-deductible': {
            backgroundColor: 'rgba(141, 153, 174, 1) !important',
        },
        '&.pending-added-day': {
            backgroundColor: 'rgba(221, 190, 169, 1) !important',
        },
        '&.added-day': {
            backgroundColor: 'rgba(176, 137, 104, 1) !important',
        },

        '&.between': {
            backgroundColor: 'rgb(216, 160, 7)',
        },
        '&.start': {
            borderRadius: '50% 0 0 50%',
            backgroundColor: 'rgb(216, 160, 7)',
        },
        '&.end': {
            borderRadius: '0 50% 50% 0',
            backgroundColor: 'rgb(216, 160, 7)',
        },
    },
    calendarTileText: {
        margin: 'auto',
    },

    calendarDays: {},
    calendarDaysAsRow: {
        display: 'inline-flex',
    },
    rowDay: {
        width: theme.spacing(5),
    },
}));

const Day = ({ currentDate, date, minDate, maxDate, selectedDate, startDate, endDate, CustomDateTooltip, CustomDateRender, CustomDateProps, onClick }) => {
    const classes = useStyles();
    const className: string[] = [];

    if (isWeekend(date)) {
        className.push('weekend');
    }

    if (isToday(date)) {
        className.push('currentDate');
        // className.push('national-holiday-date');
    }

    if (selectedDate && isSameDay(date, selectedDate)) {
        className.push('selectedDate');
    }

    // if (isSameDay(date, startDate)) {
    //     className.push('start');
    // }

    // if (startDate && endDate && isWithinInterval(date, { start: startDate, end: endDate })) {
    //     className.push('between');
    // }

    // if (isSameDay(date, endDate)) {
    //     className.push('end');
    // }

    if (!isSameMonth(date, currentDate)) {
        className.push('muted');
    }

    const tooltip = CustomDateTooltip ? CustomDateTooltip(date) : undefined;
    return (
        <Tooltip
            disableFocusListener={!tooltip}
            disableHoverListener={!tooltip}
            disableTouchListener={!tooltip}
            title={tooltip || ''}
            placement="bottom-start"
            classes={{ tooltip: classes.tooltip }}
        >
            {/* this is the shittiest solution for this problem https://github.com/mui-org/material-ui/issues/8416 */}
            {(minDate && isBefore(date, minDate)) || (maxDate && isAfter(date, maxDate)) ?
                <button className={clsx(
                    'muted',
                    classes.calendarTile,
                    CustomDateTooltip && CustomDateTooltip(date) ? 'tooltip' : '',
                    (CustomDateProps ? CustomDateProps(date) : []).concat(className).join(' ')
                )}>
                    {CustomDateRender ?
                        <CustomDateRender className={classes.calendarTileText} dateProps={className}>
                            {date.getDate()}
                        </CustomDateRender>
                    :
                        <abbr className={classes.calendarTileText}>
                            {date.getDate()}
                        </abbr>
                    }
                </button>
            :
                <button
                    onClick={() => onClick(date)}
                    // currentDate={date}
                    className={clsx(
                        classes.calendarTile,
                        CustomDateTooltip && CustomDateTooltip(date) ? 'tooltip' : '',
                        (CustomDateProps ? CustomDateProps(date) : []).concat(className).join(' ')
                    )}
                >
                    {CustomDateRender ?
                        <CustomDateRender className={classes.calendarTileText} dateProps={className}>
                            {date.getDate()}
                        </CustomDateRender>
                    :
                        <abbr className={classes.calendarTileText}>
                            {date.getDate()}
                        </abbr>
                    }
                </button>
            }
        </Tooltip>
    )
};

const Days = ({ date, startDate, endDate, minDate, maxDate, selectedDate, isRow, CustomDateTooltip, CustomDateRender, CustomDateProps, onClick, ...props }) => {
    const classes = useStyles();
    const firstDayOfTheMonth = startOfMonth(date);
    const nextMonth = startOfMonth(addMonths(date, 1));
    const days: any[] = [];
    const labels: any[] = [];
    const weekStart = { weekStartsOn: 1 } as any;

    const firstDayOfWeek = startOfWeek(date, weekStart);
    if (!isRow) {
        labels.push(
            <div key={-1}
                className={classes.calendarDayOfWeekLabel}
            />
        );
    }
    for (let i = 0; i < 7; i++) {
        const dayOfWeek = format(addDays(firstDayOfWeek, i), 'eee');
        labels.push(
            <div key={dayOfWeek}
                className={classes.calendarDayOfWeekLabel}
            >
                {dayOfWeek}
            </div>
        );
    }

    const DayRender = props =>
        <Day
            CustomDateRender={CustomDateRender}
            CustomDateProps={CustomDateProps}
            CustomDateTooltip={CustomDateTooltip}
            minDate={minDate}
            maxDate={maxDate}
            selectedDate={selectedDate}
            onClick={onClick}
            currentDate={date}
            startDate={startDate}
            endDate={endDate}
            {...props}
        />

    let firstWeek = startOfWeek(firstDayOfTheMonth, weekStart);
    // comment this out if we don't want to show whole week from previous month
    if (!props.onlyCurrentMonth && isFirstDayOfMonth(firstWeek)) {
        firstWeek = subDays(firstWeek, 7);
    }

    let lastWeekNumber = 0;
    for (let i = firstWeek; !isFirstDayOfMonth(i); i = addDays(i, 1)) {
        if (isRow) {
            days.push(null);
            continue;
        }

        const key = format(i, 'dd MM yyyy');
        const currentWeek = getWeek(i, weekStart);
        if (lastWeekNumber !== currentWeek) {
            days.push(
                <div className={clsx(classes.calendarTile, 'week-number')} key={key + '-week'}>
                    <abbr className={classes.calendarTileText}>
                        {currentWeek}
                    </abbr>
                </div>
            );
            lastWeekNumber = currentWeek;
        }

        if (props.onlyCurrentMonth) {
            days.push(<div className={classes.calendarTile} key={key} />)
        } else {
            days.push(
                <DayRender key={key} date={i} />
            );
        }
    }

    for (let i = firstDayOfTheMonth; !isSameDay(i, nextMonth); i = addDays(i, 1)) {
        const key = format(i, 'dd MM yyyy');
        if (!isRow) {
            const currentWeek = getWeek(i, weekStart);
            if (lastWeekNumber !== currentWeek) {
                days.push(
                    <div className={clsx(classes.calendarTile, 'week-number')} key={key + '-week'}>
                        <abbr className={classes.calendarTileText}>
                            {currentWeek}
                        </abbr>
                    </div>
                );
                lastWeekNumber = currentWeek;
            }
        }

        days.push(
            <DayRender key={key} date={i} />
        );
    }

    // uncomment this out if we don't want to show whole week from next month
    if (!isRow && !props.onlyCurrentMonth) { // && isSameMonth(startOfWeek(nextMonth), firstDayOfTheMonth)) {
        const startOfNextWeek = addDays(endOfWeek(nextMonth, weekStart), 1);
        for (let i = nextMonth; !isSameDay(i, startOfNextWeek); i = addDays(i, 1)) {
            const key = format(i, 'dd MM yyyy');
            days.push(
                <DayRender key={key} date={i} />
            );
        }
    }

    return isRow ?
        <div className={classes.calendarDaysAsRow}>
            {days.map((d, i) => d ?
                <div key={i} className={classes.rowDay}>
                    {labels[i % labels.length]}
                    {d}
                </div>
                : null
            )}
        </div>
    :
        <div className={classes.calendarDays}>
            <div className={classes.calendarDates}>
                {labels.concat()}
            </div>
            <div className={classes.calendarDates}>
                {days.concat()}
            </div>
        </div>
};

export interface CalendarProps {
    date?: Date;
    minDate?: Date;
    maxDate?: Date;
    isRow?: boolean;
    showCurrentMonthTitle?: boolean;
    selectedDate?: Date;
    onlyCurrentMonth?: boolean;
    classes?: any;
    CustomDateRender?: any;
    CustomDateProps?: any;
    CustomDateTooltip?: (date: Date) => string | undefined;
    controls?: boolean;
    onChange?: (Date) => void;
    onBlurCapture?: () => void;
    onMonthChange?: (Date) => void;
}

export const BrandCalendar = ({ minDate, maxDate, date, onChange, ...props } : CalendarProps) => {
    const safeDate = startOfDay(date || new Date());
    const $selectedDay = useHook<Date>(safeDate);
    return <div className={props.classes?.root}>
        {props.controls ?
            <>
                <IconButton onClick={() => {
                    const d = subMonths($selectedDay.value, 1);
                    $selectedDay.set(d);
                    if (props.onMonthChange) {
                        props.onMonthChange(d);
                    }
                }}>
                    <ArrowBackIcon />
                </IconButton>
                <IconButton onClick={() => {
                    const d = addMonths($selectedDay.value, 1);
                    $selectedDay.set(d);
                    if (props.onMonthChange) {
                        props.onMonthChange(d);
                    }
                }}>
                    <ArrowForwardIcon />
                </IconButton>
            </>
        : null}
        {props.controls || props.showCurrentMonthTitle ?
            format(props.controls ? $selectedDay.value : safeDate as any, 'MMMM y')
        : null}
        <Days
            onlyCurrentMonth={props.onlyCurrentMonth}
            isRow={props.isRow}
            selectedDate={props.selectedDate}
            CustomDateRender={props.CustomDateRender}
            CustomDateProps={props.CustomDateProps}
            CustomDateTooltip={props.CustomDateTooltip}
            minDate={minDate}
            maxDate={maxDate}
            date={props.controls ? $selectedDay.value : safeDate}
            startDate={addDays(new Date(), 1)}
            endDate={addDays(new Date(), 8)}
            onClick={onChange}
        />
    </div>
}

export interface BrandVacationsCalendarProps extends  Omit<CalendarProps, 'onChange'> {
    requestedLeaves?: LeaveRequest[];
    nationalHolidays?: NationalHoliday[];
    isFromPopup?: boolean;
    onChange?: (date: Date, leaveRequest: LeaveRequest | undefined, nationalHoliday: NationalHoliday | undefined) => void;
}

export const BrandVacationsCalendar = ({ date, requestedLeaves, nationalHolidays, isFromPopup, onChange, ...props } : BrandVacationsCalendarProps) => {
    const $categories = useHook({});
    const CustomDateProps = (date) => {
        const classNames: string[] = [];
        if (nationalHolidays) {
            const nationalHoliday = nationalHolidays.find(x => isSameDay(x.calendarDate, date));
            if (nationalHoliday) {
                classNames.push('national-holiday-date');
            }
        }

        if (!requestedLeaves) {
            return classNames;
        }

        const requestedLeave = requestedLeaves.find(x => isWithinInterval(date, { start: x.startDate, end: x.endDate }));
        if (!requestedLeave || (classNames.includes('national-holiday-date') && (requestedLeave.checkForNationalHoliday && requestedLeave.category !== LeaveCategory.NationalHoliday))) {
            return classNames;
        }

        let categoryClass = '';
        let hasPendingColoring = false;
        switch (requestedLeave.category) {
            case LeaveCategory.PaidLeave:
                categoryClass = 'paid-leave';
                hasPendingColoring = true;
                break;
            case LeaveCategory.UnpaidLeave:
                categoryClass = 'unpaid-leave';
                hasPendingColoring = true;
                break;
            case LeaveCategory.NationalHoliday:
                categoryClass = 'national-holiday';
                hasPendingColoring = true;
                break;
            case LeaveCategory.SickLeave:
                categoryClass = 'sick-leave';
                break;
            case LeaveCategory.AddedDays:
                categoryClass = 'added-day';
                hasPendingColoring = true;
                break;
            case LeaveCategory.HalfDay:
                categoryClass = 'half-day-leave';
                hasPendingColoring = true;
                break;

            default:
                categoryClass = 'other-non-deductible';
                break;
        }

        if (hasPendingColoring && requestedLeave.status === LeaveStatus.Pending) {
            categoryClass = 'pending-' + categoryClass;
        }

        classNames.push(categoryClass);
        return classNames;
    };

    const CustomDateTooltip = (date) => {
        if (requestedLeaves) {
            const requestedLeave = requestedLeaves.find(x => isWithinInterval(date, { start: x.startDate, end: x.endDate }));
            
            if (requestedLeave && requestedLeave.status === 2 && requestedLeave.checkForNationalHoliday && nationalHolidays) { 
                const nationalHoliday = nationalHolidays.find(x => isSameDay(x.calendarDate, date));
                if (nationalHoliday) {
                    return 'National holiday';
                }
            }

            if (requestedLeave) {
                return $categories.value[`${requestedLeave.status}_${requestedLeave.category}`];
            }
        }

        if (nationalHolidays) {
            const nationalHoliday = nationalHolidays.find(x => isSameDay(x.calendarDate, date));
            if (nationalHoliday) {
                return 'National holiday';
            }
        }

        return undefined;
    }

    const changeHandler = (date, _isFromPopup) => {
        if (!onChange) {
            return;
        }

        const requestedLeave = requestedLeaves && requestedLeaves.find(x => isWithinInterval(date, { start: x.startDate, end: x.endDate }));
        const nationalHoliday = nationalHolidays && nationalHolidays.find(x => isSameDay(x.calendarDate, date));
        
        if (!_isFromPopup && requestedLeave && requestedLeave.checkForNationalHoliday && requestedLeave.category !== 3 && nationalHoliday) {
            return;
        }
        
        onChange(date, requestedLeave, nationalHoliday);
    }

    useEffect(() => {
        CategoryService.getAll()
            .then(categories => {
                const c = {};
                for (let cat of categories) {
                    c[`${LeaveStatus.Approved}_${cat.id}`] = cat.name;
                    c[`${LeaveStatus.Pending}_${cat.id}`] = 'Pending ' + cat.name;
                }

                $categories.set(c);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <BrandCalendar
        CustomDateProps={CustomDateProps}
        CustomDateTooltip={CustomDateTooltip}
        date={date}
        onChange={(date) => changeHandler(date, isFromPopup)}
        {...props}
    />
}

const useCalendarInputStyles = makeStyles((theme) => ({
    inputField: {
        width: '100%',
    },
}));
export const CalendarInput = ({ $value, className, minDate, maxDate, nationalHolidays, requestedLeaves, onMonthChange, onClose, ...props } : any) => {
    const classes = useCalendarInputStyles();
    const $firstFocus = useHook(true);
    const forceValidateContext = useForceValidateContext();
    const $showSelect = useHook(false);
    const calendarRef = useRef<any>(null);

    let errors: any = undefined;
    if (!$firstFocus.value || forceValidateContext) {
        errors = $value?.validator ? $value.validator($value.value) : undefined;
    }

    return <div className={className} ref={calendarRef}>
        <Input
            value={$value.value.toDateString()}
            error={Boolean(errors && errors.length)}
            // helperText={errors?.join('\n')}
            onFocusCapture={e => {
                setTimeout(() => {
                    $showSelect.set(true);
                }, 0);
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle calendar visibility"
                            onClick={() => $showSelect.set(true)}
                            onMouseDown={() => $showSelect.set(true)}
                            edge="end"
                        >
                            <TodayIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                labelWidth: 70
            }}
            className={classes.inputField}
            {...props}
        />
        <Popover
            anchorEl={calendarRef.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={() => {
                $firstFocus.set(false);
                $showSelect.set(false);
                onClose();
            }}
            open={$showSelect.value}
            disableRestoreFocus
        >
            <BrandVacationsCalendar
                selectedDate={$value.value}
                controls={true}
                date={$value.value}
                nationalHolidays={nationalHolidays}
                requestedLeaves={requestedLeaves}
                minDate={minDate}
                maxDate={maxDate}
                onChange={date => {
                    $firstFocus.set(false);
                    $showSelect.set(false);
                    $value.set(date);
                }}
                isFromPopup={true}
                onMonthChange={onMonthChange}
            />
        </Popover>
    </div>
}
