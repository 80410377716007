import { config } from "../config";
import { differenceInCalendarDays, startOfDay } from "date-fns";
import { deleteData, getData, postData, sortVacations } from "./utils";
import { LeaveCategory } from "./category.service";

interface TeamUser {
    userId: number;
    vacations?: any;
}

export interface TeamEntry {
    id?: number;
    name: string;
    isDefault?: boolean;
    users: TeamUser[];
}

export namespace TeamService {

    export function getMy(): Promise<TeamEntry[]> {
        return getData(config.API_BASE_URL + '/team/my/');
    }

    export function getTeam(teamId: number, startDate: Date, endDate: Date): Promise<TeamEntry> {
        return getData(`${config.API_BASE_URL}/team/${teamId}?start=${startDate.toISOString()}&end=${endDate.toISOString()}`)
            .then(data => {
                for (let user of data.users) {
                    sortVacations(user.vacations);
                    user.vacations = user.vacations.map(x => {
                        const startDate = startOfDay(new Date(x.startDate));
                        const endDate = startOfDay(new Date(x.endDate));
                        const mapped = {
                            // eslint-disable-next-line eqeqeq
                            daysUsed: x.category == LeaveCategory.HalfDay ?
                                0.5
                                : 1 + Math.abs(differenceInCalendarDays(endDate, startDate)),
                            ...x,
                            startDate: startDate,
                            endDate: endDate,
                        };
                        // console.log('getTeam', x, mapped);
                        return mapped;
                    });
                }
                return data;
            });
    }

    export function addTeam(team: TeamEntry): Promise<any> {
        return postData(`${config.API_BASE_URL}/team/`, team);
    }

    export function deleteTeam(teamId: number): Promise<TeamEntry> {
        return deleteData(`${config.API_BASE_URL}/team/${teamId}/`);
    }

    export function addUser(teamId: number, user: TeamUser): Promise<TeamEntry> {
        return postData(`${config.API_BASE_URL}/team/${teamId}/addUser/`, user);
    }

    export function removeUser(teamId: number, user: TeamUser): Promise<TeamEntry> {
        return postData(`${config.API_BASE_URL}/team/${teamId}/removeUser/`, user);
    }

    export function teamVacations(teamId: number, startDate: Date, endDate: Date): Promise<any> {
        return getData(`${config.API_BASE_URL}/team/${teamId}/vacations?start=${startDate.toISOString()}&end=${endDate.toISOString()}`)
            .then(data => {
                const result = {};
                for (let v of data) {
                    sortVacations(v.vacations);
                    result[v.userId] = v.vacations.map(x => {
                        const startDate = startOfDay(new Date(x.startDate));
                        const endDate = startOfDay(new Date(x.endDate));
                        const mapped = {
                            // eslint-disable-next-line eqeqeq
                            daysUsed: x.category == LeaveCategory.HalfDay ?
                                0.5
                                : 1 + Math.abs(differenceInCalendarDays(endDate, startDate)),
                            ...x,
                            startDate: startDate,
                            endDate: endDate,
                        };
                        // console.log('teamVacations', x, mapped);
                        return mapped;
                    });
                }
                return result
            });
    }

}
