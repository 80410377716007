import { config } from "../config";
import { deleteData, getData, postData, putData } from "./utils";

export interface DayType {
    id: number;
    name: string;
    label: string;
    color: string;
    hotkey: string;
    isDeleteOption: number;
}

export interface ScheduleDto {
    id?: number;
    ownerId?: number;
    name: string;
    office: string;
    shiftStart: string;
    shiftStartTime: string;
    shiftEnd: string;
    shiftEndTime: string;
    createdDate?: string;
    lastUpdateDate?: string;
    members: any;
}

export interface DayTypeDto {
    year: number;
    month: number;
    day: number;
    dayTypeId: number;
    note?: string;
}

export interface ScheduleHistory {
    id: number;
    scheduleId: number;
    userId: number;
    changeLog: string;
    createdDate: string;
    fullName: string;
}

export namespace ScheduleService {
    export function getDayTypes(): Promise<DayType[]> {
        return getData(config.API_BASE_URL + '/schedule/dayTypes');
    }

    export function getScheduleHistory(scheduleId: number): Promise<ScheduleHistory[]> {
        return getData(config.API_BASE_URL + `/schedule/${scheduleId}/history`);
    }

    export function getMySchedules(year?: number, month?: number, filter?: string, scheduleFilter?: string): Promise<ScheduleDto[]> {
        return getData(config.API_BASE_URL + `/schedule/my?year=${year || ''}&month=${month || ''}&filter=${filter || ''}${scheduleFilter || ''}`);
    }

    export function createSchedule(schedule: ScheduleDto): Promise<any> {
        return postData(config.API_BASE_URL + '/schedule', schedule);
    }

    export function updateSchedule(schedule: ScheduleDto, scheduleId: number): Promise<any> {
        return postData(config.API_BASE_URL + `/schedule/${scheduleId}`, schedule);
    }

    export function deleteSchedule(scheduleId: number): Promise<ScheduleDto[]> {
        return deleteData(config.API_BASE_URL + `/schedule/${scheduleId}`);
    }

    export function updateScheduleDay(dayTypeDto: DayTypeDto, scheduleId: number, userId: number): Promise<any> {
        return postData(config.API_BASE_URL + `/schedule/${scheduleId}/user/${userId}/day`, dayTypeDto);
    }

    export function cloneSchedule(dayTypes: Array<any>, scheduleId: number, selectedMonths: Array<string>): Promise<any> {
        return postData(config.API_BASE_URL + `/schedule/${scheduleId}/days`, { dayTypes: dayTypes, selectedMonths: selectedMonths });
    }

    export function copyMemberSchedule(fromScheduleId: number, toScheduleIds: Array<number>, userId: number, year: number, month: number,): Promise<any> {
        return putData(config.API_BASE_URL + `/schedule/copy-member-schedule`, {
                fromScheduleId: fromScheduleId,
                toScheduleIds: toScheduleIds,
                userId: userId,
                month: month,
                year: year
            });
    }
}
