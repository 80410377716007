import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { BrandCard } from "./Card";

const useStyles = makeStyles((theme) => ({
    sectionRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(170),
        margin: 'auto',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom:  theme.spacing(5),
    },
    sectionContainer: {
        padding: theme.spacing(3),
        margin: theme.spacing(0, 8),
    },
    sectionTitle: {
        textAlign: 'left',
        marginTop: 0,
    },
}));

export const Section = ({ sectionName, ...props }) => {
    const classes = useStyles();
    return <div className={classes.section}>
        <h1 className={classes.sectionTitle}>{sectionName}</h1>
        {props.container ?
            <props.container className={classes.sectionContainer}>
                {props.children}
            </props.container>
        :
            <BrandCard className={classes.sectionContainer}>
                {props.children}
            </BrandCard>
        }
    </div>
}

export const SectionContainer = (props) => {
    const classes = useStyles();
    return <div className={clsx(classes.sectionRoot, props.className)}>
        {props.children}
    </div>
}
