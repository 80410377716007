import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Hook, useForceValidateContext, useReadOnlyContext } from "./Utils";

const useStyles = makeStyles((theme) => ({
    input: {
        '& .MuiFormLabel-asterisk': {
            color: 'red'
        }
    }
}));

export interface RadioGroupProps {
    $value?: Hook<any>;
    readOnly?: boolean;
    label: string;
    value?: any;
    children?: any;
    required?: boolean;
}

export const BrandRadioGroup = ({ $value, readOnly, label, ...props } : RadioGroupProps) => {
    const readOnlyContext = useReadOnlyContext();
    const forceValidateContext = useForceValidateContext();
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ($value) {
            $value.set(+event.target.value);
        }
    };

    let errors: any = undefined;
    if (forceValidateContext) {
        errors = $value?.validator ? $value.validator($value.value) : undefined;
    }

    return  <FormControl
        component="fieldset"
        disabled={readOnly != null ? readOnly : readOnlyContext}
        classes={{ root: classes.input }}
        error={Boolean(errors && errors.length)}
        {...props}
    >
        {label ?
            <FormLabel component="legend" required={props.required}>{label}</FormLabel>
        : null}
        <RadioGroup
            // aria-label={label.toLowerCase()}
            // name={label.toLowerCase()}
            value={$value?.value == null ? props.value : $value?.value}
            onChange={handleChange}
            row
        >
            {props.children}
        </RadioGroup>
        {errors?.length ?
            <FormHelperText>
                {errors?.join('\n')}
            </FormHelperText>
        : null}
    </FormControl>
}