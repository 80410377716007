import React, { useEffect } from 'react';
import { Input, PasswordField } from '../CoreComponents/Input';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import withStyles from '@material-ui/core/styles/withStyles';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/Select';
import { ForceValidateContext, ReadOnlyContext, useHook, areFieldsValid, useStoreContext } from '../CoreComponents/Utils';
import { ReadOnlyField } from '../CoreComponents/ReadOnlyField';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GenderType, StatusType, User, UserService, UserType, UserDataValidators } from '../Services/user.service';
import { BrandButton } from '../CoreComponents/Button';
import Chip from '@material-ui/core/Chip';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Department, DepartmentService } from '../Services/department.service';
import { Section, SectionContainer } from '../CoreComponents/Section';
import { LoadingIndicator } from '../CoreComponents/LoadingIndicator';
import { BrandModal, InformationDialog, WarningConfirmationDialog } from '../CoreComponents/Modal';
import { Alert } from '../CoreComponents/Alert';
import { BrandRadioGroup } from '../CoreComponents/RadioGroup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    inputField: {
        width: '100%'
    },
    selectField: {
        minWidth: theme.spacing(20)
    },
    gridContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    gridItem: {
        width: theme.spacing(35),
        margin: theme.spacing(1),
        display: 'flex',
    },
    editControls: {
        display: 'flex',
        // justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        margin: theme.spacing(2, 0, 2, 'auto'),
    },
    changePasswordButton: {
        backgroundColor: '#ffb800',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#b27d00',
            color: '#ffffff',
        }
    },
    editButton: {
        margin: '0 auto',
        '&:first-child': {
            margin: theme.spacing(0, 'auto', 0, 1),
        },
        '&:last-child': {
            margin: theme.spacing(0, 1, 0, 'auto'),
        },
    },
    addApproverButton: {
        width: theme.spacing(10),
        height: '100%',
        margin: theme.spacing(0, 'auto', 0, 1)
    },
    approversContainer: {
        // border: '1px solid rgba(0, 0, 0, 0.23)',
        width: `calc(100% - ${theme.spacing(1)}px)`,
        maxHeight: theme.spacing(20),
        margin: theme.spacing(1),
        overflowY: 'auto',
        padding: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1, 1)
        }
    },
    changePasswordContainer: {
        maxWidth: theme.spacing(50),
    },
    changePasswordModalBody: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 'auto',
        '&>*': {
            margin: theme.spacing(1, 0)
        }
    },
    changePasswordModalControls: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    approversList: {
        width: '100%',
        maxWidth: 390,
        height: 150,
        overflow: 'auto',
        marginBottom: theme.spacing(1.5)
    },
    addForm: {
        display: 'flex',
        flexDirection: 'row',
        width: theme.spacing(30),
        marginBottom: theme.spacing(1),
    },
    formInputField: {
        minWidth: theme.spacing(32),
        marginRight: theme.spacing(1.5),
    },
    addBtn: {
        height: '56px',
        width: theme.spacing(10),
    }
}));

const Grid = ({ ...props }) => {
    const classes = useStyles();
    return <div className={props.container ? classes.gridContainer : classes.gridItem}>
        {props.children}
    </div>
}

const ChangePasswordModal = ({ $isOpenChangePasswordModal, userId, $password, $repeatPassword }) => {
    const classes = useStyles();
    const $error = useHook('');
    const $loading = useHook(false);
    const $validators = useHook({
        password: UserDataValidators.password,
        repeatPassword: UserDataValidators.repeatPassword,
    });
    return <BrandModal
        title="Fill in the fields below with the new password"
        open={$isOpenChangePasswordModal.value}
        onClose={() => $isOpenChangePasswordModal.set(false)}
        classes={{ paper: classes.changePasswordContainer }}
    >
        {$loading.value ?
            <LoadingIndicator />
            :
            <form className={classes.changePasswordModalBody}>
                {$error.value ?
                    <Alert severity="error">{$error.value}</Alert>
                    : null}
                <PasswordField autoFocus readOnly={false} className={classes.inputField} $value={$password} required label="Password" />
                <PasswordField readOnly={false} className={classes.inputField} $value={$repeatPassword} required label="Repeat password" />
                <div className={classes.changePasswordModalControls}>
                    <BrandButton
                        onClick={() => $isOpenChangePasswordModal.set(false)}
                    >
                        Cancel
                    </BrandButton>
                    <BrandButton
                        type="submit"
                        className={classes.changePasswordButton}
                        onClick={e => {
                            e.preventDefault();
                            if ($password.value !== $repeatPassword.value) {
                                $error.set('Passwords don\'t match');
                                return;
                            }

                            if (!areFieldsValid(
                                {
                                    password: $password.value,
                                    repeatPassword: $repeatPassword.value
                                },
                                $validators.value,
                                ['password', 'repeatPassword'])
                            ) {
                                return;
                            }

                            $loading.set(true);
                            UserService.changePassword(userId, $password.value)
                                .then(() => $isOpenChangePasswordModal.set(false))
                                .catch(err => {
                                    $loading.set(false);
                                    $error.set(`Error: ${err.message}`);
                                });
                        }}
                    >
                        Change
                    </BrandButton>
                </div>
            </form>
        }
    </BrandModal>
}

const BlueRadio = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
const RedRadio = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const UserManagement = ({ isNewUser, userId, showAutocompleteOnly = false, $selectedUsers = [], onLoadFilterUnselected = false, filterMe = false }: { isNewUser: boolean, userId?: number, showAutocompleteOnly: boolean, $selectedUsers: any, onLoadFilterUnselected: boolean, filterMe: boolean }) => {
    const { $currentUser } = useStoreContext();
    const history = useHistory();
    const classes = useStyles();
    const $isReadOnly = useHook(!isNewUser);
    const $isForceValidating = useHook(false);
    const $isOpenChangePasswordModal = useHook(false);

    const $isDeletingUser = useHook(false);
    const $informationMessage = useHook('');
    const $error = useHook('');
    const $isSavingUserData = useHook(false);

    const $loadingUser = useHook(false);
    const $departments = useHook<Department[]>([]);

    const $possibleApprovers = useHook<User[]>([]);
    const $selectedApproverUser = useHook<User | null>(null);

    const $user = useHook<User>({
        id: (userId as number),
        firstName: '',
        middleName: '',
        lastName: '',
        departmentId: '' as any,
        email: '',
        password: '',
        homeOffice: '',
        repeatPassword: '',
        telephoneNumber: '',
        jobPosition: '',
        status: StatusType.Active,
        gender: '' as any,
        userType: '' as any,
        canSelfApprove: false,

        daysFromContract: '' as any,
        yearlyAllowance: '' as any,
        daysUsed: '' as any,
        daysRemaining: '' as any,
        totalDays: '' as any,
        nationalHolidays: '' as any,
        daysFromLastYear: '' as any,

        approvers: [],
    });
    const $resetUser = useHook<User>($user.value);
    const $approvers = useHook<User[]>([]);
    const $unselectedApprovers = useHook<User[]>([]);
    const $hasLoaded = useHook<boolean>(false);

    const getPropHook = (propName: string) => {
        return {
            value: $user.value[propName],
            set: (val) => $user.set({ ...$user.value, [propName]: val }),
            validator: (val): string[] | undefined => {
                if (!UserDataValidators[propName]) {
                    return undefined;
                }

                const errors: string[] = [];
                for (const validator of UserDataValidators[propName]) {
                    const err = validator(val);
                    errors.push(...err);
                }

                if (propName === 'repeatPassword' && $user.value.password !== $user.value.repeatPassword) {
                    errors.push('Passwords don\'t match');
                }

                return errors;
            }
        }
    }

    const getUserDto = (): User => ({
        ...$user.value,
        approvers: $approvers.value.map(x => x.id)
    })

    const resetUser = () => {
        if (!isNewUser && userId) {
            $loadingUser.set(true);
            UserService.getById(userId)
                .then(data => {
                    $loadingUser.set(false);
                    if (!data) {
                        return;
                    }

                    const user = {
                        ...$user.value,
                        ...data,
                        canSelfApprove: Boolean(data.canSelfApprove)
                    };
                    $user.set(user);
                    $resetUser.set({
                        ...user,
                        approvers: user.approvers ? [...user.approvers] : []
                    });
                })
        }
    }

    useEffect(() => {
        UserService.getAll()
            .then(data => {
                const possibleApprovers = data
                    .filter(x => x.userType === UserType.Approver && x.id !== userId as number);
                $possibleApprovers.set(possibleApprovers);
            });
        DepartmentService.getAll()
            .then(data => $departments.set(data));

        resetUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!$user.value || !$possibleApprovers.value || !$possibleApprovers.value.length) {
            return;
        }

        if (!$user.value.approvers || !$user.value.approvers.length) {
            $unselectedApprovers.set($possibleApprovers.value);
            return;
        }

        if ($approvers.value.length === $user.value.approvers.length) {
            let newApprovers = false;
            for (let i = 0; i < $approvers.value.length; ++i) {
                if ($approvers.value[i].id !== $user.value.approvers[i]) {
                    newApprovers = true;
                    break;
                }
            }

            if (!newApprovers) {
                return;
            }
        }

        const approvers: User[] = [];
        const unselectedApprovers: User[] = [];
        for (let approver of $possibleApprovers.value) {
            const isApprover = $user.value.approvers.includes(approver.id);
            if (isApprover) {
                approvers.push(approver);
            } else {
                unselectedApprovers.push(approver);
            }
        }

        if (approvers.length !== $user.value.approvers.length) {
            console.error('Discrepancy! Some of the approvers were deleted or changed to normal users.');
        }

        $unselectedApprovers.set(unselectedApprovers);
        $approvers.set(approvers);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$user.value, $possibleApprovers.value]);


    useEffect(() => {
        if (showAutocompleteOnly && onLoadFilterUnselected && !$hasLoaded.value && $selectedUsers.value && $selectedUsers.value.length && $unselectedApprovers.value && $unselectedApprovers.value.length) {
            $hasLoaded.set(true);
            $unselectedApprovers.set($unselectedApprovers.value.filter(u => $selectedUsers.value.findIndex(su => su.id === u.id) < 0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAutocompleteOnly, $selectedUsers, $hasLoaded.value, onLoadFilterUnselected, $unselectedApprovers])

    return (
        showAutocompleteOnly ?
            <>
                <div className={classes.addForm}>
                    <Autocomplete
                        classes={{ root: classes.formInputField }}
                        options={filterMe ?
                            $unselectedApprovers.value.filter(u => u.id !== $currentUser.value.id)
                            :
                            $unselectedApprovers.value
                        }
                        getOptionLabel={(option) => `${option.firstName} ${option.middleName} ${option.lastName} ID-${option.id}`}
                        style={{ width: 300 }}
                        value={$selectedApproverUser.value}
                        onChange={(_, user) => $selectedApproverUser.set(user)}
                        renderInput={(params) => <Input {...params} required variant="outlined" />}
                    />
                    <BrandButton
                        color="primary"
                        className={classes.addBtn}
                        onClick={() => {
                            if ($selectedApproverUser.value) {
                                $selectedUsers.set([...$selectedUsers.value, $selectedApproverUser.value]);
                                // @ts-ignore
                                $unselectedApprovers.set($unselectedApprovers.value.filter(u => u.id !== $selectedApproverUser.value.id));
                                $selectedApproverUser.set(null);
                            }
                        }}
                    >
                        Add
                    </BrandButton>
                </div>
                <List dense className={classes.approversList}>
                    {$selectedUsers.value.map((user) => {
                        if (filterMe && $currentUser.value.id === user.id /*|| scheduleForEdit?.ownerId === user.id */) {
                            return null;
                        };
                        const key = `checkbox-list-secondary-label-${user.id}`;
                        return (
                            <ListItem key={key}>
                                <ListItemText primary={`${user.firstName} ${user.lastName} ID-${user.id}`} />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        onClick={() => {
                                            $unselectedApprovers.set([...$unselectedApprovers.value, user]);
                                            $selectedUsers.set($selectedUsers.value.filter(user2 => user2.id !== user.id));
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            </>
            :
            <>
                {$loadingUser.value ?
                    <BrandModal open={$loadingUser.value}>
                        <LoadingIndicator />
                    </BrandModal>
                    : null}
                {$error.value ?
                    <Alert severity="error">{$error.value}</Alert>
                    : null}
                <SectionContainer>
                    <ReadOnlyContext.Provider value={$isReadOnly.value}>
                        <ForceValidateContext.Provider value={$isForceValidating.value}>
                            <Section sectionName="Personal information">
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('firstName')} required label="First name" />
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('middleName')} required label="Middle name" />
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('lastName')} required label="Last name" />
                                    </Grid>
                                    <Grid item xs>
                                        <BrandSelect
                                            className={classes.inputField}
                                            required
                                            label="Department"
                                            $value={getPropHook('departmentId')}
                                        >
                                            {$departments.value.map(x =>
                                                <BrandMenuItem value={x.id} key={x.id}>{x.name}</BrandMenuItem>
                                            )}
                                        </BrandSelect>
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('email')} required label="Email address" />
                                    </Grid>
                                    {isNewUser ?
                                        <>
                                            <Grid item xs>
                                                <PasswordField className={classes.inputField} $value={getPropHook('password')} required label="Password" />
                                            </Grid>
                                            <Grid item xs>
                                                <PasswordField className={classes.inputField} $value={getPropHook('repeatPassword')} required label="Repeat password" />
                                            </Grid>
                                        </>
                                        : null}
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('telephoneNumber')} label="Telephone number" />
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('jobPosition')} label="Job position" />
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('homeOffice')} label="Home Office Location" />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <BrandRadioGroup required label="Status" $value={getPropHook('status')}>
                                            <FormControlLabel value={StatusType.Active} control={<BlueRadio />} label="Active" />
                                            <FormControlLabel value={StatusType.Inactive} control={<Radio color="default" />} label="Inactive" />
                                        </BrandRadioGroup>
                                    </Grid>
                                    <Grid item xs>
                                        <BrandRadioGroup required label="Gender" $value={getPropHook('gender')}>
                                            <FormControlLabel value={GenderType.Male} control={<BlueRadio />} label="Male" />
                                            <FormControlLabel value={GenderType.Female} control={<RedRadio />} label="Female" />
                                        </BrandRadioGroup>
                                    </Grid>
                                    {isNewUser ? null :
                                        <Grid item xs>
                                            <ReadOnlyField label="ID of user" value={'ID-' + getPropHook('id').value} />
                                            {/* <Input className={classes.inputField} value={'ID-' + getPropHook('id').value} disabled label="ID of user" /> */}
                                        </Grid>
                                    }
                                    <Grid item xs>
                                        <BrandButton
                                            className={classes.changePasswordButton}
                                            onClick={() => $isOpenChangePasswordModal.set(true)}
                                        >
                                            Change password
                                        </BrandButton>
                                    </Grid>
                                    {isNewUser ? null :
                                        <>
                                            <Grid item xs>
                                            </Grid>
                                            <Grid item xs>
                                                <ChangePasswordModal
                                                    $isOpenChangePasswordModal={$isOpenChangePasswordModal}
                                                    userId={getPropHook('id').value}
                                                    $password={getPropHook('password')}
                                                    $repeatPassword={getPropHook('repeatPassword')}
                                                />
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <BrandSelect
                                            id="user-type"
                                            label="User type"
                                            className={classes.selectField}
                                            $value={getPropHook('userType')}
                                            required
                                            onChange={e => {
                                                const userType = e.target.value;
                                                $user.set({
                                                    ...$user.value,
                                                    userType: userType,
                                                    canSelfApprove: userType === 2
                                                });
                                            }}
                                        >
                                            <BrandMenuItem value={UserType.User}>User</BrandMenuItem>
                                            <BrandMenuItem value={UserType.Approver}>Approver</BrandMenuItem>
                                        </BrandSelect>
                                    </Grid>
                                    <Grid item xs>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={getPropHook('canSelfApprove').value}
                                                    onChange={e => getPropHook('canSelfApprove').set(e.target.checked)}
                                                />
                                            }
                                            label="Self approver"
                                            disabled={$isReadOnly.value}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <Autocomplete
                                            options={$unselectedApprovers.value}
                                            getOptionLabel={(option) => `${option.firstName} ${option.middleName} ${option.lastName} ID-${option.id}`}
                                            style={{ width: 300 }}
                                            value={$selectedApproverUser.value}
                                            disabled={$isReadOnly.value}
                                            onChange={(_, user) => $selectedApproverUser.set(user)}
                                            renderInput={(params) => <Input {...params} required label="Approver/s of this user" variant="outlined" />}
                                        />
                                    </Grid>
                                    {$isReadOnly.value ? null :
                                        <Grid item xs>
                                            <BrandButton
                                                color="primary"
                                                className={classes.addApproverButton}
                                                onClick={() => {
                                                    if ($selectedApproverUser.value) {
                                                        $approvers.set([...$approvers.value, $selectedApproverUser.value]);
                                                        // @ts-ignore
                                                        $unselectedApprovers.set($unselectedApprovers.value.filter(u => u.id !== $selectedApproverUser.value.id));
                                                        $selectedApproverUser.set(null);
                                                    }
                                                }}
                                            >
                                                Add
                                            </BrandButton>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid container spacing={3}>
                                    <div className={$approvers.value.length ? classes.approversContainer : undefined}>
                                        {$approvers.value.map((user, index) =>
                                            <Chip
                                                key={user.id}
                                                // color="primary"
                                                onDelete={$isReadOnly.value ? undefined : () => {
                                                    $approvers.set($approvers.value.filter(u => u.id !== user.id));
                                                    $unselectedApprovers.set([...$unselectedApprovers.value, user]);
                                                }}
                                                // avatar={<Avatar>FA</Avatar>}
                                                label={`${user.firstName} ${user.middleName} ${user.lastName}`}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Section>
                            <Section sectionName="Time off information">
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('daysFromContract')} required label="Days from contract" />
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('yearlyAllowance')} required label="Yearly allowance" />
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} value={getPropHook('daysUsed').value} disabled label="Days used" />
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} value={getPropHook('daysRemaining').value} disabled label="Days remaining" />
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} value={getPropHook('totalDays').value} disabled label="Total days" />
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('daysFromLastYear')} required label="Days from last year" />
                                    </Grid>
                                    <Grid item xs>
                                        <Input className={classes.inputField} $value={getPropHook('nationalHolidays')} required label="National Holidays" />
                                    </Grid>
                                    <Grid item xs></Grid>
                                </Grid>
                            </Section>
                        </ForceValidateContext.Provider>
                    </ReadOnlyContext.Provider>
                    <div className={classes.editControls}>
                        {isNewUser ?
                            <>
                                <BrandButton
                                    className={classes.editButton}
                                    component={Link} to="/"
                                >
                                    Back
                                </BrandButton>
                                <BrandButton
                                    color="primary"
                                    className={classes.editButton}
                                    onClick={() => {
                                        $isForceValidating.set(true);
                                        if (!areFieldsValid($user.value, UserDataValidators)) {
                                            return;
                                        }

                                        $isReadOnly.set(true);
                                        $isSavingUserData.set(true);
                                        $informationMessage.set('User successfully created');
                                        UserService.addUser(getUserDto())
                                            .then(newUser => {
                                                $isSavingUserData.set(false);
                                            })
                                            .catch(err => {
                                                $isReadOnly.set(false);
                                                $isSavingUserData.set(false);
                                                $informationMessage.set('');
                                                $error.set(`Error: ${err.message}`);
                                            });
                                    }}
                                >
                                    Create
                                </BrandButton>
                                <InformationDialog
                                    isOpen={Boolean($informationMessage.value)}
                                    isLoading={$isSavingUserData.value}
                                    message={$informationMessage.value}
                                    onOk={() => {
                                        history.push('/');
                                    }}
                                />
                            </>
                            : $isReadOnly.value ?
                                <>
                                    <BrandButton
                                        className={classes.editButton}
                                        component={Link} to="/"
                                    >
                                        Back
                                    </BrandButton>
                                    <BrandButton
                                        color="primary"
                                        className={classes.editButton}
                                        onClick={() => { $isReadOnly.set(false) }}
                                    >
                                        Edit
                                    </BrandButton>
                                </>
                                :
                                <>
                                    <BrandButton
                                        className={classes.editButton}
                                        onClick={() => {
                                            $isReadOnly.set(true);
                                            $user.set({
                                                ...$resetUser.value,
                                                approvers: $resetUser.value.approvers ? [...$resetUser.value.approvers] : []
                                            });
                                        }}
                                    >
                                        Cancel
                                    </BrandButton>
                                    <BrandButton
                                        color="secondary"
                                        className={classes.editButton}
                                        onClick={() => $isDeletingUser.set(true)}
                                    >
                                        Delete
                                    </BrandButton>
                                    <WarningConfirmationDialog
                                        isOpen={$isDeletingUser.value}
                                        isLoading={$isSavingUserData.value}
                                        message="Are you sure you want to delete this user?"
                                        onCancel={() => $isDeletingUser.set(false)}
                                        onOk={() => {
                                            $isSavingUserData.set(true);
                                            UserService.deleteUser(userId as number)
                                                .then(() => {
                                                    history.push('/');
                                                }).catch(err => {
                                                    $isSavingUserData.set(false);
                                                    $informationMessage.set('');
                                                    $error.set(`Error: ${err.message}`);
                                                });
                                        }}
                                    />
                                    <BrandButton
                                        color="primary"
                                        className={classes.editButton}
                                        onClick={() => {
                                            $isForceValidating.set(true);
                                            if (!areFieldsValid($user.value, UserDataValidators, null, ['password', 'repeatPassword'])) {
                                                return;
                                            }

                                            $isSavingUserData.set(true);
                                            $informationMessage.set('User successfully edited');
                                            UserService.editUser(getUserDto())
                                                .then(() => {
                                                    const _newUser = getUserDto();
                                                    $resetUser.set({
                                                        ..._newUser,
                                                        approvers: _newUser.approvers ? [..._newUser.approvers] : []
                                                    })
                                                    $isSavingUserData.set(false);
                                                })
                                                .catch(err => {
                                                    $isSavingUserData.set(false);
                                                    $informationMessage.set('');
                                                    $error.set(`Error: ${err.message}`);
                                                });
                                        }}
                                    >
                                        Save
                                    </BrandButton>
                                    <InformationDialog
                                        isOpen={Boolean($informationMessage.value)}
                                        isLoading={$isSavingUserData.value}
                                        message={$informationMessage.value}
                                        onOk={() => {
                                            $isReadOnly.set(true);
                                            $informationMessage.set('');
                                        }}
                                    />
                                </>
                        }
                    </div>
                </SectionContainer>
            </>
    );
}

export const AddUser = props => <UserManagement isNewUser={true} {...props} />
export const EditUser = props => {
    const { userId } = useParams<any>();
    return <UserManagement isNewUser={false} userId={userId} {...props} />
}
export const UserApproversAutocomplete = ({ $selectedUsers, onLoadFilterUnselected = false, filterMe = false, ...props }) => {
    const { userId } = useParams<any>();
    return <UserManagement isNewUser={false} userId={userId} showAutocompleteOnly={true} $selectedUsers={$selectedUsers} onLoadFilterUnselected={onLoadFilterUnselected} filterMe={filterMe} {...props} />
}
