import { differenceInCalendarDays, format, startOfDay } from "date-fns";
import { config } from "../config";
import { maxLengthValidator, requiredValidator } from "../CoreComponents/Utils";
import { LeaveCategory } from "./category.service";
import { getData, postData, sortVacations } from "./utils";

export enum VacationStatusType {
    Pending = 1,
    Approved,
    Rejected,
    Deleted
}

export interface PendingHistoryEntry {
    id: number;
    startDate: Date;
    endDate: Date;
    status: VacationStatusType;
    category: number;
}

export interface ApprovedHistoryEntry extends PendingHistoryEntry {
    userId: number;
    firstName: string;
    lastName: string;
    jobPosition: string;
}

export interface HistoryEntry {
    pending: PendingHistoryEntry[];
    approved: ApprovedHistoryEntry[];
}

export interface SummaryEntry {
    category: number;
    days: number;
}

export enum LeaveType { }

export enum LeaveStatus {
    Pending = 1,
    Approved,
    Rejected,
    Deleted,
}

export interface LeaveRequest {
    id?: number;
    bookedByUserId: number;
    userId: number;
    category: number;
    startDate: Date;
    endDate: Date;
    reason?: string;
    status?: LeaveStatus;
    approvedByUserId?: number;

    approverFirstName?: string;
    approverJobPosition?: string;
    approverLastName?: string;

    bookerFirstName?: string;
    bookerJobPosition?: string;
    bookerLastName?: string;

    daysUsed?: number;
    includeWeekends?: boolean;
    checkForNationalHoliday?: boolean;
    approvers?: number[];
}

export const LeaveRequestValidators = {
    category: [requiredValidator],
    startDate: [requiredValidator],
    endDate: [requiredValidator],
    reason: [maxLengthValidator(1000)]
};

export interface LeaveWallVacations {
    [id: number]: LeaveRequest[];
}

export namespace VacationService {

    export function getSummary(userId: number, year: number): Promise<SummaryEntry[]> {
        return getData(`${config.API_BASE_URL}/vacation/user/${userId}/summary/${year}`);
    }

    export function getHistory(userId: number, takeCount: number, skipCount: number): Promise<HistoryEntry> {
        return getData(`${config.API_BASE_URL}/vacation/user/${userId}/history?take=${takeCount}&skip=${skipCount}`)
            .then((x: HistoryEntry) => {
                x.pending = x.pending.map(e => ({
                    ...e,
                    startDate: startOfDay(new Date(e.startDate)),
                    endDate: startOfDay(new Date(e.endDate))
                }));
                x.approved = x.approved.map(e => ({
                    ...e,
                    startDate: startOfDay(new Date(e.startDate)),
                    endDate: startOfDay(new Date(e.endDate))
                }));
                return x;
            });
    }

    export function addVacationRequest(request: LeaveRequest): Promise<LeaveRequest[]> {
        return postData(`${config.API_BASE_URL}/vacation`, {
            ...request,
            startDate: format(request.startDate, 'yyyy-MM-dd'),
            endDate: format(request.endDate, 'yyyy-MM-dd')
        });
    }

    export async function getRequestedLeaves(userId: number, startDate: Date, endDate: Date): Promise<LeaveRequest[]> {
        return getData(`${config.API_BASE_URL}/vacation/user/${userId}?start=${startDate.toISOString()}&end=${endDate.toISOString()}`)
            .then((data: LeaveRequest[]) => {
                sortVacations(data);
                return data.map(x => {
                    const startDate = startOfDay(new Date(x.startDate));
                    const endDate = startOfDay(new Date(x.endDate));
                    const mapped = {
                        // eslint-disable-next-line eqeqeq
                        daysUsed: x.category == LeaveCategory.HalfDay ?
                            0.5
                            : 1 + Math.abs(differenceInCalendarDays(endDate, startDate)),
                        ...x,
                        startDate: startDate,
                        endDate: endDate,
                    };
                    // console.log('getRequestedLeaves', x, mapped);
                    return mapped;
                });
            });
    }

    export async function getSubordinatesLeaves(userId: number, startDate: Date, endDate: Date): Promise<LeaveWallVacations> {
        return getData(`${config.API_BASE_URL}/vacation/user/${userId}/subordinates?start=${startDate.toISOString()}&end=${endDate.toISOString()}`)
            .then(data => {
                const result = {};
                for (let v of data) {
                    sortVacations(v.vacations);
                    result[v.userId] = v.vacations.map(x => {
                        const startDate = startOfDay(new Date(x.startDate));
                        const endDate = startOfDay(new Date(x.endDate));
                        const mapped = {
                            // eslint-disable-next-line eqeqeq
                            daysUsed: x.category == LeaveCategory.HalfDay ?
                                0.5
                                : 1 + Math.abs(differenceInCalendarDays(endDate, startDate)),
                            ...x,
                            startDate: startDate,
                            endDate: endDate,
                        };
                        // console.log('getSubordinatesLeaves', x, mapped);
                        return mapped;
                    });
                }
                return result
            });
    }

    export function approveVacationRequest(vacationId: number): Promise<void> {
        return postData(`${config.API_BASE_URL}/vacation/${vacationId}`, { action: 1 });
    }

    export function rejectVacationRequest(vacationId: number, reason: string): Promise<void> {
        return postData(`${config.API_BASE_URL}/vacation/${vacationId}`, { action: 2, reason: reason });
    }

    export function deleteVacationRequest(vacationId: number, reason: string): Promise<void> {
        return postData(`${config.API_BASE_URL}/vacation/${vacationId}`, { action: 3, reason: reason });
    }

    export function getReportsRequest(fromDate: string, toDate: string, departmentId: number, tagId: number, filter: string): Promise<any> {
        return getData(`${config.API_BASE_URL}/vacation/reports?fromDate=${fromDate}&toDate=${toDate}&departmentId=${departmentId}&tagId=${tagId}&filter=${filter || ''}`);
    }
   
    export function getLeaveRequest(vacationId: number): Promise<LeaveRequest> {
        return getData(`${config.API_BASE_URL}/vacation/${vacationId}`)
            .then(data => ({
                ...data,
                startDate: new Date(data.startDate),
                endDate: new Date(data.endDate)
            }));
    }
}
