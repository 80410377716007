import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { LoadingIndicator } from "./LoadingIndicator";
import { BrandButton } from "./Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

export interface BrandModalProps extends DialogProps
{
    onCloseButton?: () => void;
}

const useStyles = makeStyles((theme) => ({
    dialogTitle:{
        fontWeight: 'bold',
        margin: 0,
    },
    dialogContent: {
        display: 'flex',
    },
    informationDialogContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    confirmationMessage: {
        // fontWeight: 'normal',
        fontWeight: 'bold',
        paddingBottom: theme.spacing(5),
    },
    dialogControls: {
        display: 'flex',
        '&>*': {
            margin: theme.spacing(1, 'auto'),
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export const BrandModal = ({ children, title, onCloseButton, ...props } : BrandModalProps) => {
    const classes = useStyles();
    return <Dialog {...props}>
        {title ?
            <DialogTitle disableTypography={true}>
                <h2 className={classes.dialogTitle}>
                    {title}
                </h2>
            </DialogTitle>
        : null}
        {onCloseButton ?
            <IconButton aria-label="close" className={classes.closeButton} onClick={onCloseButton}>
                <CloseIcon />
            </IconButton>
        : null}
        <DialogContent className={classes.dialogContent}>
            {children}
        </DialogContent>
    </Dialog>
}

export const InformationDialog = ({ isOpen, isLoading, message, onOk, ...props }) => {
    const classes = useStyles();
    return <BrandModal
            open={isOpen}
            onClose={() => {}}
        >
            {isLoading ?
                <LoadingIndicator />
            :
                <div className={classes.informationDialogContainer}>
                    <h2 className={classes.dialogTitle}>
                        {message}
                    </h2>
                    <div className={classes.dialogControls}>
                        <BrandButton
                            color="primary"
                            onClick={() => onOk()}
                        >
                            OK
                        </BrandButton>
                    </div>
                </div>
            }
        </BrandModal>
}

export const WarningConfirmationDialog = ({ isOpen, isLoading, message, onCancel, onOk, ...props }) => {
    const classes = useStyles();
    return <BrandModal
            title="Warning"
            open={isOpen}
            onClose={onCancel}
        >
            <div className={classes.informationDialogContainer}>
                <h2 className={classes.confirmationMessage}>
                    {message}
                </h2>
                {isLoading ?
                    <LoadingIndicator />
                :
                    <div className={classes.dialogControls}>
                        <BrandButton
                            onClick={() => onCancel()}
                        >
                            Cancel
                        </BrandButton>
                        <BrandButton
                            color="secondary"
                            onClick={() => onOk()}
                        >
                            Yes
                        </BrandButton>
                    </div>
                }
            </div>
        </BrandModal>
}
