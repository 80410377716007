import { useEffect } from 'react';
import { BrandButton } from '../CoreComponents/Button';
import { NavLink, useHistory } from 'react-router-dom';
import { AuthenticationService } from '../Services/authentication.service';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { UserType } from '../Services/user.service';
import clsx from 'clsx';
import { ForceValidateContext, useHook, useStoreContext } from '../CoreComponents/Utils';
import { BrandModal } from '../CoreComponents/Modal';
import { AdminService, NationalHoliday } from '../Services/admin.service';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import 'react-calendar/dist/Calendar.css';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { BrandUserIcon, ResetDaysIcon, LogoutIcon, NavigationIcon } from '../CoreComponents/CustomIcons';
import { LoadingIndicator } from '../CoreComponents/LoadingIndicator';
import { BrandVacationsCalendar } from '../CoreComponents/Calendar';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import Badge from '@material-ui/core/Badge';
import { NotificationService } from '../Services/notification.service';
import { Tooltip } from '@material-ui/core';

const NavItem = withStyles((theme) => ({
    link: {
        width: '100%',
        padding: theme.spacing(1),
        whiteSpace: 'nowrap',
        position: 'relative',

        '&.active:before': {
            position: 'absolute',
            height: 0,
            width: `calc(100% - (2em + ${2 * theme.spacing(1) + theme.spacing(1)}px))`,
            right: theme.spacing(1),
            bottom: theme.spacing(1.5),
            content: '""',
            borderBottom: `5px solid ${theme.palette.primary.main}`,
        },

        '&:hover': {
            textDecoration: 'none',
            '&:before': {
                position: 'absolute',
                height: 0,
                width: `calc(100% - (2em + ${2 * theme.spacing(1) + theme.spacing(1)}px))`,
                right: theme.spacing(1),
                bottom: theme.spacing(1.5),
                content: '""',
                borderBottom: `5px solid ${theme.palette.action.active}`,
            },
        }
    },
}))(({ classes, ...props }: any) =>
    <Link
        to="/"
        classes={{ root: classes.link }}
        component={NavLink}
        exact={true}
        color="inherit"
        {...props}
    />
)

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing('auto', 1),
    },
    resetDaysNavButton: {
        padding: 0,
    },
    addUserButton: {
        color: 'black',
        margin: theme.spacing('auto', 1),
        display: 'flex',
    },
    addUserButtonIcon: {
        margin: 'auto',
    },
    notificationButton: {
        color: '#000000',
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5),

        marginTop: 2 * theme.spacing(3) + theme.spacing(5),
        // height: `calc(100vh - ${2 * theme.spacing(3) + theme.spacing(5)}px)`,
        boxSizing: 'border-box',
    },
    pageContentHeight: {
        height: `calc(100vh - ${2 * theme.spacing(3) + theme.spacing(5)}px)`
    },

    navigation: {
        display: 'flex',
        padding: theme.spacing(3),
        borderBottom: '0.2px solid rgba(113, 113, 113, 0.49)',
        '&> ul': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            listStyleImage: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        position: 'fixed',
        boxSizing: 'border-box',
        // position: 'sticky',
        top: 0,
        width: '100%',
        background: 'white',
        zIndex: theme.zIndex.appBar,
    },
    navigationItemText: {
        marginLeft: theme.spacing(1),
    },

    navigationGroup: {
        display: 'flex',
        height: theme.spacing(5),
    },
    navMiddleGroup: {
        // justifyContent: 'space-between',
        display: 'flex',
    },

    engNavGroup: {
        marginLeft: 'auto',
        marginTop: '-7px',
        gap: theme.spacing(3)
    },

    // reset days
    resetDaysPaper: {
        maxWidth: 'unset', // theme.spacing(95),
        padding: theme.spacing(3.125, 2.5, 3.125, 3.125),
    },
    resetDaysContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    resetDaysSelectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '&>*': {
            padding: theme.spacing(1),
        },
    },
    resetDaysButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    inputField: {
        width: '100%'
    },
    calendarControl: {
        '& .MuiFormLabel-asterisk': {
            color: 'red'
        },
    },
    calendar: {
        // width: theme.spacing(40),
        height: theme.spacing(50),
        border: 0,
    },
    resetDaysSelectedDaysContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(29),
    },
    selectedDatesContainer: {
        maxHeight: theme.spacing(30),
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    selectedDateItem: {
        margin: theme.spacing(0.5),
        maxWidth: theme.spacing(21),
        justifyContent: 'flex-end',
        '& .MuiChip-label': {
            paddingRight: 0,
        },
        '& > .MuiChip-deleteIcon': {
            margin: theme.spacing(0, 0.5, 0, 1),
        },
    },
    resetButton: {
        width: theme.spacing(16),
    },
    navigationLink: {
        margin: 'auto',
        color: '#727272',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    logoutIcon: {
        marginLeft: '25px'
    },
    adminTabLogout: {
        marginTop: '-6px'
    }
}));

const ResetDaysModal = ({ $isModalOpen }) => {
    const classes = useStyles();
    const $selectedDates = useHook<NationalHoliday[]>([]);
    const $isLoading = useHook(false);
    const $isForceValidating = useHook(false);

    return <BrandModal
        title="Reset Days"
        open={$isModalOpen.value}
        // onClose={() => $isModalOpen.set(false)}
        onCloseButton={() => $isModalOpen.set(false)}
        classes={{ paper: classes.resetDaysPaper }}
    >
        {$isLoading.value ?
            <LoadingIndicator />
            :
            <ForceValidateContext.Provider value={$isForceValidating.value}>
                <div className={classes.resetDaysContainer}>
                    <div className={classes.resetDaysSelectionContainer}>
                        <FormControl className={classes.calendarControl}>
                            <FormLabel component="legend" required={true}>Select National days</FormLabel>
                            <BrandVacationsCalendar
                                controls
                                classes={{ root: classes.calendar }}
                                onChange={date => {
                                    $selectedDates.set([...$selectedDates.value.filter(d => d.calendarDate.getTime() !== date.getTime()), { calendarDate: date } as NationalHoliday]);
                                }}
                                nationalHolidays={$selectedDates.value}
                            />
                        </FormControl>
                        <div className={classes.resetDaysSelectedDaysContainer}>
                            <span>National Holidays</span>
                            <span>{$selectedDates.value.length}</span>
                            <div className={classes.selectedDatesContainer}>
                                {$selectedDates.value.map(x => {
                                    const options = { year: 'numeric', month: 'long', day: 'numeric' };
                                    return <Chip
                                        key={x.calendarDate.getTime()}
                                        // color="primary"
                                        onDelete={() => {
                                            $selectedDates.set($selectedDates.value.filter(d => d !== x));
                                        }}
                                        className={classes.selectedDateItem}
                                        label={x.calendarDate.toLocaleDateString('en-UK', options as any)}
                                    />
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={classes.resetDaysButtonContainer}>
                        <BrandButton
                            color="primary"
                            classes={{ root: classes.resetButton }}
                            onClick={() => {
                                $isForceValidating.set(true);
                                if (!$selectedDates.value.length) {
                                    return;
                                }

                                // $isLoading.set(true);
                                AdminService
                                    .resetDays({
                                        nationalHolidays: $selectedDates.value.map(x => x.calendarDate)
                                    })
                                    .then(() => $isModalOpen.set(false))
                                    .catch(err => {
                                        $isLoading.set(false);
                                        console.log(err);
                                    });
                            }}
                        >
                            Reset
                        </BrandButton>
                    </div>
                </div>
            </ForceValidateContext.Provider>
        }
    </BrandModal>
}

export const NavBar = ({ $userData, $navigationComponent, ...props }) => {
    const history = useHistory();
    const { $missedNotificationsCount, $blockNotificationCounterUpdate, $lastReadNotificationDate, $filters } = useStoreContext();
    const $resetDaysModal = useHook(false);
    const classes = useStyles();
    const $notificationsCount = useHook<string | undefined>(undefined);
    const $refreshNotifications = useHook<any>(undefined);

    useEffect(() => {
        $notificationsCount.set(
            !$missedNotificationsCount.value ? undefined :
                $missedNotificationsCount.value > 9 ? '9+' :
                    $missedNotificationsCount.value.toString()
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$missedNotificationsCount.value]);

    useEffect(() => {
        if (!$refreshNotifications.value) {
            return;
        }

        // console.log('$lastReadNotificationDate.value', $lastReadNotificationDate.value);
        NotificationService.notificationSync($lastReadNotificationDate.value)
            .then(data => $missedNotificationsCount.set(data.count));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$refreshNotifications.value]);

    useEffect(() => {
        if (!$userData.value) {
            return;
        }

        if ($userData.value.userType === UserType.Approver || $userData.value.userType === UserType.User) {
            setTimeout(function () {
                $refreshNotifications.set({});
            }, 10);
            const timer = setInterval(function () {
                $refreshNotifications.set({});
            }, 15000);
            return () => {
                // $refreshNotifications.set(undefined);
                clearInterval(timer);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$userData.value]);

    return <nav className={classes.navigation}>
        <div className={clsx(classes.navigationGroup, classes.navMiddleGroup)}>
            {$navigationComponent.value}
        </div>
        <div className={clsx(classes.navigationGroup, classes.engNavGroup)}>
            {$userData.value.userType === UserType.Admin ?
                <>
                    <IconButton
                        className={clsx(classes.button, classes.resetDaysNavButton)}
                        onClick={() => $resetDaysModal.set(true)}
                    >
                        <ResetDaysIcon />
                    </IconButton>
                    {$resetDaysModal.value ?
                        <ResetDaysModal $isModalOpen={$resetDaysModal} />
                        : null}
                    <NavLink to="/add-user"
                        className={classes.addUserButton}
                    >
                        <BrandUserIcon className={classes.addUserButtonIcon} />
                    </NavLink>
                </>
                : $userData.value.userType === UserType.Approver ?
                    <>
                        <NavItem to="/schedule">
                            <Tooltip title='Schedule'>
                                <div>
                                    <NavigationIcon iconname='schedule' active={history.location.pathname === '/schedule'} />
                                </div>
                            </Tooltip>
                        </NavItem>
                        <NavItem to="/reports">
                            <Tooltip title='Reports'>
                                <div>
                                    <NavigationIcon iconname='reportsIcon' active={history.location.pathname === '/reports'} />
                                </div>
                            </Tooltip>
                        </NavItem>
                        <NavItem to="/">
                            <Tooltip title='Leave Wall'>
                                <div>
                                    <NavigationIcon iconname='leaveWall' active={history.location.pathname === '/'} />
                                </div>
                            </Tooltip>
                        </NavItem>
                        <NavItem to="/team-wall">
                            <Tooltip title='Team Wall'>
                                <div>
                                    <NavigationIcon iconname='teamWall' active={history.location.pathname === '/team-wall'} />
                                </div>
                            </Tooltip>
                        </NavItem>
                        <NavItem to="/my-wall">
                            <Tooltip title='My Wall'>
                                <div>
                                    <NavigationIcon iconname='myWall' active={history.location.pathname === '/my-wall'} />
                                </div>
                            </Tooltip>
                        </NavItem>
                        <NavItem to='/notifications'>
                            <Tooltip title='Notifications'>
                                <div>
                                    <Badge badgeContent={$blockNotificationCounterUpdate.value ? undefined : $notificationsCount.value} color="error">
                                        <NavigationIcon iconname='notification' active={history.location.pathname === '/notifications'} />
                                    </Badge>
                                </div>
                            </Tooltip>
                        </NavItem>
                    </>
                    : $userData.value.userType === UserType.User ?
                        <>
                            <NavItem to="/schedule">
                                <Tooltip title='Schedule'>
                                    <div>
                                        <NavigationIcon iconname='schedule' active={history.location.pathname === '/schedule'} />
                                    </div>
                                </Tooltip>
                            </NavItem>
                            <NavItem to="/team-wall">
                                <Tooltip title='Team Wall'>
                                    <div>
                                        <NavigationIcon iconname='teamWall' active={history.location.pathname === '/team-wall'} />
                                    </div>
                                </Tooltip>
                            </NavItem>
                            <NavItem to="/">
                                <Tooltip title='My Wall'>
                                    <div>
                                        <NavigationIcon iconname='myWall' active={history.location.pathname === '/'} />
                                    </div>
                                </Tooltip>
                            </NavItem>
                            <NavItem to='/notifications'>
                                <Tooltip title='Notifications'>
                                    <div>
                                        <Badge badgeContent={$blockNotificationCounterUpdate.value ? undefined : $notificationsCount.value} color="error">
                                            <NavigationIcon iconname='notification' active={history.location.pathname === '/notifications'} />
                                        </Badge>
                                    </div>
                                </Tooltip>
                            </NavItem>
                        </>
                        : null}
        </div>
        {/* {$userData.value.userType === UserType.Admin ? */}
        <div className={clsx(
            classes.navigationGroup,
            classes.logoutIcon,
            $userData.value.userType === UserType.Admin ? classes.adminTabLogout : null
        )}
        >
            <LogoutIcon
                onClick={_ => {
                    AuthenticationService.logout()
                        .then(data => {
                            if (data.success) {
                                $userData.set(null);
                                $filters.set({ pageId: '', data: {} });
                                history.push('/');
                            }
                        })
                        .catch(_ => {
                            $userData.set(null);
                            history.push('/');
                        });
                }}
            />
        </div>
    </nav>
}

export const PageLayout = ({ $userData, $navigationComponent, ...props }) => {
    const classes = useStyles();
    const history = useHistory();

    return <div>
        <NavBar $userData={$userData} $navigationComponent={$navigationComponent} />
        <div className={clsx(classes.pageContent, history.location.pathname === '/schedule' ? classes.pageContentHeight : null)}>{props.children}</div>
    </div >
}
